<template>
<div id="mpmateriallist">
  <b-field grouped>
    <b-select :disabled="!countData" v-model="materialType" expanded placeholder="选择素材类型" @input="onMaterialTypeChange">
      <option value="news">图文</option>
      <option value="image">图片</option>
      <option value="video">视频</option>
      <option value="voice">语音</option>
    </b-select>
    <button :disabled="!canGetMaterial" class="button is-primary" @click="getMaterialList()">获取素材</button>
  </b-field>
  <b-field v-if="materialType==='image'" class="has-text-danger">
    若希望查看图片，请复制图片URL，然后开启一个新的浏览器窗口粘贴URL。
  </b-field>
  <b-field>
      <b-table :data="items"
        paginated backend-pagination
        :total="total"
        :per-page="perPage"
        :current-page="currentPage"
        @page-change="onPageChange">
        <b-table-column #default="props" field="media_id" label="媒体ID" width="200">
          {{ props.row.media_id }}
        </b-table-column>
        <b-table-column #default="props" field="update_time" label="更新时间" width="200">
          {{ formatDateTime(props.row.update_time * 1000) }}
        </b-table-column>
        <b-table-column #default="props" field="content" label="内容" width="200">
          <ul>
            <li v-for="(o, i) in getContent(props.row)" :key="i">
              <a class="button is-text" :href="o.url" target="_blank">{{ o.name }}</a>
            </li>
          </ul>
        </b-table-column>
        <template #bottom-left>
          <b>{{materialType}} 资源总计：</b>{{ total }}
        </template>
      </b-table>
  </b-field>
</div>
</template>

<script>
import config from '@/core/config'

export default {
  name: 'mpmateriallist',
  created () {
    this.getMaterialCount()
  },
  computed: {
    canGetMaterial () {
      return this.countData && this.materialType && this.total > 0
    }
  },
  data () {
    return {
      perPage: config.PER_PAGE,
      currentPage: 1,
      total: 0,
      items: [],
      materialType: null,
      countData: null
    }
  },
  methods: {
    getContent (row) {
      if (this.materialType !== 'news') {
        return [{ name: row.name, url: row.url }]
      }
      const items = []
      if (row.content && row.content.news_item) {
        for (const item of row.content.news_item) {
          items.push({ name: item.title, url: item.url })
        }
      }
      return items
    },
    onMaterialTypeChange (select) {
      if (this.countData) this.total = this.countData[select + '_count']
      this.items = []
    },
    onPageChange (page) {
      this.currentPage = page
      this.getMaterialList(page)
    },
    async getMaterialCount () {
      try {
        const data = await this.mjp.get2({
          url: '/wxoa/mp/material/count/get/',
          mjpType: 'mp'
        })
        this.countData = data
      } catch (error) {
        console.error(error)
      }
    },
    async getMaterialList (page) {
      page = page || this.currentPage || 1
      try {
        const filterobj = { type: this.materialType, total: this.total, page, per_page: this.perPage }
        const data = await this.mjp.get2({
          url: '/wxoa/mp/material/get/more/',
          query: filterobj,
          mjpType: 'mp'
        })
        this.items = data.item
        this.perPage = data.per_page
        this.page = data.page
        this.total = data.total
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style>
#mpmateriallist {
  padding: 12px;
}
</style>
