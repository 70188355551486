<template>
<div id="clearplayer">
  <b-field horizontal label="ID 类型">
    <b-switch v-model="isGSUID">使用游戏服 UID</b-switch>
  </b-field>
  <b-field v-if="isGSUID" horizontal label="游戏服 UID">
    <b-input v-model.number="uid" placeholder="输入游戏服 UID"></b-input>
  </b-field>
  <b-field v-else horizontal label="昵称或 SAGIUID(LID)">
    <nickname-searcher ref="nicknameselector" @input="onNicknameSelect" show-select />
  </b-field>
  <button class="button is-danger" :disabled="!canSet" @click="onClick">设置</button>
</div>
</template>

<script>
import NicknameSearcher from '@/components/NicknameSearcher'

export default {
  name: 'setplayer',
  components: { NicknameSearcher },
  computed: {
    canSet () {
      return this.uid || this.sagiuid
    }
  },
  data () {
    return {
      isGSUID: false,
      uid: null,
      sagiuid: null
    }
  },
  methods: {
    onNicknameSelect (user) {
      if (user !== null) {
        this.sagiuid = user.sagiuid
      }
    },
    refresh () {
      this.uid = null
      this.sagiuid = null
      this.isGSUID = false
    },
    onClick () {
      this.$emit('do', {
        name: 'clearplayer',
        uid: this.uid,
        sagiuid: this.sagiuid
      })
    }
  }
}
</script>
