
<template>
<div id="setclientconfig">
  <b-field horizontal label="选择 shareImg">
    <b-radio v-model="shareImg"
      :native-value="shareImgs[0]">
      <figure class="image is-128x128">
        <img :src="shareImgs[0]" alt="红包分享图" />
      </figure>
    </b-radio>
    <b-radio v-model="shareImg"
      :native-value="shareImgs[1]">
      <figure class="image is-128x128">
        <img :src="shareImgs[1]" alt="普通分享图" />
      </figure>
    </b-radio>
  </b-field>
  <button class="button is-danger" :disabled="!canSet" @click="onClick">设置</button>
</div>
</template>

<script>
export default {
  name: 'setclientconfig',
  created () {
    this.refresh()
  },
  computed: {
    canSet () {
      return this.shareImg !== null
    }
  },
  data () {
    return {
      shareImg: null,
      shareImgs: [
        'https://h5.sagiteam.cn/icon/wechat_share_icon_redpack.png',
        'https://h5.sagiteam.cn/icon/wechat_share_icon.png'
      ]
    }
  },
  methods: {
    async refresh () {
      const data = await this.mjp.get('/mana/getclientconfig/')
      this.shareImg = data.data.shareImg
      console.log('refresh data:%o', data)
    },
    onClick () {
      this.$emit('do', {
        name: 'setclientconfig',
        shareImg: this.shareImg
      })
    }
  }
}
</script>
