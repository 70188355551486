<template>
<div id="analytics500">
  <b-field grouped group-multiline class="box">
    <date-quick @change="onDateQuick" expanded/>
    <b-datepicker
      placeholder="开始日期"
      v-model="fromDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar-today"
      expanded
      @input="onDateInput">
    </b-datepicker>
    <b-datepicker
      placeholder="截止日期"
      v-model="toDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar"
      expanded
      @input="onDateInput">
    </b-datepicker>
  </b-field>
  <b-table ref="table" :data="items"
    paginated backend-pagination
    :total="total"
    :per-page="perPage"
    :current-page="currentPage"
    @page-change="onPageChange">
    <b-table-column #default="props" field="channel" label="来源玩家ID" width="150" numeric>
      {{ props.row.channel }}
    </b-table-column>
    <b-table-column #default="props" field="date" label="日期" width="120">
      {{ props.row.date }}
    </b-table-column>
    <b-table-column #default="props" field="num" label="数量">
      {{ props.row.num }}
    </b-table-column>
    <template #bottom-left>
      <b>总计：</b>{{ total }}
    </template>
  </b-table>
</div>
</template>

<script>
import { fromNow } from '@/core/util'
import DateQuick from '@/components/DateQuick'
import config from '@/core/config'
import cache from '@/core/cache'

export default {
  name: 'analytics500',
  components: { DateQuick },
  mounted () {
    this.getRecord()
  },
  data: function () {
    return {
      perPage: config.PER_PAGE,
      currentPage: 1,
      total: 0,
      // 表格数据
      items: [],
      minDate: new Date(2018, 4, 12),
      maxDate: new Date(),
      fromDate: fromNow({ days: -7 }),
      toDate: new Date(),
      isLoading: false
    }
  },
  computed: {
    selectedChannels () {
      return this.$refs.channelSelector.selectedChannels
    },
    regional () {
      return cache.getR(true)
    }
  },
  methods: {
    onPageChange (page) {
      this.currentPage = page
      this.getRecord(page)
    },
    onDateQuick (date) {
      this.fromDate = date.fromDate
      this.toDate = date.toDate
    },
    onDateInput (date) {
      this.getRecord()
    },
    onChannelChange (channel) {
      this.getRecord()
    },
    buildData (data) {
      // 数据需要重新进行组合才能在图表中使用
      const stat = data.stat
      const channels = {}
      stat.forEach(item => {
        // 获得不重复的 channel
        channels[item.channel] = true
      })
      this.items = stat
      this.total = data.total
      this.perPage = data.per_page
      this.currentPage = data.page
    },
    clearRecord () {
      this.items = []
      this.total = 0
      this.perPage = config.PER_PAGE
      this.currentPage = 1
    },
    async getRecord (page) {
      console.log('isLoading: %s', this.isLoading)
      if (this.isLoading || !this.hub.checkFilters([this.regional, '区服'])) {
        this.clearRecord()
        return
      }
      page = page || this.currentPage || 1
      const filterObj = { page, per_page: this.perPage }
      if (this.fromDate) {
        filterObj.from_date = this.formatDate(this.fromDate)
      }
      if (this.toDate) {
        filterObj.to_date = this.formatDate(this.toDate)
      }
      this.isLoading = true
      try {
        const data = await this.mjp.get('/analy/register/500/', filterObj)
        this.buildData(data)
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    }
  }
}
</script>

<style>
#analytics500 {
  padding: 12px;
}
</style>
