<template>
<div id="manabanner">
  <b-message>
    “手动设置” 和 “定时任务” 并不冲突，也不会互相影响。<br><br>
    手动设置仅会设置一次。<br>
    定时任务会持续根据配置执行“开”或“关”的任务。<br>
    当前开关的状态取决于最后一次执行的是“开”还是“关”。<br><br>
    定时任务的定时器每 30 秒执行一次，因此生效的具体时间为设定时间之后的 30 秒以内的任意时刻。<br>
    定时任务执行是否成功，可以通过点击“手动设置”中的“刷新”按钮，观察最新的“手动设置”状态来确认。
  </b-message>
  <b-field horizontal label="手动设置">
    <div class="columns">
      <div class="column">
        <b-tooltip label="点击“设置”按钮后立即生效">
          <b-switch v-model="value" >{{value ? '误触开' : '误触关'}}</b-switch>
        </b-tooltip>
      </div>
      <div class="column buttons">
        <button class="button is-danger" @click="onSetOnoff">设置</button>
        <button class="button is-success" @click="refresh">刷新</button>
      </div>
    </div>
  </b-field>
  <b-field horizontal label="定时任务">
    <div class="columns">
      <div class="column">
        <b-tooltip label="点击“保存”按钮后，重新设置任务队列">
          <b-switch v-model="useCrons">使用定时任务</b-switch>
        </b-tooltip>
      </div>
      <div class="column buttons">
        <button :disabled="!crons" class="button is-warning" @click="onSaveCrons">保存</button>
        <button :disabled="!(cronTime)" class="button is-success" @click="onAddCron">增加</button>
        <button :disabled="!cron" class="button is-danger" @click="onDelCron">删除</button>
      </div>
    </div>
  </b-field>
  <b-field v-if="useCrons" horizontal label="定时任务设置">
    <b-timepicker v-model="cronTime" icon="clock" placeholder="任务触发时间"></b-timepicker>
    <b-switch v-model="cronSwitch">{{ cronSwitch ? '误触开' : '误触关'}}</b-switch>
  </b-field>
  <b-table :data="crons" focusable :selected.sync="cron" @select="onCronSelect">
    <b-table-column #default="props" field="time" label="time">
      {{ props.row.time }}
    </b-table-column>
    <b-table-column #default="props" field="qqadex" label="开关">
      {{ props.row.qqadex }}
    </b-table-column>
  </b-table>
</div>
</template>

<script>
import cache from '@/core/cache'
import { DateTime } from 'luxon'

export default {
  name: 'manabanner',
  mounted () {
    this.refresh()
  },
  data () {
    return {
      value: null,
      crons: [],
      cron: null,
      useCrons: false,
      cronTime: null,
      cronSwitch: false
    }
  },
  methods: {
    clear () {
      this.value = null
      this.crons = []
      this.cron = null
      this.userCrons = false
      this.cronTime = null
      this.cronSwitch = false
    },
    onCronSelect (row) {
      this.cronTime = DateTime.fromFormat(row.time, 'HH:mm').toJSDate()
      this.cronSwitch = row.qqadex === 1
    },
    getVOName () {
      return `r${cache.getR(true)}_crons`
    },
    async refresh () {
      this.clear()
      try {
        const rdata = await this.mjp.get('/mana/getonoffqqadex/')
        this.value = rdata.data.qqadex === 1

        const voData = await this.mjp.get('/cf/qqadex/get/', null, null, 'admin')
        this.crons = voData.value
        this.useCrons = voData.status === 1
      } catch (error) {
        console.error(error)
      }
    },
    async onSaveCrons (evt) {
      console.log('onSaveCrons %o', evt)
      try {
        const rdata = await this.mjp.post('/cf/qqadex/set/', {
          status: this.useCrons ? 1 : 5,
          value: JSON.stringify(this.crons)
        }, null, null, 'admin')
        console.log('rdata %o', rdata)
        this.hub.alert('调用成功!', 4, 'is-success')
        this.refresh()
      } catch (error) {
        console.error(error)
      }
    },
    async onAddCron (evt) {
      const cronObj = {}
      cronObj.qqadex = this.cronSwitch ? 1 : 0
      cronObj.time = DateTime.fromJSDate(this.cronTime).toFormat('HH:mm')
      this.crons.push(cronObj)
    },
    async onDelCron (evt) {
      if (!this.crons) return
      if (!this.cron) return
      const index = this.crons.indexOf(this.cron)
      if (index !== -1) {
        this.crons.splice(index, 1)
      }
    },
    async onSetOnoff (evt) {
      try {
        const rdata = await this.mjp.post('/mana/setonoffqqadex/', { qqadex: this.value ? 1 : 0 })
        console.log('rdata %o', rdata)
        this.hub.alert('调用成功!', 4, 'is-success')
        this.refresh()
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    cronTime (to, from) {
      if (this.cron) this.cron.time = DateTime.fromJSDate(to).toFormat('HH:mm')
    },
    cronSwitch (to, from) {
      try {
        if (this.cron) this.cron.qqadex = to ? 1 : 0
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style>
#manabanner {
  padding: 12px;
}
</style>
