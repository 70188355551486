<template>
<div id="setplayer">
  <b-field horizontal label="属性">
    <b-select v-model="key" placeholder="选择属性" expanded>
      <option
        v-for="(o, index) in keys"
        :value="o"
        :key="index">
        {{o}}
      </option>
    </b-select>
  </b-field>
  <b-field horizontal label="属性值">
    <b-input v-model.trim="value" placeholder="输入属性值"></b-input>
  </b-field>
  <b-field horizontal label="ID 类型">
    <b-switch v-model="isGSUID">使用游戏服 UID</b-switch>
  </b-field>
  <b-field v-if="isGSUID" horizontal label="游戏服 UID">
    <b-input v-model.number="uid" placeholder="输入游戏服 UID"></b-input>
  </b-field>
  <b-field v-else horizontal label="昵称或 SAGIUID(LID)">
    <nickname-searcher ref="nicknameselector" @input="onNicknameSelect" show-select />
  </b-field>
  <button class="button is-danger" :disabled="!canSet" @click="onClick">设置</button>
</div>
</template>

<script>
import NicknameSearcher from '@/components/NicknameSearcher'

// 射手支持的 KEYS ，用做默认值
const YSF_KEYS = ['coin', 'diamond', 'sp', 'headimg', 'nickname', 'gender', 'numexec', 'numgame', 'numwin', 'numheadshot', 'numfinish', 'score']

export default {
  name: 'setplayer',
  components: { NicknameSearcher },
  mounted () {
    // 请求参数 key
    this.refresh()
  },
  computed: {
    canSet () {
      return this.key !== null && this.value !== null && (this.uid || this.sagiuid)
    }
  },
  data () {
    return {
      keys: YSF_KEYS,
      key: null,
      value: null,
      isGSUID: false,
      uid: null,
      sagiuid: null
    }
  },
  methods: {
    onNicknameSelect (user) {
      if (user !== null) {
        this.sagiuid = user.sagiuid
      }
    },
    async refresh () {
      this.key = null
      this.value = null
      this.uid = null
      this.sagiuid = null
      this.isGSUID = false

      try {
        const rdata = await this.mjp.get('/mana/getplayerattr/')
        // 每个服务器支持的 keys 可能不同。每次刷新都要重新请求可用的参数 key
        if (rdata.data) {
          this.keys = rdata.data
        } else {
          this.keys = YSF_KEYS
        }
      } catch (error) {
        this.keys = YSF_KEYS
      }
    },
    onClick () {
      this.$emit('do', {
        name: 'setplayer',
        key: this.key,
        value: this.value,
        uid: this.uid,
        sagiuid: this.sagiuid
      })
    }
  }
}
</script>
