<template>
<div id="setmaintain">
  <b-field horizontal label="是否维护">
    <b-switch v-model.number="maintain" true-value="1" false-value="0">{{maintain}}</b-switch>
  </b-field>
  <b-field v-if="maintain === 1" horizontal label="开始时间">
      <b-datepicker
        placeholder="选择日期"
        v-model="date"
        :min-date="new Date()"
        :date-formatter="formatDate"
        icon="calendar">
      </b-datepicker>
      <b-timepicker
        placeholder="点击选择时间"
        icon="clock"
        v-model="time"
        :readonly="false">
        </b-timepicker>
  </b-field>
  <b-field v-if="maintain === 1" horizontal label="维护持续(分钟)">
    <b-input v-model.number="duration" placeholder="输入分钟数"></b-input>
  </b-field>
  <button class="button is-danger" :disabled="!canSet" @click="onClick">设置</button>
</div>
</template>

<script>
export default {
  name: 'setmaintain',
  created () {
    this.refresh()
  },
  computed: {
    start () {
      if (this.date && this.time) {
        const date = new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          this.date.getDate(),
          this.time.getHours(),
          this.time.getMinutes(),
          this.time.getSeconds()
        )
        return date.getTime()
      }
      return null
    },
    canSet () {
      return this.maintain !== null && this.duration > 0 && this.start
    }
  },
  data () {
    return {
      duration: null,
      maintain: null,
      date: new Date(),
      time: new Date()
    }
  },
  methods: {
    async refresh () {
      const data = await this.mjp.get('/mana/getmaintain/')
      this.maintain = data.data.maintain
      this.duration = data.data.duration
      this.date = data.data.start ? new Date(data.data.start) : new Date()
      this.time = data.data.start ? new Date(data.data.start) : new Date()
    },
    onClick () {
      this.$emit('do', {
        name: 'setmaintain',
        maintain: Number.parseInt(this.maintain),
        start: this.start,
        duration: this.duration
      })
    }
  }
}
</script>

<style>
/** 弹出的日期选择器需要一个高度，否则会被截断 **/
#setmaintain {
  height: 400px;
}
</style>
