<template>
<div id="analyvideoad">
  <b-field grouped group-multiline>
    <date-quick @change="onDateQuick" expanded/>
    <b-datepicker
      placeholder="开始日期"
      v-model="fromDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar-today"
      expanded
      @input="onDateInput">
    </b-datepicker>
    <b-datepicker
      placeholder="截止日期"
      v-model="toDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar"
      expanded
      @input="onDateInput">
    </b-datepicker>
  </b-field>
  <horizontal-bar-chart :chart-data="chartData" :height="400"></horizontal-bar-chart>
</div>
</template>

<script>
import cache from '@/core/cache'
import HorizontalBarChart from '@/components/charts/HorizontalBarChart'
import DateQuick from '@/components/DateQuick'
import { nextChartColor, fromNow, sortDate } from '@/core/util'

const PROP_NAME = {
  0: '任务刷新',
  1: '复活（无限模式）',
  2: '看广告送角色',
  3: '单机结算奖励(已废弃)',
  4: '结算界面奖励',
  5: '宝箱翻倍',
  6: '天降宝箱',
  7: '商店免费购买(已废弃）',
  8: '首页免费金币钻石',
  9: '强制广告(已废弃)',
  10: '转盘广告',
  11: '赏金模式难度1免费加入'
}

export default {
  name: 'analyprops',
  components: { HorizontalBarChart, DateQuick },
  mounted () {
    this.getRecord()
  },
  computed: {
    regional () {
      return cache.getR(true)
    }
  },
  data () {
    return {
      minDate: new Date(2018, 8, 30),
      maxDate: new Date(),
      fromDate: fromNow({ days: -7 }),
      toDate: new Date(),
      chartData: this.buildChartData(),
      statData: null,
      isLoading: false
    }
  },
  methods: {
    getPropName (pid) {
      return PROP_NAME[pid] || pid
    },
    onDateQuick (date) {
      this.fromDate = date.fromDate
      this.toDate = date.toDate
    },
    onDateInput (date) {
      this.getRecord()
    },
    buildChartData (labels, datasets) {
      labels = labels || []
      datasets = datasets || []
      return { labels, datasets }
    },
    // 创建各个要分析的属性数据的 dataset
    buildPropDataSets (labels, propsInStat, dataSetsObj) {
      const datasets = []
      for (let i = 0; i < propsInStat.length; i++) {
        const pid = propsInStat[i]
        const propName = this.getPropName(pid)
        const color = nextChartColor(i)
        const datasetShow = { label: `${propName}/次数`, data: [], backgroundColor: color }
        const datasetClick = { label: `${propName}/人数`, data: [], backgroundColor: color }
        const datasetNoPlay = { label: `${propName}/未播完次数`, data: [], backgroundColor: color }
        for (const date of labels) {
          const day = dataSetsObj[date][pid]
          if (day) {
            datasetShow.data.push(day[0])
            datasetClick.data.push(day[1])
            datasetNoPlay.data.push(day[2])
          } else {
            datasetShow.data.push(0, 0)
          }
        }
        datasets.push(datasetShow, datasetClick, datasetNoPlay)
      }
      return datasets
    },
    buildPropData () {
      // 获得 propsId 的数组
      let propsInStat = {}
      // 图表的数据
      const dataSetsObj = {}
      this.statData.forEach(item => {
        if (!dataSetsObj[item.date]) {
          dataSetsObj[item.date] = {}
        }
        // console.log('dataSetObj[%s] %o', item.date, dataSetsObj[item.date])
        for (let [propId, propValue] of Object.entries(item.data)) {
          propId = Number.parseInt(propId)
          propsInStat[propId] = true
          dataSetsObj[item.date][propId] = propValue
        }
      })
      // 其中的内容是 date 字符串
      const labels = sortDate(Object.keys(dataSetsObj))
      // 把 propsInstat 转换成 Array
      propsInStat = Object.keys(propsInStat).sort()

      const datasets = this.buildPropDataSets(labels, propsInStat, dataSetsObj)

      this.chartData = this.buildChartData(labels, datasets)
      // console.log('buildPropData chartData:%o', this.chartData)
    },
    buildData (data) {
      // 留存表格的数据，需要把表格“横”过来
      const stat = data.stat
      this.statData = stat
    },
    clearRecord () {
      this.chartData = this.buildChartData()
      this.statData = null
    },
    async getRecord () {
      if (this.isLoading) {
        return
      }
      if (!this.hub.checkFilters([this.regional, '区服'])) {
        this.clearRecord()
        return
      }
      this.isLoading = true
      try {
        const data = await this.mjp.get('/analy/videoad/', {
          from_date: this.formatDate(this.fromDate),
          to_date: this.formatDate(this.toDate)
        })
        this.buildData(data)
        this.buildPropData()
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    }
  }
}
</script>

<style>
#analyvideoad {
  padding: 12px;
}
</style>
