<template>
  <b-select @input="onPrefabDate" placeholder="快速选择" icon="calendar-clock" :expanded="expanded">
    <option value="1">昨天</option>
    <option value="2">今天</option>
    <option value="3">上周</option>
    <option value="4">本周</option>
    <option value="5">上月</option>
    <option value="6">本月</option>
  </b-select>
</template>

<script>
import { fromNow, getLastMonth } from '@/core/util'
import { DateTime } from 'luxon'

export default {
  props: { expanded: Boolean },
  methods: {
    getNow () {
      return new Date()
    },
    getLastWeek () {
      const weekday = DateTime.fromJSDate(this.getNow()).weekday
      return DateTime.local().plus({ days: -1 * (6 + weekday) }).toJSDate()
    },
    getCurWeek () {
      const weekday = DateTime.fromJSDate(this.getNow()).weekday
      return DateTime.local().plus({ days: -1 * (weekday - 1) }).toJSDate()
    },
    onPrefabDate (select) {
      const now = this.getNow()
      let fromDate = null
      let toDate = null
      if (select === '1') {
        fromDate = fromNow({ days: -1 })
        toDate = fromNow({ days: -1 })
      } else if (select === '2') {
        fromDate = new Date()
        toDate = now
      } else if (select === '3') {
        fromDate = this.getLastWeek()
        toDate = DateTime.fromJSDate(fromDate).plus({ days: 6 }).toJSDate()
      } else if (select === '4') {
        fromDate = this.getCurWeek()
        toDate = now
      } else if (select === '5') {
        fromDate = getLastMonth()
        toDate = DateTime.fromJSDate(fromDate).endOf('month').toJSDate()
      } else if (select === '6') {
        fromDate = DateTime.local().startOf('month').toJSDate()
        toDate = now
      }
      this.$emit('change', { fromDate, toDate })
    }
  }
}
</script>
