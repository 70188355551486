<template>
<div id="settimeoffset">
  <b-field horizontal label="天">
    <b-input v-model.number="days" placeholder="输入天数（可留空）"></b-input>
  </b-field>
  <b-field horizontal label="小时">
    <b-input v-model.number="hours" placeholder="输入小时数（可留空）"></b-input>
  </b-field>
  <b-field horizontal label="分">
    <b-input v-model.number="minutes" placeholder="输入分钟数（可留空）"></b-input>
  </b-field>
  <b-field horizontal label="秒">
    <b-input v-model.number="seconds" placeholder="输入秒数（可留空）"></b-input>
  </b-field>
  <button class="button is-danger" :disabled="!canSet" @click="onClick">设置</button>
</div>
</template>

<script>
export default {
  name: 'settimeoffset',
  computed: {
    canSet () {
      return this.days !== null || this.hours !== null || this.minutes !== null || this.seconds !== null
    }
  },
  data () {
    return {
      days: null,
      hours: null,
      minutes: null,
      seconds: null
    }
  },
  methods: {
    refresh () {
      this.days = null
      this.hours = null
      this.minutes = null
      this.seconds = null
    },
    onClick () {
      // 将分钟切换成秒
      if (this.minutes !== null) {
        if (this.seconds) this.seconds = 0
        this.seconds += this.minutes * 60
      }
      this.$emit('do', {
        name: 'settimeoffset',
        hours: this.hours,
        seconds: this.seconds,
        days: this.days
      })
    }
  }
}
</script>
