<template>
<div id="analygameinfo">
  <b-field grouped group-multiline>
    <b-select v-model.number="selectedGameinfoProp" @input="onGameInfoPropSelect" expanded>
      <option v-for="(o, i) in gameinfoProps" :key="i" :value="i">{{o.text}}</option>
    </b-select>
    <date-quick @change="onDateQuick" expanded/>
    <b-datepicker
      placeholder="开始日期"
      v-model="fromDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar-today"
      expanded
      @input="onDateInput">
    </b-datepicker>
    <b-datepicker
      placeholder="截止日期"
      v-model="toDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar"
      expanded
      @input="onDateInput">
    </b-datepicker>
  </b-field>
  <line-chart :chart-data="chartData" :height="120"></line-chart>
  <b-table ref="table" :data="tableData">
    <b-table-column #default="props" field="date" label="日期" width="120">
      {{ props.row.date }}
    </b-table-column>
    <b-table-column #default="props" v-for="o in infoKeys"
      :key="o"
      :field="o"
      :label="gameInfoKeys[o]"
      width="120">
      {{ props.row[o] }}
    </b-table-column>
  </b-table>
</div>
</template>

<script>
import cache from '@/core/cache'
import LineChart from '@/components/charts/LineChart'
import DateQuick from '@/components/DateQuick'
import { nextChartColor, fromNow } from '@/core/util'

export default {
  name: 'analygameinfo',
  components: { LineChart, DateQuick },
  mounted () {
    this.getRecord()
  },
  computed: {
    regional () {
      return cache.getR(true)
    },
    infoKeys () {
      return Object.keys(this.gameInfoKeys)
    }
  },
  data () {
    return {
      minDate: new Date(2018, 4, 12),
      maxDate: new Date(),
      fromDate: fromNow({ days: -7 }),
      toDate: new Date(),
      chartData: this.buildChartData(),
      tableData: [],
      isLoading: false,
      gameInfoKeys: {
        avground: '平均回合数',
        avgchoosetime: '平均选角色耗时',
        avgtime: '平均单场时间',
        numrealfirst: '先手胜利次数(不含AI)',
        numrealall: '总战斗数(不含AI)'
      },
      gameinfoProps: [
        { text: '回合/选角色', keys: ['avground', 'avgchoosetime'] },
        { text: '单场时间', keys: ['avgtime'] },
        { text: '战斗次数', keys: ['numrealfirst', 'numrealall'] }
      ],
      selectedGameinfoProp: 0,
      infoInStat: null,
      labels: null,
      dataSetsObj: null
    }
  },
  methods: {
    getInfoName (key) {
      return this.gameInfoKeys[key]
    },
    onDateQuick (date) {
      this.fromDate = date.fromDate
      this.toDate = date.toDate
    },
    onDateInput (date) {
      this.getRecord()
    },
    onGameInfoPropSelect (select) {
      this.buildPropChartData()
    },
    buildChartData (labels, datasets) {
      labels = labels || []
      datasets = datasets || []
      return { labels, datasets }
    },
    buildPropChartData () {
      // 其中的内容是 date 字符串
      const datasets = []
      // 根据选择的类型得到一组游戏数据
      const gameinfoProps = this.gameinfoProps[this.selectedGameinfoProp].keys
      for (let i = 0; i < gameinfoProps.length; i++) {
        const pid = gameinfoProps[i]
        const dataset = { label: this.getInfoName(pid), data: [], borderColor: nextChartColor(i) }
        for (const date of this.labels) {
          const day = this.dataSetsObj[date]
          dataset.data.push(day[pid] || 0)
        }
        datasets.push(dataset)
      }
      this.chartData = this.buildChartData(this.labels, datasets)
    },
    buildTableData (data) {
      // 留存表格的数据，需要把表格“横”过来
      const stat = data.stat
      const statWithPropKey = {}
      stat.forEach(item => {
        this.infoKeys.forEach(infoKey => {
          if (!statWithPropKey[infoKey]) {
            statWithPropKey[infoKey] = {}
          }
          if (!statWithPropKey[infoKey][item.date]) {
            statWithPropKey[infoKey][item.date] = {}
          }
          statWithPropKey[infoKey][item.date].key = infoKey
          statWithPropKey[infoKey][item.date].date = item.date
          statWithPropKey[infoKey][item.date].name = this.getInfoName(infoKey)
          statWithPropKey[infoKey][item.date].num = item[infoKey]
        })
      })
      // 用于表格的数据
      const statList = []
      Object.values(statWithPropKey).forEach(propItem => {
        Object.values(propItem).forEach(dateItem => {
          statList.push(dateItem)
        })
      })
      const dataSetsObj = {}
      statList.forEach(item => {
        if (!dataSetsObj[item.date]) {
          dataSetsObj[item.date] = {}
        }
        // 将数据按照日期分开
        dataSetsObj[item.date][item.key] = item.num
      })
      // 倒序
      // statList.sort(this.sortChannelAndDate)
      // this.tableData = statList
      this.tableData = stat
      // 所有的键名，这个值并未使用
      this.infoInStat = Object.keys(statWithPropKey)
      this.dataSetsObj = dataSetsObj
      // 其中的内容是 date 字符串
      this.labels = Object.keys(dataSetsObj).sort()
    },
    clearRecord () {
      this.chartData = this.buildChartData()
      this.tableData = []
      this.dataSetsObj = null
      this.labels = null
    },
    async getRecord () {
      if (this.isLoading) {
        return
      }
      if (!this.hub.checkFilters([this.regional, '区服'])) {
        this.clearRecord()
        return
      }
      this.isLoading = true
      try {
        const data = await this.mjp.get('/analy/gameinfo/', {
          from_date: this.formatDate(this.fromDate),
          to_date: this.formatDate(this.toDate)
        })
        this.buildTableData(data)
        this.buildPropChartData()
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    }
  }
}
</script>

<style>
#analygameinfo {
  padding: 12px;
}
</style>
