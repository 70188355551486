<template>
<div id="channelselector2">
  <b-switch v-model="selectAll" @input="onSelectAll">选择全部</b-switch>&nbsp;
  <b-checkbox v-model="selectedChannels" v-for="o in channels"
    :native-value="o.cid" :key="o.cid">
    {{ o.name }}
  </b-checkbox>
</div>
</template>

<script>
/**
 渠道选择器，以 CheckBox 的方式展现
 */

export default {
  props: ['value'],
  created () {
    this.getChannels()
  },
  data () {
    return {
      selectAll: false,
      selectedChannels: [],
      channels: [],
      // 键名是 cid，键值是 name，用于查询
      channelsName: {}
    }
  },
  methods: {
    onSelectAll (select) {
      if (select) {
        this.channels.forEach(item => {
          if (!this.selectedChannels.includes(item.cid)) {
            this.selectedChannels.push(item.cid)
          }
        })
      } else {
        this.selectedChannels.splice(0, this.selectedChannels.length)
      }
      this.$emit('change', this.selectedChannels)
    },
    async getChannels () {
      try {
        const data = await this.mjp.get2({
          url: '/channel/get/all/',
          query: {
            status: 1
          }
        })
        this.selectAll = false
        this.channels = data.channels
        this.channels.forEach(item => {
          this.channelsName[item.cid] = item.name
        })
        // console.log('channelsName:%o', this.channelsName)
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    selectedChannels (to, from) {
      this.$emit('change', this.selectedChannels)
    }
  }
}
</script>
