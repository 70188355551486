<template>
<value-object id="mpconfig"
  ref="vo"
  mjptype="mp"
  :votype="305"
  :refresh-route-path="['/mp/mpconfig']"
  @tab-change="onTabChange" >
  <template slot="top">
    <div class="columns box" style="margin:12px">
      <div class="column has-text-left">
        <h2>具体解释请点击配置左边的箭头符号查看配置详细说明</h2>
        <br>
        <p><code>subscribe</code>：微信公众号关注回复信息配置。</p>
        <p><code>textfilter</code>：微信公众号文字自动回复配置。</p>
        <p><code>defaultmsg</code>：微信公众号默认回复（找不到其他配置时生效）配置。</p>
        <p><code>clickgift2reward</code>：微信公众号菜单单击送奖励配置，请将公众号菜单的 key 配置为 <code>clickgift2</code>。</p>
        <p><code>wmasm</code>： <strong>微信公众平台/字节小游戏/QQ小游戏</strong> 向玩家发送一次性订阅消息模板配置。</p>
        <hr>
        <p>====以下内容已经作废====</p>
        <p><code>wxmsgspecific</code>：微信小游戏向特定玩家发送模版消息配置。</p>
        <p><code>wxsubscriptioninfo</code>: 微信定时推送消息的固定模板,配置方法见 <a href="https://sagigames.feishu.cn/wiki/wikcn5cpFriDBgGLLcItMOPHAOb#gD9tV7" target="_blank">文档</a></p>
        <p><code>wxsubscriptioncrons</code>: 微信定时推送固定消息的时间,配置方法见 <a href="https://sagigames.feishu.cn/wiki/wikcn5cpFriDBgGLLcItMOPHAOb#gD9tV7" target="_blank">文档</a></p>
        <p><code>wxsubscriptioncrons1</code>: 微信定时推送非固定消息的时间,配置方法见 <a href="https://sagigames.feishu.cn/wiki/wikcn5cpFriDBgGLLcItMOPHAOb#YY0PsJ" target="_blank">文档</a></p>
        <p><code>qq2msgall</code>：QQ 小游戏向所有玩家发送模版消息配置。</p>
        <p><code>qq2msgspecific</code>：QQ 小游戏向特定玩家发送模版消息配置。</p>
        <p><code>qq2msgback</code>：QQ 小游戏向将要流失的玩家发送模版消息配置。</p>
        <p><code>qq2gift10000</code>：QQ 小游戏礼包配置。最后的数字 10000 代表礼包 id（在QQ小游戏后台配置），请根据实际情况修改。</p>
      </div>
      <ul class="column">
        <p>外部说明文档：</p>
        <li> <a href="https://developers.weixin.qq.com/minigame/dev/guide/open-ability/subscribe-message.html" target="_blank" class="button is-text">微信小游戏订阅消息</a> </li>
        <li> <a href="https://q.qq.com/wiki/develop/miniprogram/server/open_port/port_subscribe.html" target="_blank" class="button is-text">QQ小游戏订阅消息</a> </li>
        <li> <a href="https://developer.open-douyin.com/docs/resource/zh-CN/mini-game/develop/server/subscribe-notification/notify" target="_blank" class="button is-text">字节小游戏订阅消息</a> </li>
        <li> <a href="https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421140543" target="_blank" class="button is-text">微信公众号被动回复用户消息文档</a> </li>
        <li> <a href="https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421140547" target="_blank" class="button is-text">微信公众号客服消息文档</a> </li>
        <li> <a href="https://docs.qq.com/doc/DREt2d1FMTEpMRUtz" target="_blank" class="button is-text">（已作废）QQ小游戏礼包接入文档</a> </li>
      </ul>
    </div>

  </template>
  <template slot="buttons" slot-scope="props">
    <button class="button is-warning" @click="onEditClick(props.row)">修改</button>
  </template>
  <template slot="tabitems">
    <b-tab-item label="增加">
      <value-object-editor-j-t ref="editorAsAdd" value-type="toml" @edit="onEditSave" />
    </b-tab-item>
    <b-tab-item label="编辑"  :disabled="!inEdit">
      <value-object-editor-j-t ref="editorAsEdit" value-type="toml" @edit="onEditSave" is-edit />
    </b-tab-item>
  </template>
</value-object>
</template>

<script>
import ValueObject from '@/components/vo/ValueObject'
import ValueObjectEditorJT from '@/components/vo/ValueObjectEditorJT'

export default {
  name: 'mpconfig',
  components: { ValueObject, ValueObjectEditorJT },
  data () {
    return {
      inEdit: false
    }
  },
  methods: {
    onTabChange ({ to, from }) {
      if (from === 2) {
        this.$refs.editorAsEdit.clearValues()
      }
      if (from === 1) {
        this.$refs.editorAsAdd.clearValues()
      }
      this.inEdit = to === 2
    },
    onEditClick (vo) {
      this.$refs.editorAsEdit.setValues(vo)
      this.$refs.vo.tabIndex = 2
    },
    async onEditSave (vo) {
      // 增加和编辑使用同一条协议
      try {
        vo.votype = 305
        await this.mjp.post2({
          url: '/cf/vo/set/',
          data: vo,
          query: { r: this.regional },
          mjpType: 'mp'
        })
        this.$refs.vo.tabIndex = 0
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style>
#mpconfig {
  padding: 12px;
}
</style>
