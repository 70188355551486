<template>
  <div>
    <b-field class="markdown-body">
      <help-rsi-app />
    </b-field>
    <b-tabs
      @change="onTabChange"
      v-model="tabIndex"
      position="is-centered"
      type="is-boxed"
      size="is-medium"
    >
      <b-tab-item label="Application">
        <b-table :data="appData" ref="table" detailed detail-key="vid">
          <b-table-column #default="props" field="vid" label="vid" width="60" numeric centered>{{ props.row.vid}}</b-table-column>
          <b-table-column #default="props" field="name" label="名称" width="200">{{ props.row.name}}</b-table-column>
          <b-table-column #default="props" field="status" label="状态" width="60" centered>
            <b-tag :type="props.row.status===1?'is-success':'is-light'">{{ props.row.status===1?'已启用':'已禁用'}}</b-tag>
          </b-table-column>
          <b-table-column #default="props" label="操作">
            <div class="buttons">
              <button class="button is-warning" @click="onEditClick(props.row)">修改</button>
              <button class="button is-danger" @click="ondeleteClick(props.row)">删除</button>
            </div>
          </b-table-column>
          <template #detail="props" >
            <pre>{{props.row.value}}</pre>
          </template>
        </b-table>
      </b-tab-item>
      <b-tab-item label="增加">
        <section>
          <b-field horizontal label="名称" width="100">
            <b-input v-model="saveData.name" placeholder="必填"></b-input>
          </b-field>
          <b-field horizontal label="配置">
            <b-input v-model="saveData.value" type="textarea" rows="10" placeholder="选填-TOML格式"></b-input>
          </b-field>
          <b-field horizontal label="状态" style="text-align:left">
            <b-switch
              v-model.number="saveData.status"
              true-value="1"
              false-value="5"
            >{{saveData.status == 1?'启用':'禁用'}}</b-switch>
          </b-field>
          <b-field grouped position="is-centered">
            <button class="button is-danger" :disabled="!canAdd" size="is-medium" @click="saveApp()" >保存</button>
          </b-field>
        </section>
      </b-tab-item>
      <b-tab-item label="编辑" :disabled="!inEdit">
        <section>
          <b-field horizontal label="vid" style="text-align:left">{{saveData.vid}}</b-field>
          <b-field horizontal label="名称" width="100">
            <b-input v-model="saveData.name" placeholder="英文字符"></b-input>
          </b-field>
          <b-field horizontal label="配置">
            <b-input v-model="saveData.value" type="textarea" rows="10" placeholder="必填-TOML格式"></b-input>
          </b-field>
          <b-field horizontal label="状态" style="text-align:left">
            <b-switch
              v-model.number="saveData.status"
              true-value="1"
              false-value="5"
            >{{saveData.status == 1?'启用':'禁用'}}</b-switch>
          </b-field>
          <b-field grouped position="is-centered">
            <button class="button is-danger" :disabled="!canSave" size="is-medium" @click="saveEdit()" >保存</button>
          </b-field>
        </section>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import toml from '@iarna/toml'
import HelpRsiApp from '@/help/rsiapp.md'

export default {
  name: 'rsiapplication',
  components: { HelpRsiApp },
  data () {
    return {
      appData: [],
      tabIndex: 0,
      inEdit: false,
      saveData: {
        status: 1
      }
    }
  },
  created () {
    this.init()
  },
  watch: {
    tabIndex (to) {
      if (to === 1) {
        this.saveData = {
          status: 1
        }
      }
    }
  },
  computed: {
    canSave () {
      return this.saveData.name && this.saveData.status
    },
    canAdd () {
      return this.saveData.name && this.saveData.status && this.saveData.value
    }
  },
  methods: {
    init () {
      this.getData()
    },
    testToml (t) {
      try {
        toml.parse(t)
        return true
      } catch (e) {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'TOML格式错误',
          position: 'is-bottom',
          type: 'is-danger'
        })
        console.log(e)
        return false
      }
    },
    async getData () {
      try {
        const results = await this.mjp.get('/rsi/app/get/all/')
        this.appData = results.apps
      } catch (e) {
        console.log(e)
      }
    },
    async onEditSave (url, s) {
      try {
        await this.mjp.post(url, s)
        this.$buefy.toast.open({
          message: '操作成功！',
          type: 'is-success'
        })
        this.freshData()
      } catch (e) {
        console.log(e)
      }
    },
    saveApp () {
      if (this.testToml(this.saveData.value)) {
        this.onEditSave('/rsi/app/add/', this.saveData)
      }
    },
    ondeleteClick (s) {
      try {
        this.$buefy.dialog.confirm({
          message: '确认删除？',
          type: 'is-danger',
          onConfirm: async () => {
            await this.mjp.post('/rsi/app/del/', { vid: s.vid, name: s.name })
            this.freshData()
            this.$buefy.toast.open({
              message: '删除成功',
              type: 'is-success'
            })
          }
        })
      } catch (e) {
        console.log(e)
        this.$buefy.toast.open({
          message: '删除失败',
          type: 'is-danger'
        })
      }
    },
    onEditClick (data) {
      this.saveData = data
      this.tabIndex = 2
      this.inEdit = true
    },
    saveEdit () {
      if (this.saveData.value) {
        if (this.testToml(this.saveData.value)) {
          this.onEditSave('/rsi/app/edit/', this.saveData)
        }
      } else {
        this.onEditSave('/rsi/app/edit/', this.saveData)
      }
    },
    onTabChange (to) {
      if (to !== 2) {
        this.inEdit = false
      }
    },
    freshData () {
      this.init()
      this.tabIndex = 0
    }
  }
}
</script>
