<template>
  <b-menu>
    <b-menu-list label="运营功能" icon="star">
      <b-menu-item v-if="checkPermit(51, 53, 55)" label="大盘数据" icon="barcode" tag="router-link" to="/home" />
      <b-menu-item v-if="!checkPermit(50)" icon="gamepad" label="游戏列表" tag="router-link" to="/regionallist" @click="hub.$emit('regional-click')" />
      <b-menu-item label="公众号配置" icon="cog" tag="router-link" to="/vol" />
      <b-menu-item v-if="hasSafeR && checkPermit(51, 53)" icon="cogs" label="AUTH配置">
        <b-menu-item label="频繁访问" icon="tune" tag="router-link" to="/vogc" />
        <b-menu-item label="所有分类" icon="table-settings" tag="router-link" to="/vol" />
        <b-menu-item label="发布公告" tag="router-link" to="/releasenote" />
        <b-menu-item v-if="hasHTTP" label="游戏服务器" tag="router-link" to="/gameserver" />
        <b-menu-item v-if="hasRank" label="排行榜" tag="router-link" to="/ranklist" />
      </b-menu-item>
      <b-menu-item v-if="hasSafeR && hasPA && checkPermit(51, 53, 55)" icon="equalizer" label="统计">
        <b-menu-item label="渠道用户" tag="router-link" to="/analysis" />
        <!-- 数据库过大的时候无法查询，故而取消 -->
        <!-- <b-menu-item label="分享进入用户" tag="router-link" to="/analy500" /> -->
        <b-menu-item label="统计比例" tag="router-link" to="/analypercent" />
        <b-menu-list v-if="isComplexAnalysis" label="射手专用">
          <b-menu-item label="实时在线" tag="router-link" to="/analyrealtime" />
          <b-menu-item label="场景道具" tag="router-link" to="/analysceneprops" />
          <b-menu-item label="商城数据" tag="router-link" to="/analyshop" />
          <b-menu-item label="游戏数据" tag="router-link" to="/analygameinfo" />
          <b-menu-item label="角色数量" tag="router-link" to="/analyactor" />
          <b-menu-item label="视频广告" tag="router-link" to="/analyvideoad" />
          <b-menu-item label="死亡竞赛" tag="router-link" to="/analydead" />
        </b-menu-list>
      </b-menu-item>
      <b-menu-item v-if="hasSafeR && checkPermit(51, 53)" icon="rowing" label="运营">
        <b-menu-item v-if="hasPA" label="玩家管理" tag="router-link" to="/account" />
        <b-menu-item v-if="hasPrize" label="兑换码" tag="router-link" to="/redeemcode" />
        <b-menu-item label="CDN文件管理" tag="router-link" to="/cdnfiles" />
        <b-menu-item v-if="hasHTTP" label="后台接口" tag="router-link" to="/mana" />
        <!-- <b-menu-item v-if="hasMP" label="公众号图文配置" tag="router-link" to="/mp/materialnews" /> -->
        <b-menu-item v-if="hasMP" label="公众号配置" tag="router-link" to="/mp/mpconfig" />
        <b-menu-item v-if="hasMP" label="公众号模版消息" tag="router-link" to="/mp/mptemsg" />
        <b-menu-item v-if="hasMP" label="公众号素材" tag="router-link" to="/mp/materiallist" />
        <b-menu-item v-if="hasMP" label="公众号菜单" tag="router-link" to="/mp/mpmenu" />
        <b-menu-item v-if="hasMP" label="公众号关注" tag="router-link" to="/mp/follows" />
        <b-menu-item v-if="hasMP" label="红包奖励" tag="router-link" to="/hongbao" />
        <b-menu-item v-if="hasMP" label="QQ钱包" tag="router-link" to="/qqwallet" />
      </b-menu-item>
    </b-menu-list>
    <b-menu-list v-if="checkPermit(51)" label="服务管理" icon="waves">
      <b-menu-item icon="server" label="MJP服务器">
        <b-menu-item label="Key" tag="router-link" to="/key" />
        <b-menu-item label="Server" tag="router-link" to="/serverlist" />
        <b-menu-item label="Application" tag="router-link" to="/applist" />
        <b-menu-item label="Instance" tag="router-link" to="/instance" />
        <b-menu-item label="Relation" tag="router-link" to="/relation" />
      </b-menu-item>
      <b-menu-item v-if="checkPermit(51, 53)" label="MAPI" tag="router-link" to="/mapi" />
      <b-menu-item v-if="checkPermit(51)" label="负载统计" tag="router-link" to="/statsinst" />
    </b-menu-list>
    <b-menu-list label="功能区" icon="memory">
      <b-menu-item v-if="hasR" label="ADM配置" tag="router-link" icon="cog" to="/admvol" />
      <b-menu-item label="渠道管理" tag="router-link" to="/channel" />
      <b-menu-item label="ID转换器" tag="router-link" to="/idconvert" />
      <b-menu-item v-if="checkPermit(51)" label="生成器" tag="router-link" to="/gen" />
      <b-menu-item v-if="checkPermit(51)" label="操作历史" tag="router-link" to="/history" />
      <b-menu-item v-if="checkPermit(50, 51)" label="后台管理员" tag="router-link" to="/admin" />
      <b-menu-item v-if="loginUserProfile" :label="loginUserProfile" icon="account">
        <b-menu-item label="帐号设置" tag="router-link" to="/profile" />
        <b-menu-item label="登出" tag="a" @click="onLogout" />
      </b-menu-item>
    </b-menu-list>
    <b-menu-list label="外部链接" icon="link">
      <b-menu-item
          label="TinyClue"
          tag="a"
          target="_blank"
          href="https://tinyclue.sagigame.cn/">
      </b-menu-item>
      <b-menu-item
          label="DataSoar"
          tag="a"
          target="_blank"
          href="https://datasoar-web.app.sagigames.top/">
      </b-menu-item>
    </b-menu-list>
  </b-menu>
</template>

<script>
// 全局菜单组件

import cache from '@/core/cache'
import auth from '@/core/auth'

export default {
  name: 'appmenu',
  data () {
    const user = cache.getUser()
    return {
      user: user,
      regional: cache.getR(),
      hasMP: false,
      hasPA: false,
      hasHTTP: false,
      hasRank: false,
      hasPrize: false,
      curRouterPath: this.$route.path
    }
  },
  created () {
    this.updateLogin()
    this.hub.$on('login-success', this.updateLogin)
    this.hub.$on('logout', this.onLogout)
    this.hub.$on('regional-change', this.onRegionalChange)
  },
  destroyed () {
    this.hub.$off('login-success', this.updateLogin)
    this.hub.$off('logout', this.onLogout)
    this.hub.$off('regional-change', this.onRegionalChange)
  },
  computed: {
    loginUserProfile () {
      if (this.user) {
        return `${this.user.typename}-${this.user.nickname}(${this.user.username})`
      }
      return ''
    },
    hasR () {
      return Number.isInteger(this.regional)
    },
    /**
     * 不包含 0/-1 等特殊的 regional
     */
    hasSafeR () {
      return Number.isInteger(this.regional) && this.regional > 0
    },
    // 这三个都是（开心射手/天才射手/猎头专家）在不同平台上的版本。后面的统计不再使用这种方式，而是使用 zeppelin 了
    isComplexAnalysis () {
      return [1, 200, 300, 5118].includes(this.regional)
    }
  },
  methods: {
    checkPermit (...permits) {
      const typeid = cache.getUserType()
      // console.log('checkPermit, permit:%s, typeid:%s', permits, typeid)
      return typeid && permits.includes(typeid)
    },
    updateLogin () {
      this.user = cache.getUser()
      // 首次启动的时候，不一定能收到 regioinalSelect 发出的变化事件
      this.onRegionalChange(cache.getR())
    },
    onLogout () {
      auth.logout()
      this.user = null
      this.mjpType = null
      this.regional = null
      this.$router.replace('/')
    },
    onRegionalChange (regional) {
      // console.warn('onRegionalChange regional %s', regional)
      if (regional === null) {
        return
      }
      this.regional = regional
      // console.log('this.regional', this.regional)
      const mjpObj = cache.getRegional()
      // console.warn('onRegionalChange mjpObj %o', mjpObj)
      if (!mjpObj) {
        return
      }
      this.mjpType = mjpObj.mjptype
      const MPURI = cache.getMJPURI('mp')
      const PAURI = cache.getMJPURI('pa')
      const rankURI = cache.getMJPURI('rank')
      const prizeURI = cache.getMJPURI('prize')

      // 是否带有公众号
      this.hasMP = Boolean(MPURI)
      // 是否带有统计功能
      this.hasPA = Boolean(PAURI)
      this.hasHTTP = mjpObj.gs
      // 是否带有排行榜功能
      this.hasRank = Boolean(rankURI)
      // 是否有礼物功能
      this.hasPrize = Boolean(prizeURI)
      console.log(`mjpType: ${this.mjpType} hasMP: ${this.hasMP} hasPA: ${this.hasPA} hasHTTP ${this.hasHTTP} hasRank: ${this.hasRank} hasPrize: ${this.hasPrize}`)

      // 如果没有对应的菜单，就切换到首页
      if (!this.hasMP &&
        (this.curRouterPath.startsWith('/mp') ||
        this.curRouterPath.startsWith('/withdraw'))) {
        this.$router.replace('/')
      }
      if (!this.hasPA &&
        (this.curRouterPath.startsWith('/analy') ||
        this.curRouterPath.startsWith('/account'))) {
        this.$router.replace('/')
      }
      if (!this.hasHTTP &&
        (this.curRouterPath.startsWith('/server') ||
        this.curRouterPath.startsWith('/mana'))) {
        this.$router.replace('/')
      }
      if (!this.hasRank && this.curRouterPath.startsWith('/ranklist')) {
        this.$router.replace('/')
      }
      // console.warn('onRegionalChange %s', regional)
    }
  }
}
</script>
