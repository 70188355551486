<template>
<div id="mana">
  <b-tabs v-model="tabIndex" position="is-centered" type="is-boxed" size="is-medium">
    <b-tab-item label="功能开关">
      <set-onoff ref="setonoff" @do="onDo" />
    </b-tab-item>
    <b-tab-item label="服务器维护">
      <set-maintain ref="setmaintain" @do="onDo" />
    </b-tab-item>
    <b-tab-item label="发送邮件">
      <send-mail ref="sendmail" @do="onDo" />
    </b-tab-item>
    <b-tab-item label="修改客户端">
      <set-client-config ref="setclientconfig" @do="onDo" />
    </b-tab-item>
    <b-tab-item v-if="isTestRegional" label="修改玩家">
      <set-player ref="setplayer" @do="onDo"/>
    </b-tab-item>
    <b-tab-item v-if="isTestRegional" label="清除玩家">
      <clear-player ref="clearplayer" @do="onDo" />
    </b-tab-item>
    <b-tab-item v-if="isTestRegional" label="时间加速">
      <set-time-offset ref="settimeoffset" @do="onDo" />
    </b-tab-item>
  </b-tabs>
</div>
</template>

<script>
import cache from '@/core/cache'

import SetPlayer from '@/components/mana/SetPlayer'
import SetTimeOffset from '@/components/mana/SetTimeOffset'
import SetOnoff from '@/components/mana/SetOnoff'
import SetMaintain from '@/components/mana/SetMaintain'
import SendMail from '@/components/mana/SendMail'
import SetClientConfig from '@/components/mana/SetClientConfig'
import ClearPlayer from '@/components/mana/ClearPlayer'

/**
 * 所有的 URL 路径和方法
 * url 调用地址
 * method 调用的 HTTP 方法
 * refresh 调用成功之后是否需要立即刷新
 * */
const urls = {
  clearplayer: { url: '/mana/clearplayer/', method: 'post' },
  setplayer: { url: '/mana/setplayer/', method: 'post' },
  settimeoffset: { url: '/mana/settimeoffset/', method: 'post' },
  setonoff: { url: '/mana/setonoff/', method: 'post', refresh: true },
  getonoff: { url: '/mana/getonoff/', method: 'get' },
  setmaintain: { url: '/mana/setmaintain/', method: 'post', refresh: true },
  sendmail: { url: '/mana/sendmail/', method: 'post', refresh: true },
  setclientconfig: { url: '/mana/setclientconfig/', method: 'post', refresh: true }
}

// const refIndexNames = ['setonoff', 'setmaintain', 'sendmail', 'setclientconfig', 'setplayer', 'clearplayer', 'settimeoffset']

export default {
  name: 'mana',
  components: { SetPlayer, SetTimeOffset, SetOnoff, SetMaintain, SendMail, SetClientConfig, ClearPlayer },
  created () {
    this.isTestRegional = this.checkTest(cache.getR(true))
  },
  data () {
    return {
      tabIndex: 0,
      isTestRegional: null
    }
  },
  methods: {
    checkTest (regional) {
      return regional >= 1000 && regional < 2000
    },
    async onDo (evt) {
      console.log('onDo %o', evt)
      try {
        const urlcfg = urls[evt.name]
        await this.mjp[urlcfg.method](urlcfg.url, evt)
        this.hub.alert('调用成功!', 4, 'is-success')
        if (urlcfg.refresh) {
          this.$refs[evt.name].refresh()
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style>
#mana {
  padding: 12px;
}
</style>
