<template>
<div id="releasenote">
  <help-release-note  v-if="tabIndex === 0" class="markdown-body" />
  <help-release-note-editor v-else class="markdown-body" />
  <b-tabs v-model="tabIndex" position="is-centered" type="is-boxed" size="is-medium">
    <b-tab-item label="发布公告列表">
      <b-table :data="releaseNotes"
        paginated backend-pagination
        detailed detail-key="vid"
        :per-page="perPage"
        :total="total"
        :current-page="currentPage"
        @page-change="onPageChange">
        <b-table-column #default="props" field="status" label="状态" width="60">
          <b-tag :type="statusColor(props.row.status)"> {{ statusText(props.row.status) }}</b-tag>
        </b-table-column>
        <b-table-column #default="props" field="vid" label="VID" width="40" numeric>
          {{ props.row.vid }}
        </b-table-column>
        <b-table-column #default="props" field="name" label="name" width="120">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column #default="props" field="version" label="版本" width="60">
          {{ props.row.version }}
        </b-table-column>
        <b-table-column #default="props" field="copy" label="aslist=0" width="100" centered>
          <b-tooltip
            :label="`复制URL到剪贴板: ${getConfigUrl(props.row, 0)}`">
            <b-button
              :id="`copy-url-${props.row.vid}`"
              icon-right="link-plus"
              type="is-text"
              :data-clipboard-text="getConfigUrl(props.row, 0)"
              @click="onClipboardClick(`copy-url-${props.row.vid}`)" />
          </b-tooltip>
        </b-table-column>
        <b-table-column #default="props" field="copy" label="aslist=1" width="100" centered>
          <b-tooltip
            :label="`复制URL到剪贴板: ${getConfigUrl(props.row, 1)}`">
            <b-button
              :id="`copy-url-${props.row.vid}`"
              icon-right="link-plus"
              type="is-text"
              :data-clipboard-text="getConfigUrl(props.row, 1)"
              @click="onClipboardClick(`copy-url-${props.row.vid}`)" />
          </b-tooltip>
        </b-table-column>
        <b-table-column #default="props" field="date" label="日期" width="120">
          {{ props.row.date }}
        </b-table-column>
        <b-table-column #default="props" field="note" label="备注" expanded>
          {{ props.row.note }}
        </b-table-column>
        <b-table-column #default="props" label="操作" width="180">
          <div class="buttons">
            <button class="button is-warning" @click="onEditClick(props.row)">修改</button>
            <button class="button is-danger" @click="onDeleteClick(props.row)">删除</button>
          </div>
        </b-table-column>
        <template #bottom-left>
          <b>总计：</b>{{ total }}
        </template>
        <template #detail="{row}">
          <pre class="prewrap">{{ row.msg }}</pre>
        </template>
      </b-table>
    </b-tab-item>
    <b-tab-item label="增加公告">
      <release-note-editor ref="rnAdd" @edit="onEditSave" />
    </b-tab-item>
    <b-tab-item label="编辑公告" :disabled="!inEdit">
      <release-note-editor ref="rnEdit" is-edit="true" @edit="onEditSave" />
    </b-tab-item>
  </b-tabs>
</div>
</template>

<script>
import config from '@/core/config'
import cache from '@/core/cache'
import ReleaseNoteEditor from '@/components/vo/ReleaseNoteEditor'
import Clipboard from 'clipboard'
import HelpReleaseNote from '@/help/vorn.md'
import HelpReleaseNoteEditor from '@/help/vorne.md'

export default {
  name: 'releasenote',
  components: { ReleaseNoteEditor, HelpReleaseNote, HelpReleaseNoteEditor },
  mounted () {
    this.regional = cache.getR()
    this.getReleaseNotes()
  },
  data () {
    return {
      tabIndex: 0,
      perPage: config.PER_PAGE,
      currentPage: 1,
      total: 0,
      releaseNotes: [],
      inEdit: false,
      isLoading: false,
      regional: cache.getR()
    }
  },
  methods: {
    getConfigUrl (row, aslist) {
      aslist = aslist === undefined ? 1 : Number.parseInt(aslist)
      const configUrlPrefix = '/cf/releasenote/get/'
      const baseUrl = this.mjp.getMJPURI(configUrlPrefix, 'auth', this.regional)
      const params = [`r=${this.regional}`, `ver=${row.version}`, `aslist=${aslist}`]
      const url = `${baseUrl}${configUrlPrefix}?${params.join('&')}`
      return url
    },
    statusColor (status) {
      return status === 1 ? 'is-success' : 'is-danger'
    },
    statusText (status) {
      return status === 1 ? '启用' : '禁用'
    },
    onClipboardClick (btnid) {
      const c = new Clipboard(`#${btnid}`)
      c.on('success', e => {
        this.hub.alert(`已经复制 ${e.text} 到剪贴板。`, 1.5, 'is-success')
      })
      c.on('error', e => {
        this.hub.alert('复制失败啦！您自己解决吧。', 1.5, 'is-danger')
      })
    },
    onEditClick (vo) {
      this.$refs.rnEdit.setValues(vo)
      this.tabIndex = 2
      this.inEdit = true
    },
    async onDeleteClick (vo) {
      const msg = `是否要删除发布日志 ${vo.version} ？`
      this.$buefy.dialog.confirm({
        title: '删除发布日志',
        message: msg,
        onConfirm: async () => {
          try {
            await this.mjp.post2({
              url: '/cf/vo/del/',
              data: { vid: vo.vid }
            })
            this.hub.alert(`删除发布日志 ${vo.version} 成功！`, 5)
            this.getReleaseNotes()
          } catch (error) {
            console.error(error)
          }
        }
      })
    },
    onPageChange (page) {
      this.currentPage = page
      this.getReleaseNotes(page)
    },
    async onEditSave (vo) {
      // 增加和编辑使用同一条协议
      try {
        await this.mjp.post2({
          url: '/cf/releasenote/set/',
          data: vo.value,
          query: { ver: vo.ver }
        })
        this.tabIndex = 0
      } catch (error) {
        console.error(error)
      }
    },
    async getReleaseNotes (page) {
      if (this.isLoading) {
        return
      }
      // 在切换 regional 的时候，可能会造成 onPageChange 的调用，这回导致请求两次。在这里用时间间隔来控制一下。
      this.isLoading = setTimeout(() => { this.isLoading = 0 }, 500)
      page = page || this.currentPage || 1
      try {
        const data = await this.mjp.get2({
          url: '/cf/releasenote/get/more/',
          qery: { page: page, per_page: this.perPage }
        })
        this.releaseNotes = data.vos
        this.currentPage = data.page
        this.perPage = data.per_page
        this.total = data.total
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    tabIndex (to, from) {
      if (from === 2) {
        this.inEdit = false
        this.$refs.rnEdit.clearValues()
      }
      if (from === 1) {
        this.$refs.rnAdd.clearValues()
      }
      if (to === 0) {
        this.getReleaseNotes()
      }
    }
  }
}
</script>

<style>
#releasenote {
  padding: 12px;
}
</style>
