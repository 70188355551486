<template>
  <b-select v-model="ntype" @input="onInput" :multiple="multiple">
    <option value="auth">auth</option>
    <option value="tool">tool</option>
    <option value="prize">prize</option>
    <option value="cache">cache</option>
    <option value="rank">rank</option>
    <option value="pa">pa</option>
    <option value="mp">mp</option>
    <option value="hlw">葫芦娃</option>
    <option value="stats">stats</option>
    <option value="compete">compete</option>
  </b-select>
</template>
<script>
export default {
  name: 'rsiinstance',
  props: ['value', 'multiple'],
  data () {
    return {
      ntype: 'auth'
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>
