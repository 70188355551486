import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import JsonEditor from 'vue-json-edit'
import 'json-editor'

// markdown 组件需要增加 class=markdown-body
// 代码高亮
import 'highlight.js/styles/github.css'
// 其他元素使用 github 的样式
import 'github-markdown-css'

// eslint-disable-next-line no-unused-vars
// import $ from 'jquery'
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'
// import 'font-awesome/css/font-awesome.min.css'

import '@mdi/font/css/materialdesignicons.css'
import { utilInstall, eventHub } from '@/core/util'
import { API } from '@/core/api'

/**
出现这样的错误，这可能是 Vue 2.6.11 的 bug。目前没有解决方案，因此屏蔽它，等待下一个版本解决。

https://github.com/vuetifyjs/vuetify/issues/9999

[Vue warn]: The .native modifier for v-on is only valid on components but it was used on <a>.

found in

---> <BMenuItem>
       <BMenu>
         <Appmenu> at src/components/AppMenu.vue
           <App> at src/App.vue
             <Root>
 */

// const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <a>.'
// Vue.config.warnHandler = function (msg, vm, trace) {
//   // `trace` is the component hierarchy trace
//   if (msg === ignoreWarnMessage) {
//     msg = null
//     vm = null
//     trace = null
//   }
// }

Vue.config.productionTip = false
Vue.use(Buefy)
Vue.use(utilInstall)
Vue.use(API, { router, eventHub })
Vue.use(JsonEditor)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
