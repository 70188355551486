<template>
<value-object ref="vo"
  :refresh-route-path="['/vol']"
  :votype=307
  @tab-change="onTabChange">
  <template slot="top">
    <b-field grouped class="box" position="is-centered">
      <b-select placeholder="votype" icon="view-module" v-model.number="selectVoType" @input="onVoTypeSelect">
        <option v-for="o in votypes" :key="o.value" :value="o.value">{{o.text}}</option>
      </b-select>
    </b-field>
  </template>
  <template slot="buttons" slot-scope="props">
    <button class="button is-warning" @click="onEditClick(props.row)">修改</button>
  </template>
  <template slot="tabitems">
    <b-tab-item label="增加">
      <value-object-editor-j-t
        ref="editorAsAdd"
        value-type="toml"
        @edit="onEditSave" />
    </b-tab-item>
    <b-tab-item label="编辑" :disabled="!inEdit">
      <value-object-editor-j-t
        ref="editorAsEdit"
        value-type="toml"
        is-edit
        @edit="onEditSave" />
    </b-tab-item>
  </template>
</value-object>
</template>

<script>
import type from '@/core/type'
import ValueObject from '@/components/vo/ValueObject'
import ValueObjectEditorJT from '@/components/vo/ValueObjectEditorJT'

export default {
  name: 'vol',
  components: { ValueObject, ValueObjectEditorJT },
  data () {
    return {
      inEdit: false,
      selectVoType: 307
    }
  },
  computed: {
    votypes () {
      return type.voTypes()
    }
  },
  methods: {
    onVoTypeSelect (value) {
      this.$refs.vo.setVotype(value)
      this.$refs.vo.getRecords()
    },
    onTabChange ({ to, from }) {
      if (from === 2) {
        this.$refs.editorAsEdit.clearValues()
      }
      if (from === 1) {
        this.$refs.editorAsAdd.clearValues()
      }
      this.inEdit = to === 2
    },
    onEditClick (vo) {
      try {
        this.$refs.editorAsEdit.setValues(vo)
        this.$refs.vo.tabIndex = 2
      } catch (error) {
        this.hub.alert(`JSON 解析失败，配置可能是其他格式！<br><br>${error}`, 2, 'is-danger')
      }
    },
    onEditSave (vo) {
      this.$refs.vo.onEditSave(vo)
    }
  }
}
</script>
