<template>
<div id="setonoff">
  <b-field horizontal :label="o.name" :key="index" v-for="(o, index) in items">
    <b-switch v-model="o.value" :ref="o.key+'Switch'" >{{o.value ? '开' : '关'}}</b-switch>
  </b-field>
  <button class="button is-danger" @click="onClick">设置</button>
</div>
</template>

<script>

const KEY_NAMES = {
  qr: '公众二维码',
  fight: '对战/和好友玩/无限模式',
  sd: '分享翻倍',
  redpack: '红包活动',
  othergame: '小游戏跳转',
  ad: '激励视频',
  entrance: 'Home入口(训练，冒险，好友)',
  fightui: '战场UI(边界，奖励，回合切换，道具)',
  finish: '终结技及终结技引导',
  guide: '新手引导(引导线，上次攻击)',
  homeui: '主界面UI(排行，活动入口，游戏圈)',
  link: '外部链接(跳转到小游戏，公众号，SDK)',
  qqadex: 'QQ Banner 广告误触',
  vd: '视频翻倍默认勾选',
  hwturn: '大转盘',
  smile: '悦人SDK',
  collage: '拼单助力',
  rpool: '奖励池'
}

export default {
  name: 'setonoff',
  created () {
    this.refresh()
  },
  computed: {
    canSet () {
      return false
    }
  },
  data () {
    return {
      items: []
    }
  },
  methods: {
    getRef (key) {
      const comps = this.$refs[key + 'Switch']
      if (comps) return comps[0]
      return null
    },
    async refresh () {
      const data = await this.mjp.get('/mana/getonoff/')
      this.items = []
      for (const k in data.data) {
        const onoff = data.data[k] === 1
        this.items.push({ key: k, value: onoff, name: KEY_NAMES[k] || k })
      }
    },
    onClick (key) {
      const data = { name: 'setonoff' }
      this.items.forEach(item => {
        const ref = this.getRef(item.key)
        let onoff = 0
        if (ref) {
          onoff = ref.value ? 1 : 0
        }
        data[item.key] = onoff
      })
      this.$emit('do', data)
    }
  }
}
</script>
