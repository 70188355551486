<template>
  <div id="adminedit">
    <b-field horizontal label="用户名">
      <b-input icon="account" name="uername" v-model.trim="userName"></b-input>
    </b-field>
    <b-field horizontal label="昵称">
      <b-input icon="account-heart" name="nickname" v-model.trim="nickName"></b-input>
    </b-field>
    <b-field horizontal label="密码">
      <b-input icon="key" name="password" v-model.trim="password" :placeholder="passwordph"></b-input>
    </b-field>
    <b-field horizontal label="企业微信">
      <b-input v-model.trim="wechatWorkId" placeholder="留空则不设置"></b-input>
    </b-field>
    <b-field horizontal label="类型">
      <b-select v-model="userType" icon="view-list" expanded>
        <option v-for="typ in userTypes"
          :value="typ.value"
          :key="typ.value">
          {{typ.text}}
        </option>
      </b-select>
    </b-field>
    <b-field horizontal label="状态">
      <b-switch v-model.number="status" true-value="1" false-value="5">
        {{status === 1?'启用':'禁用'}}
      </b-switch>
    </b-field>
    <b-field horizontal label="渠道管理">
      <div>
        <b-switch v-model="includeAllChannels" @input="onIncludeAllChannels">所有渠道</b-switch>
        <div v-if="!includeAllChannels" class="columns is-vcentered">
          <div class="column">
            可用渠道
            <b-select v-model="selectedAddChannelIDs" multiple native-size="8" expanded>
              <option v-for="channel in channels"
                :value="channel.cid"
                :key="channel.cid">
                {{channel.name}}
              </option>
            </b-select>
          </div>
          <div v-if="!includeAllChannels">
            <div>
              <b-button
                icon-right="arrow-right"
                :disabled="selectedAddChannelIDs.length === 0"
                @click="onChannelAdd" />
            </div>
            <div>
              <b-button
                icon-right="arrow-left"
                :disabled="selectedRemoveChannelIDs.length === 0"
                @click="onChannelRemove" />
            </div>
          </div>
          <div v-if="!includeAllChannels" class="column">
            允许<strong>{{nickName}}</strong>管理的渠道
            <b-select v-model="selectedRemoveChannelIDs" multiple native-size="8" expanded>
              <option v-for="channel in managedChannels"
                :value="channel.cid"
                :key="channel.cid">
                {{channel.name}}
              </option>
            </b-select>
          </div>
        </div>
      </div>
    </b-field>
    <b-field horizontal label="区服管理">
      <div>
        <b-switch v-model="includeAllRegionals" @input="onIncludeAllRegionals">所有区服</b-switch>
        <div v-if="!includeAllRegionals" class="columns is-vcentered">
          <div class="column">
            <regional-selector ref="regionalSelector" @select="regionalSelect" :show-type-filter="false" :include-types="[5000, 2000, 1000]" />
            所有的区服
            <b-select v-model="selectedAddRegionalIDs" multiple native-size="8" expanded>
              <option v-for="regional in regionals"
                :value="regional.r"
                :key="regional.r">
                ({{regional.r}}){{regional.name}}
              </option>
            </b-select>
          </div>
          <div v-if="!includeAllRegionals">
            <div>
              <b-button
                icon-right="arrow-right"
                :disabled="selectedAddRegionalIDs.length === 0"
                @click="onRegionalAdd" />
            </div>
            <div>
              <b-button
                icon-right="arrow-left"
                :disabled="selectedRemoveRegionalIDs.length === 0"
                @click="onRegionalRemove" />
            </div>
          </div>
          <div v-if="!includeAllRegionals" class="column">
            允许<strong>{{nickName}}</strong>管理的区服
            <b-select v-model="selectedRemoveRegionalIDs" multiple native-size="8" expanded>
              <option v-for="regional in managedRegionals"
                :value="regional.r"
                :key="regional.r">
                ({{regional.r}}){{regional.name}}
              </option>
            </b-select>
          </div>
        </div>
      </div>
    </b-field>
    <b-field grouped position="is-centered">
      <button class="button is-danger" :disabled="!canSave" @click="onSaveAdmin">保存</button>
    </b-field>
    <hr>
    <b-field v-if="isEdit" horizontal label="绑定微信">
      <nickname-searcher ref="nicknameselector" @input="onNicknameSelect" show-select />
      <button v-if="bindTargetUser!==null" class="button is-info" @click="onBindWechatClick">绑定</button>
      <b-tag closable v-if="bindedUnionid" class="is-success" @close="onUnBindWechatClick">{{ bindedUnionid }}</b-tag>
    </b-field>
  </div>
</template>

<script>
import type from '@/core/type'
import cache from '@/core/cache'
import NicknameSearcher from '@/components/NicknameSearcher'
import RegionalSelector from '@/components/RegionalSelector'

export default {
  components: { NicknameSearcher, RegionalSelector },
  computed: {
    passwordph () {
      if (this.isEdit) {
        return '不修改密码请留空'
      }
      return null
    },
    canSave () {
      const s = this.userName && this.nickName && this.userType
      // 编辑状态下不检测密码状态
      if (this.isEdit) {
        return s
      }
      return s && this.password
    },
    userTypes () {
      const user = cache.getUser()
      if (user.usertype === 50) {
        return type.filterTypes([51, 53, 55, 61])
      } else if (user.usertype === 51) {
        return type.filterTypes([53, 55, 61])
      }
      return type.filterTypes([61])
    },
    regional () {
      return cache.getR(true)
    }
  },
  // 编辑管理员则值为 true， 增加管理员则值为 false
  props: ['isEdit'],
  data: function () {
    return {
      bindTargetUser: null,
      bindedUnionid: null,
      userName: '',
      nickName: '',
      userType: null,
      wechatWorkId: null,
      showWechatId: false,
      status: 1,
      password: '',
      adminUid: null,
      scheme: null,
      channels: [],
      regionals: [],
      includeAllChannels: false,
      // 选择的准备加入的渠道
      selectedAddChannelIDs: [],
      // 选择的准备删除的渠道
      selectedRemoveChannelIDs: [],
      // 当前已经允许管理的渠道多信息列表
      managedChannels: [],
      includeAllRegionals: false,
      // 选择的准备加入的游戏
      selectedAddRegionalIDs: [],
      // 选择的准备删除的游戏
      selectedRemoveRegionalIDs: [],
      // 当前已经允许管理的游戏多信息列表
      managedRegionals: []
    }
  },
  methods: {
    regionalSelect (regionalList) {
      this.regionals = regionalList.concat()
      this.updateRegionals()
    },
    onNicknameSelect (user) {
      console.log('onNicknameSelect %o', user)
      if (user !== null) {
        this.bindTargetUser = user
      }
    },
    async onBindWechatClick () {
      try {
        const userObj = {
          idvalue: this.adminUid,
          idtype: 'adminuid',
          wxunionid: this.bindTargetUser.wxunionid
        }
        const data = await this.mjp.post2({
          url: '/admin/edit/',
          data: userObj
        })
        this.bindedUnionid = data.admin.wxunionid
      } catch (error) {
        console.error(error)
      }
    },
    async onUnBindWechatClick () {
      try {
        const userObj = {
          idvalue: this.adminUid,
          idtype: 'adminuid',
          // 使用 'NONE' 代表没有 unionid
          wxunionid: 'NONE'
        }
        await this.mjp.post2({
          url: '/admin/edit/',
          data: userObj
        })
        this.bindedUnionid = null
      } catch (error) {
        console.error(error)
      }
    },
    async getChannels () {
      const data = await this.mjp.get2({
        url: '/channel/get/more/',
        query: { per_page: 100 }
      })
      this.channels = data.channels
      this.updateChannels()
    },
    updateChannels () {
      if (this.includeAllChannels) {
        this.managedChannels = this.channels.concat()
        this.channels = []
      } else {
        this.managedChannels = []
        if (this.scheme && this.scheme.channels) {
          const newChannels = []
          this.channels.forEach((item, index) => {
            if (this.scheme.channels.includes(item.cid)) {
              this.managedChannels.push(item)
            } else {
              newChannels.push(item)
            }
          })
          this.channels = newChannels
        }
      }
    },
    // 向 managedRegionals 中加入不重复的 regionals，并返回提示重复的字符串
    addChannelsToManaged (regionals) {
      const dupR = []
      // 和 Channels 不同,regionals 总是在更新，因此这里不用替换 regionals
      this.regionals.forEach((item, index) => {
        if (regionals.includes(item.r)) {
          // 排重。因为 regionals 总是包含所有的 regional
          let notInclude = true
          for (const itemInM of this.managedRegionals) {
            if (itemInM.r === item.r) {
              notInclude = false
              dupR.push(item)
              break
            }
          }
          if (notInclude) {
            this.managedRegionals.push(item)
          }
        }
      })
      return dupR
    },
    updateRegionals () {
      if (this.includeAllRegionals) {
        this.managedRegionals = this.regionals.concat()
      } else {
        if (this.scheme && this.scheme.regionals) {
          this.addChannelsToManaged(this.scheme.regionals)
        }
      }
    },
    updateValues () {
      this.onIncludeAllChannels(this.includeAllChannels)
      this.onIncludeAllRegionals(this.includeAllRegionals)
    },
    onIncludeAllChannels (include) {
      // 切换到非所有渠道的时候，要更新渠道数据
      if (!include) {
        this.getChannels()
      }
    },
    onIncludeAllRegionals (include) {
      // 切换到非所有游戏的时候，要更新游戏数据
      if (!include) {
        // regionalSelector 可能还没有建立
        this.$nextTick(() => {
          this.$refs.regionalSelector.getRecords()
        })
      }
    },
    setValues (admin) {
      this.bindedUnionid = (admin.wxunionid === 'NONE' || (!admin.wxunionid)) ? null : admin.wxunionid
      this.bindTargetUser = null
      this.nickName = admin.nickname || ''
      this.userName = admin.username || ''
      this.wechatWorkId = admin.wxopenid
      this.status = admin.status
      this.password = ''
      this.userType = admin.usertype
      this.adminUid = admin.adminuid
      this.scheme = admin.scheme
      if (this.scheme) {
        this.includeAllRegionals = this.scheme.r_range === 'all'
        this.includeAllChannels = this.scheme.c_range === 'all'
      } else {
        this.includeAllRegionals = false
        this.includeAllChannels = false
      }
      this.updateValues()
    },
    clearValues () {
      this.bindedUnionid = null
      this.bindTargetUser = null
      this.nickName = ''
      this.userName = ''
      this.userType = null
      this.wechatWorkId = null
      this.status = 1
      this.password = ''
      this.adminUid = null
      this.scheme = null
      this.includeAllChannels = false
      this.channels = []
      this.selectedAddChannelIDs = []
      this.selectedRemoveChannelIDs = []
      this.managedChannels = []
      this.includeAllRegionals = false
      this.regionals = []
      this.selectedAddRegionalIDs = []
      this.selectedRemoveRegionalIDs = []
      this.managedRegionals = []
      if (this.$refs.regionalSelector) {
        this.$refs.regionalSelector.clear()
      }
    },
    onChannelAdd () {
      if (this.selectedAddChannelIDs.length === 0) {
        return
      }
      const newChannels = []
      this.channels.forEach((item, index) => {
        if (this.selectedAddChannelIDs.includes(item.cid)) {
          this.managedChannels.push(item)
        } else {
          newChannels.push(item)
        }
      })
      this.channels = newChannels
    },
    onChannelRemove () {
      const newManagedChannels = []
      this.managedChannels.forEach((item, index) => {
        if (this.selectedRemoveChannelIDs.includes(item.cid)) {
          this.channels.push(item)
        } else {
          newManagedChannels.push(item)
        }
      })
      this.managedChannels = newManagedChannels
    },
    onRegionalAdd () {
      if (this.selectedAddRegionalIDs.length === 0) {
        return
      }
      // 和 Channels 不同,regionals 总是在更新，因此这里不用替换 regionals
      const dupR = this.addChannelsToManaged(this.selectedAddRegionalIDs)
      if (dupR.length > 0) {
        this.hub.alert(`不允许加入重复的区服：${dupR.map(item => `(${item.r})${item.name}`).join(',')}`, 2.5, 'is-warning')
      }
    },
    onRegionalRemove () {
      // 和 channels 不同, regionals 总是在更新，因此这里不用改变 regionals
      const newManagedRegionals = []
      this.managedRegionals.forEach((item, index) => {
        if (!this.selectedRemoveRegionalIDs.includes(item.r)) {
          newManagedRegionals.push(item)
        }
      })
      this.managedRegionals = newManagedRegionals
    },
    onSaveAdmin () {
      // 返回的数据带上 idvalue 和 idtype ，让 /admin/add/ 和 /admin/edit/ 都可作用参数直接使用
      const adminObj = {
        idvalue: this.adminUid,
        idtype: 'adminuid',
        adminuid: this.adminUid,
        username: this.userName,
        nickname: this.nickName,
        password: this.password,
        usertype: this.userType,
        status: this.status
      }
      // 使用 wxopenid 这个字段来保存企业微信 id
      if (this.wechatWorkId) {
        adminObj.wxopenid = this.wechatWorkId
      }
      const scheme = {}
      if (this.includeAllChannels) {
        scheme.c_range = 'all'
      } else if (this.managedChannels.length > 0) {
        scheme.channels = this.managedChannels.map(item => item.cid)
        scheme.c_range = 'cid'
      }
      if (this.includeAllRegionals) {
        scheme.r_range = 'all'
      } else if (this.managedRegionals.length > 0) {
        scheme.regionals = this.managedRegionals.map(item => item.r)
        scheme.r_range = 'r'
      }
      adminObj.scheme = scheme
      this.$emit('admin-edit', adminObj)
    }
  }
}
</script>
