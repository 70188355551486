/**
 * 2018-06-02 zrong
 * 对 axios 进行简单的封装
 */
import axios from 'axios'
import qs from 'qs'
import config from './config'
import cache from './cache'

const removeNull = function (data) {
  for (const k in data) {
    if (Object.prototype.hasOwnProperty.call(data, k) && data[k] === null) {
      delete data[k]
    }
  }
  return data
}

/**
 * 根据提供的 API 方法来判断 API 的类型，以导向不同的 MJP
 * 注意 /cf/type 的优先级高于 /cf
 * /cf 在  admin 和 auth 中都存在。 getMJPURI 中有进一步的判断
 * pg = Players and Analysis
 */
const URL2API = {
  admin: ['/admin/gen', '/admin/history', '/admin', '/channel', '/cf/type', '/cf/regional', '/cf/crons', '/mana', '/cf', '/util', '/rsi', '/audible'],
  auth: ['/auth/gen', '/cf/server', '/cf/releasenote', '/cf', '/cache/'],
  pa: ['/user/get/more', '/usr/get/history/more', '/analy', '/user/del'],
  mp: ['/wxoa', '/wxmini'],
  rank: ['/store', '/rlist'],
  cache: ['/user/clear'],
  prize: ['/redeem']
}

class API {
  url = null

  static install () { }

  constructor () {
    this._axios = axios.create({
      timeout: 10000
    })
  }

  static getInstance () {
    if (!this._instance) {
      this._instance = new API()
      this._instance.setInterceptors()
    }
    return this._instance
  }

  /**
   * 使用 GET 方法调用 API
   * @param {string} url 调用地址
   * @param {*} query url 查询字符串对象
   * @param {string} responseType 返回类型
   * @param {string} mjpType mjp类型，详见 _getMJPURI
   */
  get (url, query, responseType, mjpType = 'guess') {
    return this._request({ method: 'get', url, data: null, query, responseType, mjpType })
  }

  /**
   * 作用同 get， 采用解构传递参数，支持设置 timeout
   */
  get2 ({ url, query, responseType, timeout, mjpType = 'guess' }) {
    return this._request({ method: 'get', url, data: null, query, responseType, mjpType, timeout })
  }

  post (url, data, query, responseType, mjpType = 'guess') {
    return this._request({ method: 'post', url, data, query, responseType, mjpType })
  }

  /**
   * 作用同 post， 采用解构传递参数，支持设置 timeout
   */
  post2 ({ url, data, query, responseType, timeout, mjpType = 'guess' }) {
    return this._request({ method: 'post', url, data, query, responseType, mjpType, timeout })
  }

  upload ({ url, data, responseType, timeout, mjpType = 'guess' }) {
    return this._request({
      method: 'post',
      url,
      data,
      responseType,
      mjpType,
      timeout,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  /**
   * 下载一个文件，使用 blob responeType 和 get method
   */
  download ({ url, query, timeout, fileName, mjpType = 'guess' }) {
    return this._request({
      method: 'get',
      url,
      query,
      responseType: 'blob',
      mjpType,
      timeout
    }).then(data => {
      // const blob = new Blob([data], { type: data.type })
      const url = window.URL.createObjectURL(data.blob)
      const link = document.createElement('a')
      link.href = url
      // 若没有提供 fileName，就使用 header 中的 filename
      if (!fileName) {
        // 在 header 中寻找 filename ，需要服务器端传递这个 header
        const contentDisposition = data.headers['content-disposition']
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename=(.+)$/)
          if (fileNameMatch && fileNameMatch.length === 2) {
            fileName = fileNameMatch[1]
          }
        }
        if (!fileName) {
          fileName = 'download.file'
        }
      }
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)
    })
  }

  setInterceptors () {
    this._axios.interceptors.response.use(this._responseResolve, this._responseReject)
  }

  /**
   * 序列化 params
   */
  _paramsSerializer (params) {
    return qs.stringify(params)
  }

  _responseResolve (resp) {
    let data = resp.data
    // 对于 Blob 类型的返回值，检测是否为 json 对象。
    if (data instanceof Blob) {
      console.log(`API response got a Blob. type: ${data.type}, size: ${data.size}, wrap to a object.`)
      // console.log('resp %o', resp)
      return { blob: data, type: data.type, size: data.size, headers: resp.headers, config: resp.config, request: resp.request }
    }
    // console.log('_responseResolve %s %o', typeof data, data)
    let errorMSG = null
    let noAuth = false
    let code = 200
    // 一般情况下 data 不会是一个字符串。除非返回的 JSON 解析报错。此时 axios 会将JSON作为字符串返回。此时需要再
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data)
      } catch (error) {
        errorMSG = 'ERROR 500 The Response data must be JSON!'
        console.error(`Response Data Parse JSON ERROR: ${data}`)
        data = null
      }
    }
    if (data !== null) {
      code = data.code
      // 检查 code 必须是 200
      if (code === 200) {
        console.log('API response: %o', data)
        return data
      }
      const message = typeof data.message === 'object' ? data.message.message : data.message
      errorMSG = `ERROR ${code}: ${message}`
      noAuth = code === 403
      if (noAuth) {
        errorMSG += '\n\n没有访问权限！'
      }
    }
    const errobj = new Error(errorMSG)
    errobj.noAuth = noAuth
    errobj.code = code
    return Promise.reject(errobj)
  }

  _responseReject (error) {
    const errobj = new Error(JSON.stringify(error))
    console.error('responsRecject: %o', error)
    if (error.response) {
      const status = error.response.status
      errobj.noAuth = status === 403
      errobj.code = status
    } else if (error.request) {
      console.error(error.request)
      errobj.message = `出现错误： ${error.message} ，请稍后重试或联系管理员。`
    }
    return Promise.reject(errobj)
  }

  /**
   * 根据 URI 猜测其调用的 API 地址
   * @param {string} url 提供调用的API URL
   * @param {string} mjpType mjp 的类型，可用值：
   *                          guess 根据路径推测 MJP，见 URL2API
   *                          admin 导向到 /mjpadm 唯一的中心化管理员权限
   *                          auth 导向到 /mjpauth 仅负责帐号登录和鉴权，一般每个不同的游戏客户端（独立数据库）部署一个
   *                          pa 导向到 /mjppa 负责游戏帐号和数据统计，一般每个不同的游戏客户端（独立数据库）部署一个
   *                          mp 导向到 /mjpmp 专用于公众号
   *                          gs 导向到 /mjpgs 专用于与 GS 通信
   * @param {Number} r regional 的值
   */
  getMJPURI (url, mjpType, r) {
    console.log(`getMJPURI url: ${url}  mjpType: ${mjpType} r: ${r}`)
    if (url.startsWith('http')) {
      return url
    }
    if (mjpType === 'guess') {
      const user = cache.getUser()
      for (const [k, v] of Object.entries(URL2API)) {
        for (const prefix of v) {
          if (url.startsWith(prefix)) {
            /* /cf API 需要单独强制处理。因为每个 mjp 都有自己独立的 /cf
            对于超管，强制其使用 mjpadm 的 /cf API
            非超管就默认使用  mjpauth 的 /cf API
            更多专用的 API，则必须指定 mjpType
            */
            if (prefix === '/cf') {
              if (user.usertype === 50) {
                mjpType = 'admin'
              } else {
                mjpType = 'auth'
              }
            } else {
              mjpType = k
            }
            break
          }
        }
      }
      // 没有根据 URL 找到对应的 mjpType
      if (mjpType === 'guess') mjpType = null
    }
    let apiurl = null
    const adminMJP = config.getAdminMJP(cache.getAdminMJPIndex())
    if (mjpType === 'admin') {
      apiurl = adminMJP.admapi
    // } else if (mjpType === 'mp') {
    //   apiurl = adminMJP.mpapi
    } else if (mjpType !== null) {
      // 注意，这里必须保证 regionals 缓存是存在且有效的
      apiurl = cache.getMJPURI(mjpType)
    }
    if (apiurl === null) {
      throw new RangeError(`无法从 url:${url}, mjpType:${mjpType}, r:${r} 获取到 MJP，请提供合法的 URI 或指定 mjpType。`)
    }
    return apiurl
  }

  _getRequestObj (params) {
    const data = params.data ? removeNull(params.data) : {}
    const query = params.query ? removeNull(params.query) : {}

    /**
     * URI 参数处理
     * 此处的逻辑是：
     * 如果没有显示在参数中提供 r，则从缓存中查找 r 加入到 query 参数
     * 如果参数中提供了 r，优先使用参数中传来的 r
     * 如果 data 和 query 中都提供了 r，query 优先级高于 data
     * r 可能是 0，因此使用 Number.isInteger 来判断
     * 如果
     */
    const r = Number.parseInt(data.r || cache.getR())
    if (Number.isInteger(r) && query.r === undefined) {
      query.r = r
    }

    const requestObj = {
      method: params.method,
      url: params.url,
      baseURL: this.getMJPURI(params.url, params.mjpType, query.r),
      paramsSerializer: this._paramsSerializer
    }

    // 处理url参数
    requestObj.params = query

    // 处理mjst
    let accesstoken = qs.parse(window.location.search).accesstoken
    if (!accesstoken) {
      try {
        accesstoken = cache.getAccessToken()
      } catch (error) {
        accesstoken = null
        cache.setValue('errorMessage', error.message)
        this.router.replace('/error')
      }
    }
    const mjst = accesstoken
    const headers = params.headers || {}
    if (mjst) {
      headers.MJST = mjst
    }
    if (params.timeout) {
      requestObj.timeout = params.timeout
    }
    if (params.responseType) {
      requestObj.responseType = params.responseType
    }
    if (params.method !== 'get') {
      requestObj.data = data
    }
    requestObj.headers = headers
    return requestObj
  }

  _request (params) {
    const requestObj = this._getRequestObj(params)
    const finalUrl = requestObj.url.startsWith('http') ? requestObj.url : requestObj.baseURL + requestObj.url
    console.log(`API ${requestObj.method}:  ${finalUrl}`)
    console.log('API requestObj:  %o', requestObj)
    // 返回 resolve ，但统一处理 reject
    return new Promise((resolve, reject) => {
      this._axios.request(requestObj).then(data => {
        resolve(data)
      }).catch(error => {
        this.hub.showProgress(false)
        // 对于 403 统一处理
        if (error.noAuth) {
          cache.setValue('errorMessage', error.message)
          this.router.replace('/error')
        } else {
          // 其它的错误一概弹出 alert
          this.hub.alert(error.message, 2, 'is-danger')
        }
        reject(error)
      })
    })
  }
}

const instance = API.getInstance()

API.install = function (Vue, options) {
  console.log('INSTALLl API router:%s, hub:%s', options.router, options.eventHub)
  instance.router = options.router
  instance.hub = options.eventHub
  Object.defineProperty(Vue.prototype, 'mjp', {
    get () { return instance }
  })
}

export { API, instance as default }
