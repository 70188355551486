/**
 * 工具类
 */
import Vue from 'vue'
import qs from 'qs'
import { DateTime } from 'luxon'
import toml from '@iarna/toml'
import config from './config'

function formatString (s) {
  let i = arguments.length
  while (i--) {
    s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), arguments[i])
  }
  return s
}

function findObjectInArray (key, value) {
  for (const item of this) {
    if (typeof item === 'object' && item[key] === value) {
      return item
    }
  }
  return {}
}

function formatRMB (num) {
  return Number.parseInt(num) === 0 ? 0 : ((num % 100) ? (num / 100).toFixed(2) : (num / 100))
}

function setCookie (name, value, del) {
  const days = del ? 0 : 1
  const exp = new Date()
  exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = name + '=' + decodeURI(value) + ';domain=' + window.location.hostname + ';path=' + config.PATH + ';expires=' + exp.toUTCString()
  return value
}

function getCookie (name) {
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  const arr = document.cookie.match(reg)
  // console.log('------arr:', arr)
  if (arr) {
    return decodeURI(arr[2])
  } else {
    return null
  }
}

function clearCookie () {
  const keys = document.cookie.match(/[^ =;]+(?==)/g)
  if (keys) {
    for (let i = keys.length; i--;) {
      document.cookie = keys[i] + '=0;domain=' + window.location.hostname + ';path=' + config.PATH + ';expires=' + new Date(0).toUTCString()
    }
  }
}

function getQuery (name) {
  const qsobj = qs.parse(window.location.search)
  return qsobj[name]
}

function appendQuery (obj) {
  let queryString = ''
  for (const k in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, k) && obj[k]) {
      queryString += '&' + k + '=' + decodeURIComponent(obj[k])
    }
  }
  return queryString.substr(1)
}

/**
 * 基于当前时间返回一个新的 Date ，使用 DateTime.plus 方法
 * @param {object} props {days: 1, months: 1}
 * @param {Date} minDate 提供一个最小日期，如果计算出的日期在最小日期之前，则返回最小日期
 */
function fromNow (props, minDate) {
  const dt = DateTime.local().plus(props)
  if (minDate) {
    const mindt = DateTime.fromJSDate(minDate)
    if (dt.diff(mindt) < 0) {
      return mindt.toJSDate()
    }
  }
  return dt.toJSDate()
}

/**
 * 基于提供的JS时间，返回一个新的 Date ，使用 DateTime.plus 方法
 * @param {Date} date 一个 JS Date
 * @param {object} props {days: 1, months: 1}
 */
function plusDate (date, props) {
  return DateTime.fromJSDate(date).plus(props).toJSDate()
}

/**
 * 对一个包含日期字符串的数组进行排序
 * @param {Array} dateList 包含字符串日期对象的数组
 * @param {boolean} asc 排序方式
 */
function sortDate (dateList, asc) {
  const tm = d => DateTime.fromFormat(d, 'yyyy-MM-dd').toMillis()
  let f = null
  if (asc) {
    f = (a, b) => tm(a) - tm(b)
  } else {
    f = (a, b) => tm(b) - tm(a)
  }
  return dateList.sort(f)
}

function getLastMonth () {
  const now = new Date()
  let year = now.getFullYear()
  let month = now.getMonth()
  if (month === 0) {
    month = 11
    year--
  } else {
    month--
  }
  return new Date(year, month, 1)
}

function fromDateFormat (dateTime) {
  // console.log(DateTime.fromFormat(dateTime, 'yyyy-MM-dd').toJSDate().getTime())
  return DateTime.fromFormat(dateTime, 'yyyy-MM-dd').toJSDate()
}

function fromDateTimeFormat (dateTime) {
  const fmt = 'yyyy-MM-dd HH:mm:ss'
  return DateTime.fromFormat(dateTime, fmt).toJSDate()
}

function fromDateTime (fmt, dateTime) {
  if (dateTime === undefined) {
    return DateTime.local().toFormat(fmt)
  } else if (typeof dateTime === 'number') {
    return DateTime.fromMillis(dateTime).toFormat(fmt)
  } else if (dateTime instanceof Date) {
    return DateTime.fromJSDate(dateTime).toFormat(fmt)
  }
  return DateTime.fromISO(dateTime).toFormat(fmt)
}

function formatDateTime (dateTime) {
  const fmt = 'yyyy-MM-dd HH:mm:ss'
  return fromDateTime(fmt, dateTime)
}

function formatDate (dateTime) {
  const fmt = 'yyyy-MM-dd'
  return fromDateTime(fmt, dateTime)
}

const eventHub = new Vue({
  methods: {
    logout () {
      this.$emit('logout')
    },
    alert (message, duration, type = 'is-danger', position = 'is-top') {
      // this.$emit('show-alert', {message, countdown, type})
      const toastObj = {
        message: message,
        position: position,
        type: type
      }
      if (duration) {
        toastObj.duration = duration * 1000
      } else {
        toastObj.duration = 2000
      }
      console.log('toastObj %o', toastObj)
      this.$buefy.toast.open(toastObj)
    },
    showProgress (showOrMessage) {
      let evt = { show: true }
      if (showOrMessage !== undefined) {
        if (typeof showOrMessage === 'boolean') {
          evt.show = showOrMessage
        } else if (typeof showOrMessage === 'string') {
          evt.message = showOrMessage
        } else {
          evt = showOrMessage
        }
      }
      this.$emit('show-progress', evt)
    },
    showConfirm (message, cb, options) {
      /**
       * message 信息
       * cb 回调函数
       * options {type, onlyOK, isTextArea}
       */
      if (!options) {
        options = {}
      }
      options.message = message
      options.cb = cb
      if (message) {
        this.$emit('show-confirm', options)
      }
    },
    /**
     * 检查提供的 filter 的值是否有效，若无效则使用 alert 弹出提示
     * @param {array} filters 每个参数是个两元素的数组，第一项代表要检查的值，第二项代表该值名称
     */
    checkFilters (...filters) {
      const needSelect = []
      filters.forEach(([value, name], index, array) => {
        if (value === null || value === undefined || value.length === 0) {
          needSelect.push(name)
        }
      })
      if (needSelect.length > 0) {
        this.alert(`请选择 ${needSelect.join(',')}`, 4, 'is-warning')
        return false
      }
      return true
    }
  }
})

/**
 * 提供一个数组长度，根据数组长度来寻找下一个颜色
 * @param {number} length 要改变颜色的数组长度
 */
function nextChartColor (length) {
  const colorNames = Object.keys(config.CHART_COLORS)
  const colorName = colorNames[length % colorNames.length]
  return config.CHART_COLORS[colorName]
}

function mjpType2Color (mjpType) {
  const mt = Number.parseInt(mjpType)
  if (mt === 1000) {
    return 'is-danger'
  }
  if (mt === 2000) {
    return 'is-primary'
  }
  if (mt === 5000) {
    return 'is-dark'
  }
  return 'is-info'
}

function mjpType2Name (mjpType) {
  const mt = Number.parseInt(mjpType)
  if (mt === 1000) {
    return '测试服'
  }
  if (mt === 2000) {
    return '审核服'
  }
  return '正式服'
}

function beautifyTJString ({ value, valueType }) {
  valueType = valueType || 'json'
  if (valueType === 'toml') {
    try {
      toml.parse(value)
      return value
    } catch (e) {
      // 如果无法被解析为 toml 字符串，一般是因为 value 是 json 字符串
      // 将 json 字符串转换为 toml 字符串
      let jsonobj = JSON.parse(value)
      // toml 不支持 list，因此要加一个 ROOTLIST
      if (Array.isArray(jsonobj)) {
        jsonobj = { ROOTLIST: jsonobj }
      }
      return toml.stringify(jsonobj)
    }
  }
  return JSON.stringify(JSON.parse(value), null, 4)
}

function checkTJValue ({ value, valueType }) {
  valueType = valueType || 'json'
  try {
    if (valueType === 'toml') {
      toml.parse(value)
    } else {
      JSON.parse(value)
    }
  } catch (error) {
    return false
  }
  return true
}

function utilInstall (Vue, options) {
  console.warn('INSTALL UTIL')
  Vue.prototype.hub = eventHub
  Vue.prototype.formatDate = formatDate
  Vue.prototype.formatDateTime = formatDateTime
  Vue.prototype.fromDateFormat = fromDateFormat
  Vue.prototype.fromDateTimeFormat = fromDateTimeFormat
}

export {
  utilInstall, eventHub,
  setCookie, getCookie, clearCookie,
  formatRMB, formatString, findObjectInArray,
  nextChartColor, mjpType2Color, mjpType2Name,
  getQuery, appendQuery, fromNow, plusDate, sortDate, getLastMonth,
  beautifyTJString, checkTJValue, fromDateTime
}
