<template>
  <div>
    <b-field class="markdown-body">
      <help-rsi-server />
    </b-field>
    <b-tabs
      @change="onTabChange"
      v-model="tabIndex"
      position="is-centered"
      type="is-boxed"
      size="is-medium" >
      <b-tab-item label="服务器列表">
        <b-field grouped class="box" position="is-centered">
          <b-select placeholder="服务器类型" icon="server" v-model="serverType">
            <option value="all">全部</option>
            <option value="cvm">cvm</option>
            <option value="mysql">mysql</option>
            <option value="redis">redis</option>
          </b-select>
        </b-field>
        <b-table :data="showSever" ref="table" detailed detail-key="sid">
          <b-table-column #default="props" field="sid" label="sid" width="60" numeric centered>{{ props.row.sid}}</b-table-column>
          <b-table-column #default="props" field="name" label="名称" width="200">{{ props.row.name}}</b-table-column>
          <b-table-column #default="props" field="stype" label="服务器类型" centered>{{ props.row.stype }}</b-table-column>
          <b-table-column #default="props" field="ipnetwork" label="内网IP" centered>{{ props.row.ipnetwork }}</b-table-column>
          <b-table-column
            #default="props"
            field="ippublic"
            label="外网IP"
            centered
          >{{ props.row.ippublic?props.row.ippublic:'-' }}</b-table-column>
          <b-table-column
            #default="props"
            field="port"
            label="端口"
            centered
          >{{ props.row.port?props.row.port:'-' }}</b-table-column>
          <b-table-column
            #default="props"
            field="server"
            label="域名"
            centered
          >{{ props.row.domain?props.row.domain:'-' }}</b-table-column>
          <b-table-column #default="props" field="status" label="状态" width="60" centered>
            <b-tag :type="props.row.status===1?'is-success':'is-light'">{{ props.row.status===1?'已启用':'已禁用'}}</b-tag>
          </b-table-column>
          <b-table-column #default="props" label="操作">
            <div class="buttons">
              <button class="button is-warning" @click="onEditClick(props.row)">修改</button>
              <button class="button is-danger" @click="ondeleteClick(props.row.sid)">删除</button>
              <button class="button is-info" @click="toRelation(props.row.sid)">Relation</button>
            </div>
          </b-table-column>
          <template #detail="props">
            <pre>{{props.row.value?props.row.value:'-'}}</pre>
          </template>
        </b-table>
      </b-tab-item>
      <b-tab-item label="增加">
        <section>
          <b-field horizontal label="服务器类型">
            <b-select placeholder="服务器类型" icon="server" v-model="saveData.stype">
              <option value="cvm">cvm</option>
              <option value="mysql">mysql</option>
              <option value="redis">redis</option>
            </b-select>
          </b-field>
          <b-field horizontal label="名称" width="100">
            <b-input v-model="saveData.name" placeholder="必填"></b-input>
          </b-field>
          <b-field horizontal label="内网IP">
            <b-input v-model="saveData.ipnetwork" placeholder="必填"></b-input>
          </b-field>
          <b-field horizontal label="外网IP">
            <b-input v-model="saveData.ippublic" placeholder="选填"></b-input>
          </b-field>
          <b-field horizontal label="端口">
            <b-input v-model="saveData.port" placeholder="选填"></b-input>
          </b-field>
          <b-field horizontal label="域名">
            <b-input v-model="saveData.domain" placeholder="选填"></b-input>
          </b-field>
          <b-field horizontal label="配置">
            <b-input v-model="saveData.value" rows="10" type="textarea" placeholder="选填-TOML格式"></b-input>
          </b-field>
          <b-field horizontal label="状态" style="text-align:left">
            <b-switch
              v-model.number="saveData.status"
              true-value="1"
              false-value="5"
            >{{saveData.status == 1?'启用':'禁用'}}</b-switch>
          </b-field>
          <b-field grouped position="is-centered">
            <button class="button is-danger" :disabled="!canSave" size="is-medium" @click="saveServer()">保存</button>
          </b-field>
        </section>
      </b-tab-item>
      <b-tab-item label="编辑" :disabled="!inEdit">
        <section>
          <b-field horizontal label="sid" style="text-align:left">{{saveData.sid}}</b-field>
          <b-field horizontal label="服务器类型">
            <b-select placeholder="服务器类型" icon="server" v-model="saveData.stype">
              <option value="cvm">cvm</option>
              <option value="mysql">mysql</option>
              <option value="redis">redis</option>
            </b-select>
          </b-field>
          <b-field horizontal label="名称" width="100">
            <b-input v-model="saveData.name" placeholder="必填"></b-input>
          </b-field>
          <b-field horizontal label="内网IP">
            <b-input v-model="saveData.ipnetwork" placeholder="必填"></b-input>
          </b-field>
          <b-field horizontal label="外网IP">
            <b-input v-model="saveData.ippublic" placeholder="选填"></b-input>
          </b-field>
          <b-field horizontal label="端口">
            <b-input v-model="saveData.port" placeholder="选填"></b-input>
          </b-field>
          <b-field horizontal label="域名">
            <b-input v-model="saveData.domain" placeholder="选填"></b-input>
          </b-field>
          <b-field horizontal label="配置">
            <b-input v-model="saveData.value" rows="10" type="textarea" placeholder="选填-TOML格式"></b-input>
          </b-field>
          <b-field horizontal label="状态" style="text-align:left">
            <b-switch
              v-model.number="saveData.status"
              true-value="1"
              false-value="5"
            >{{saveData.status == 1?'启用':'禁用'}}</b-switch>
          </b-field>
          <b-field grouped position="is-centered">
            <button center class="button is-danger" :disabled="!canSave" size="is-medium" @click="saveEdit()" >保存</button>
          </b-field>
        </section>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import toml from '@iarna/toml'
import HelpRsiServer from '@/help/rsiserver.md'

export default {
  name: 'rsiserverlist',
  components: { HelpRsiServer },
  data () {
    return {
      serverData: [],
      tabIndex: 0,
      inEdit: false,
      saveData: {
        status: 1
      },
      serverType: 'all'
    }
  },
  created () {
    this.init()
  },
  watch: {
    tabIndex (to) {
      if (to === 1) {
        this.saveData = {
          status: 1
        }
      }
    }
  },
  computed: {
    showSever () {
      if (this.serverData.length === 0) {
        return []
      }
      return this.serverData.filter(robj => {
        if (this.serverType === 'cvm') {
          return robj.stype === 'cvm'
        } else if (this.serverType === 'mysql') {
          return robj.stype === 'mysql'
        } else if (this.serverType === 'redis') {
          return robj.stype === 'redis'
        } else {
          return robj
        }
      })
    },
    canSave () {
      return (
        this.saveData.stype && this.saveData.name && this.saveData.ipnetwork
      )
    }
  },
  methods: {
    init () {
      this.getData()
    },
    testToml (t) {
      try {
        toml.parse(t)
        return true
      } catch (e) {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'TOML格式错误',
          position: 'is-bottom',
          type: 'is-danger'
        })
        console.log(e)
        return false
      }
    },
    async getData () {
      try {
        const results = await this.mjp.get('/rsi/server/get/all/')
        this.serverData = results.servers
      } catch (e) {
        console.log(e)
      }
    },
    async onEditSave (url, r) {
      try {
        await this.mjp.post(url, r)
        this.$buefy.toast.open({
          message: '操作成功！',
          type: 'is-success'
        })
        this.freshData()
      } catch (e) {
        console.log(e)
      }
    },
    saveServer () {
      if (this.saveData.value) {
        if (this.testToml(this.saveData.value)) {
          this.onEditSave('/rsi/server/add/', this.saveData)
        }
      } else {
        this.onEditSave('/rsi/server/add/', this.saveData)
      }
    },
    ondeleteClick (sid) {
      try {
        this.$buefy.dialog.confirm({
          message: '确认删除？',
          type: 'is-danger',
          onConfirm: async () => {
            await this.mjp.post('/rsi/server/del/', { sid: sid })
            this.freshData()
            this.$buefy.toast.open({
              message: '删除成功',
              type: 'is-success'
            })
          }
        })
      } catch (e) {
        console.log(e)
        this.$buefy.toast.open({
          message: '删除失败',
          type: 'is-danger'
        })
      }
    },
    onEditClick (data) {
      this.saveData = data
      this.tabIndex = 2
      this.inEdit = true
    },
    saveEdit () {
      if (this.saveData.value) {
        if (this.testToml(this.saveData.value)) {
          this.onEditSave('/rsi/server/edit/', this.saveData)
        }
      } else {
        this.onEditSave('/rsi/server/edit/', this.saveData)
      }
    },
    onTabChange (to) {
      if (to !== 2) {
        this.inEdit = false
      }
    },
    freshData () {
      this.init()
      this.tabIndex = 0
    },
    toRelation (sid) {
      this.$router.push({
        path: '/relation',
        query: {
          sid: sid
        }
      })
    }
  }
}
</script>
