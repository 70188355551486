<template>
<value-object-editor-j-t ref="voe"
  :can-save-extra="canSave"
  :is-edit="isEdit"
  @save="onSave">
  <template slot="value">
    <b-field horizontal label="Title">
      <b-input name="Title" v-model.trim="Title"></b-input>
    </b-field>
    <b-field horizontal label="Description">
      <b-input name="Description" v-model.trim="Description"></b-input>
    </b-field>
    <b-field horizontal label="PicUrl">
      <b-input name="PicUrl" v-model.trim="PicUrl"></b-input>
    </b-field>
    <b-field horizontal label="Url">
      <b-input name="Url" v-model.trim="Url"></b-input>
    </b-field>
  </template>
</value-object-editor-j-t>
</template>

<script>
import ValueObjectEditorJT from '@/components/vo/ValueObjectEditorJT'

export default {
  name: 'materconfigeditor',
  components: { ValueObjectEditorJT },
  computed: {
    canSave () {
      return this.Title &&
        this.Description &&
        this.PicUrl &&
        this.Url
    }
  },
  // 编辑管理员则值为 true， 增加管理员则值为 false
  props: ['isEdit'],
  data () {
    return {
      Title: null,
      Description: null,
      PicUrl: null,
      Url: null
    }
  },
  methods: {
    setValues (vo) {
      const value = JSON.parse(vo.value)
      this.Title = value.Title
      this.Description = value.Description
      this.PicUrl = value.PicUrl
      this.Url = value.Url

      this.$refs.voe.setValues(vo)
    },
    clearValues () {
      this.Title = null
      this.Description = null
      this.PicUrl = null
      this.Url = null

      this.$refs.voe.clearValues()
    },
    onSave () {
      const vo = this.$refs.voe.getValues()
      vo.value = JSON.stringify({
        Title: this.Title,
        Description: this.Description,
        PicUrl: this.PicUrl,
        Url: this.Url
      }, null, 0)
      this.$emit('edit', vo)
    }
  }
}
</script>
