import cache from './cache'
import qs from 'qs'
import { clearCookie } from './util'

// 公众号
const mpAppId = 'wxdbe777fc9396aa9a'
// 网站应用
const wechatWebAppId = 'wxb72add1a3e58fd3b'
// 企业应用
const workAgentId = '1000006'
// 企业 ID
const workCropId = 'ww91baef4e02378e5d'

// 目前只有微信有扫码登录功能
const prodCallback = { wx: 'https://work2.sagiteam.cn/mjpadm/admin/login/callback/' }
const testCallback = { wx: 'https://work2.sagiteam.cn/mjpadmtest/admin/login/callback/' }

function openInWechat () {
  const hasJSBridge = window.WeixinJSBridge !== undefined
  const hasWorkAgent = /wxwork/i.test(window.navigator.userAgent)
  const hasWXAgent = /MicroMessenger/i.test(window.navigator.userAgent)

  const testType = qs.parse(window.location.search, { ignoreQueryPrefix: true }).test
  console.log('WeixinJSBridge %o, hasWXAgent: %s, hasWorkAgent: %s, testType: %s', window.WeixinJSBridge, hasWXAgent, hasWorkAgent, testType)

  if (hasWorkAgent) {
    return 'wechatwork'
  }
  if (hasJSBridge || testType === 'mp' || hasWXAgent) {
    return 'wechat'
  }
  return null
}

/**
 * 获取公众号或者WebApp的跳转URI
 * @param {Boolean} isProd 是否正式服
 * @param {Boolean} isWork 是否企业微信，否则就是普通微信
 * @param {Boolean} isQR 是否扫码登录，否则就是通过网页跳转授权
 */
function getAuthURI ({ isProd, isWork, isQR }) {
  const redirectURI = redirectAuthURI(isProd)
  // console.warn('getAuthURI isProd:%s', isProd)
  // console.warn('redirectURI %s', redirectURI)
  let state = ''
  const appid = getAppId({ isWork, isQR })
  if (isWork) {
    state = genState({ login: 'workappmgr' })
    // 企业微信扫码登录
    if (isQR) {
      return `https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=${appid}&agentid=${workAgentId}&redirect_uri=${redirectURI}&state=${state}`
    }
    // 企业微信公众号内部登录
    return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectURI}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`
  }
  // 普通微信扫码登录
  if (isQR) {
    state = genState({ login: 'webappmgr' })
    return `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${redirectURI}&response_type=code&scope=snsapi_login&state=${state}#wechat_redirect`
  }
  // 普通微信公众号内部登录
  state = genState({ login: 'mp' })
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectURI}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`
}

/**
 * 获取 AppID
 * @param {Boolean} isWork 是否企业微信，否则就是普通微信
 * @param {Boolean} isQR 是否扫码登录，否则就是通过网页跳转授权
 */
function getAppId ({ isWork, isQR }) {
  // 企业微信无论使用何种登录方式，都是同一个 CropId
  if (isWork) {
    return workCropId
  }
  // 普通微信的扫码登录，使用 WebAppId
  if (isQR) {
    return wechatWebAppId
  }
  // 普通微信的跳转登录，使用公众号的 AppId
  return mpAppId
}

function redirectAuthURI (isProd, ch) {
  ch = ch || 'wx'
  if (isProd) {
    return encodeURIComponent(prodCallback[ch])
  }
  return encodeURIComponent(testCallback[ch])
}

/**
 * 生成一个传递给微信/企业微信登录的 state 值
 * @param {string} login 登录类型，可选，默认值为 mp
 *                 mp 普通微信微信浏览器内部登录
 *                 webapp 普通微信扫码登录
 *                 workapp 企业微信扫码登录或者在企业微信浏览器内部登录
 * @param {string} client 客户端类型，可选值 manager管理后台/event活动后台
 */
function genState ({ login, client }) {
  login = login || 'mp'
  client = client || 'manager'
  // 如果使用 & 会被微信解码，导致无法传递成功多个值。改为使用 | 作为分隔符
  return qs.stringify({ login, client }, { delimiter: '|' })
}

/**
 * 检查是否有 redirect 过来的微信登录凭据
 */
function checkWechatAuth () {
  const wxloginObj = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  console.log('auth.checkWechatAuth:%o', wxloginObj)
  // 有错误
  if (wxloginObj.error) {
    return wxloginObj
  }
  wxloginObj.uid = Number.parseInt(wxloginObj.uid)
  wxloginObj.expire = Number.parseInt(wxloginObj.expire)
  // 没有错误则进行校验
  if (wxloginObj.accesstoken &&
    wxloginObj.uid &&
    wxloginObj.expire &&
    wxloginObj.expire > (new Date().getTime() / 1000)) {
    return wxloginObj
  }
  // 视为没有微信登录
  return null
}

/**
 * 检查是否有 redirect 过来的 OIDC 登录数据
 */
function checkOidcAuth ({ loginObj }) {
  const oidcLoginObj = loginObj || qs.parse(window.location.search, { ignoreQueryPrefix: true })
  console.log('auth.checkOidcAuth:%o', oidcLoginObj)
  // 有错误
  if (oidcLoginObj.error) {
    return oidcLoginObj
  }
  oidcLoginObj.uid = Number.parseInt(oidcLoginObj.uid)
  oidcLoginObj.expire = Number.parseInt(oidcLoginObj.expire)
  // 没有错误则进行校验
  if (oidcLoginObj.accesstoken &&
    oidcLoginObj.uid &&
    oidcLoginObj.expire &&
    oidcLoginObj.expire > (new Date().getTime() / 1000)) {
    return oidcLoginObj
  }
  // 视为没有 Oidc 登录
  return null
}

function logout () {
  cache.clear('all')
  clearCookie()
}

export default {
  redirectAuthURI,
  checkWechatAuth,
  checkOidcAuth,
  logout,
  getAppId,
  openInWechat,
  getAuthURI
}
