<template>
  <div id="voeditorjt">
    <b-field horizontal label="英文名">
      <b-field>
        <div class="control ">
          <b-tag class="control">{{ preTag }}</b-tag>
        </div>
        <b-input name="name" v-model.trim="name" placeholder="只允许英文+数字，大小写区分" expanded />
      </b-field>
    </b-field>
    <b-field horizontal label="数据库键名">
      {{ trueName }}
    </b-field>
    <b-field horizontal label="中文名">
      <b-input v-model.trim="note" placeholder="中文，可留空" />
    </b-field>
    <slot name="value">
      <b-field horizontal label="VALUE">
        <div>
          <span class="has-text-danger">以 # 开头的行是注释。变量的详细说明可用注释方式写在 VALUE 中</span>
          <textarea class="textarea" v-model.trim="value" :placeholder="valueHolder" rows="15" />
        </div>
      </b-field>
      <toml-help v-if="valueType === 'toml'"/>
    </slot>
    <b-field grouped class="buttons" position="is-centered">
      <button class="button is-warning" v-if="showBeautify && valueType === 'json'" :disabled="!canSave" @click="onBeautify">Beautify</button>
      <button class="button is-danger" :disabled="!canSave" @click="$emit('edit', getValues())">保存</button>
    </b-field>
  </div>
</template>

<script>
/**
 * 同时支持 JSON 和 TOML 格式的配置编辑器
 * 一般用于 306 和 307，也可以用于对名称有要求的其他类型
 */
import cache from '@/core/cache'
import TomlHelp from '@/components/vo/TomlHelp'
import { beautifyTJString, checkTJValue } from '@/core/util'

const nameRE = /^[A-Za-z0-9]+$/

export default {
  name: 'voeditorjt',
  components: { TomlHelp },
  props: {
    canSaveExtra: {
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    showBeautify: {
      type: Boolean,
      default: true
    },
    valueType: {
      type: String,
      default: 'json'
    }
  },
  computed: {
    preTag () {
      return `r${this.regional}_`
    },
    trueName () {
      return `r${this.regional}_${this.name || ''}`
    },
    valueHolder () {
      return `输入 ${this.valueType.toUpperCase()} 内容`
    },
    canSave () {
      let valueIsValid = false
      if (this.value) {
        valueIsValid = checkTJValue({ value: this.value, valueType: this.valueType })
      }
      console.log('test this.name %s, %s', this.name, nameRE.test(this.name))
      const s = this.canSaveExtra && valueIsValid && this.name && nameRE.test(this.name)
      // 编辑状态下需要检验 VID
      if (this.isEdit) return s && this.vid
      return s
    }
  },
  data: function () {
    return {
      regional: cache.getR(),
      vid: null,
      name: null,
      value: null,
      note: null,
      votype: null
    }
  },
  methods: {
    onBeautify () {
      this.value = beautifyTJString({ value: this.value, valueType: this.valueType })
    },
    setValues (vo) {
      this.vid = vo.vid
      this.votype = vo.votype
      this.name = vo.name
      this.note = vo.note
      this.value = beautifyTJString({ value: vo.value, valueType: this.valueType })

      this.regional = cache.getR()
    },
    getValues () {
      const vo = {
        vid: this.vid,
        votype: this.votype,
        valuetype: this.valueType,
        name: this.trueName,
        status: 1,
        note: this.note,
        value: this.value
      }
      return vo
    },
    clearValues () {
      this.vid = null
      this.name = null
      this.note = null
      this.votype = null
      this.regional = cache.getR()
    }
  }
}
</script>
