<template>
<footer class="footer has-background-light">
  <div class="container has-text-centered">
    <strong>MJP</strong> 是 <a href="https://sagigames.cn" target="_blank">SAGI GAMES</a> 早期后台管理系统。作者<a href="https://zengrong.net" target="_blank">曾嵘</a>。最后更新：{{ updated }}。
  </div>
</footer>
</template>

<script>
export default {
  name: 'appfooter',
  data () {
    return {
      updated: 'v2.0.0 2023-06-02 10:00:00'
    }
  }
}
</script>
