<template>
<value-object ref="vo"
  mjptype="admin"
  value-type="toml"
  :refresh-route-path="['/admvol']"
  @tab-change="onTabChange">
  <template slot="top">
    <b-field grouped class="box" position="is-centered">
      <b-select placeholder="votype" icon="view-module" v-model.number="selectVoType" @input="onVoTypeSelect">
        <option value="306">频繁访问的配置</option>
        <option value="307">通用配置</option>
      </b-select>
      <button class="button is-primary" @click="mjpadmUpdateCacheVotype">刷新 mjpadm({{selectVoType}}) 缓存</button>
      <button class="button is-warning" @click="mjpadmUpdateCacheAll">刷新 mjpadm 所有缓存</button>
    </b-field>
  </template>
  <template slot="buttons" slot-scope="props">
    <button class="button is-warning" @click="onEditClick(props.row)">修改</button>
  </template>
  <template slot="tabitems">
    <b-tab-item label="增加">
      <value-object-editor-j-t ref="editorAsAdd" value-type="toml" @edit="onEditSave" />
    </b-tab-item>
    <b-tab-item label="编辑" :disabled="!inEdit">
      <value-object-editor-j-t ref="editorAsEdit" value-type="toml" @edit="onEditSave" is-edit />
    </b-tab-item>
  </template>
</value-object>
</template>

<script>
/**
 * 专门编辑任务列表的配置
 * 因为task 队列仅仅包含在 mjp-admin 进程中，因此这个 308 votype 不能和其他的vo 一起在 ValueObjectList 中处理。
 * 必须指定使用 mjptype = admin 来调用 mjp-admin 中的 vo api 来创建或者修改 vo
 */

import ValueObject from '@/components/vo/ValueObject'
import ValueObjectEditorJT from '@/components/vo/ValueObjectEditorJT'
import cache from '@/core/cache'

export default {
  name: 'vot',
  components: { ValueObject, ValueObjectEditorJT },
  data () {
    return {
      inEdit: false,
      selectVoType: 306,
      useRegionalZero: false
    }
  },
  methods: {
    onVoTypeSelect (value) {
      this.$refs.vo.setVotype(value)
      this.$refs.vo.getRecords()
    },
    onTabChange ({ to, from }) {
      if (from === 2) {
        this.$refs.editorAsEdit.clearValues()
      }
      if (from === 1) {
        this.$refs.editorAsAdd.clearValues()
      }
      this.inEdit = to === 2
    },
    onEditClick (vo) {
      try {
        this.$refs.editorAsEdit.setValues(vo)
        this.$refs.vo.tabIndex = 2
      } catch (error) {
        this.hub.alert(`JSON 解析失败，配置可能是其他格式。${error}`, 2, 'is-danger')
      }
    },
    onEditSave (vo) {
      this.$refs.vo.onEditSave(vo)
    },
    async mjpadmUpdateCacheVotype () {
      try {
        // let params = { votype: this.selectVoType, r: this.useRegionalZero ? 0 : undefined }
        const params = { votype: this.selectVoType, r: cache.getR() }
        await this.mjp.get('/cf/cache/update/votype/', params, null, 'admin')
        this.hub.alert('刷新成功！', 3, 'is-info')
      } catch (error) {
        console.error(error)
      }
    },
    async mjpadmUpdateCacheAll () {
      try {
        // let params = { votype: this.selectVoType, r: this.useRegionalZero ? 0 : undefined }
        const params = { votype: this.selectVoType, r: cache.getR() }
        await this.mjp.get('/cf/cache/update/all/', params, null, 'admin')
        this.hub.alert('刷新成功！', 3, 'is-info')
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
