<template>
<div id="analyrealtime">
  <b-field grouped group-multiline>
    <b-select v-model.number="selectedFightType" @input="onFightTypeSelect" expanded>
      <option v-for="(o, i) in fightTypes" :key="i" :value="i">{{o}}</option>
    </b-select>
    <b-datepicker
      placeholder="日期1"
      v-model="date1"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar-today"
      expanded
      @input="onDateInput">
    </b-datepicker>
    <b-datepicker
      placeholder="日期2"
      v-model="date2"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar"
      expanded
      @input="onDateInput">
    </b-datepicker>
  </b-field>
  <line-chart :chart-data="chartData" :height="150"></line-chart>
</div>
</template>

<script>
import cache from '@/core/cache'
import LineChart from '@/components/charts/LineChart'
import { nextChartColor, fromNow, plusDate } from '@/core/util'

export default {
  name: 'analyrealtime',
  components: { LineChart },
  mounted () {
    this.getRecord()
  },
  computed: {
    regional () {
      return cache.getR(true)
    },
    selectedDates () {
      return [
        this.formatDate(this.date1),
        this.formatDate(plusDate(this.date1, { days: 1 })),
        this.formatDate(this.date2),
        this.formatDate(plusDate(this.date2, { days: 1 }))
      ]
    }
  },
  data () {
    return {
      minDate: fromNow({ days: -30 }),
      maxDate: new Date(),
      date1: new Date(),
      date2: fromNow({ days: -1 }),
      chartData: this.buildChartData(),
      tableData: {},
      isLoading: false,
      selectedFightType: 0,
      datesInStat: [],
      fightTypes: ['在线', '对战', '匹配到本地机器人', '无限模式', '死亡竞赛']
    }
  },
  methods: {
    onDateInput () {
      this.getRecord()
    },
    onFightTypeSelect (select) {
      this.buildFightChartData()
    },
    buildChartData (labels, datasets) {
      labels = labels || []
      datasets = datasets || []
      return { labels, datasets }
    },
    buildFightChartData () {
      // 留存图表的数据
      const dataSetsObj = {}
      Object.values(this.tableData).forEach(itemByDay => {
        Object.values(itemByDay).forEach(item => {
          if (!dataSetsObj[item.time]) {
            dataSetsObj[item.time] = {}
          }
          // 将数据按照日期，分开，保存选择的项目
          dataSetsObj[item.time][item.day] = item[`num${this.selectedFightType}`]
        })
      })
      // 其中的内容是 time 字符串，每5分钟一个
      const labels = Object.keys(dataSetsObj).sort()
      const datasets = []
      const days = Object.keys(this.tableData)
      for (let i = 0; i < days.length; i++) {
        const dayName = days[i]
        const dataset = { label: dayName, data: [], borderColor: nextChartColor(i) }
        for (const time of labels) {
          const timeObj = dataSetsObj[time]
          dataset.data.push(timeObj[dayName] || 0)
        }
        datasets.push(dataset)
      }
      this.chartData = this.buildChartData(labels, datasets)
    },
    buildTableData (day, data) {
      // 留存表格的数据，需要把表格“横”过来
      const stat = data.stat
      if (!stat) return
      stat.forEach(item => {
        const [dayInTime, timeInTime] = item.time.split(' ')
        let dayData = this.tableData[dayInTime]
        if (!dayData) {
          dayData = {}
          this.tableData[dayInTime] = dayData
        }
        let timeData = dayData[timeInTime]
        if (!timeData) {
          timeData = {}
          dayData[timeInTime] = timeData
        }
        timeData.day = dayInTime
        timeData.channel = item.channel
        timeData.time = timeInTime
        timeData.pid = item.pid
        // 将数据按照战斗类型分开
        for (const i in this.fightTypes) {
          timeData[`num${i}`] = item.num[i]
        }
      })
    },
    clearRecord () {
      this.chartData = this.buildChartData()
      this.tableData = {}
      this.selectedFightType = 0
    },
    async getRecord () {
      if (this.isLoading) {
        return
      }
      if (!this.hub.checkFilters([this.regional, '区服'])) {
        this.clearRecord()
        return
      }
      this.isLoading = true
      this.tableData = {}
      const [day1Start, day1End, day2Start, day2End] = this.selectedDates
      try {
        // 获取第一个日期的数据
        const data1 = await this.mjp.get('/analy/realtime/', {
          channel: '[]', // 不再区分 channel，按日期分类意义更大
          from_date: day1Start,
          to_date: day1End
        })
        // 获取第二个日期的数据
        const data2 = await this.mjp.get('/analy/realtime/', {
          channel: '[]',
          from_date: day2Start,
          to_date: day2End
        })
        // 先处理一次 Table 数据
        this.buildTableData(day1Start, data1)
        this.buildTableData(day2Start, data2)
        // 再依赖  Table 数据得到图表数据
        this.buildFightChartData()
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    }
  }
}
</script>
