import Vue from 'vue'
import Router from 'vue-router'

import cache from '@/core/cache'
import type from '@/core/type'
import commonLogin from '@/core/commonLogin'

import Admin from '@/views/Admin'
import Generator from '@/views/Generator'
import Analysis from '@/views/Analysis'
import Analy500 from '@/views/Analy500'
import AnalyRealtime from '@/views/AnalyRealtime'
import AnalySceneProps from '@/views/AnalySceneProps'
import AnalyPackProps from '@/views/AnalyPackProps'
import AnalyGameInfo from '@/views/AnalyGameInfo'
import AnalyShop from '@/views/AnalyShop'
import AnalyActor from '@/views/AnalyActor'
import AnalyVideoad from '@/views/AnalyVideoad'
import AnalyDead from '@/views/AnalyDead'
import ReleaseNote from '@/views/ReleaseNote'
import Mana from '@/views/Mana'
import ManaBanner from '@/views/ManaBanner'
import MpMaterialList from '@/views/MpMaterialList'
import MpMaterialNews from '@/views/MpMaterialNews'
import MpConfig from '@/views/MpConfig'
import MpTemplateMessage from '@/views/MpTemplateMessage'
import MpFollows from '@/views/MpFollows'
import MpMenu from '@/views/MpMenu'
import ValueObjectList from '@/views/ValueObjectList'
import ValueObjectListAdmin from '@/views/ValueObjectListAdmin'
import RankList from '@/views/RankList'
import ServerList from '@/views/RsiServerList'
import RsiApplication from '@/views/RsiApplication'
import RsiInstance from '@/views/RsiInstance'
import RsiKey from '@/views/RsiKey'
import RsiRelation from '@/views/RsiRelation'
import RedeemCode from '@/views/RedeemCode'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: '登录',
    component: () => import('@/views/Login')
  },
  {
    path: '/oidclogin',
    name: 'OIDC登录',
    component: () => import('@/views/OidcLogin')
  },
  {
    path: '/profile',
    name: '用户资料',
    component: () => import('@/views/Profile')
  },
  {
    path: '/history',
    name: '操作历史',
    component: () => import('@/views/History')
  },
  {
    path: '/home',
    name: '首页',
    component: () => import('@/views/Home')
  },
  {
    path: '/error',
    name: '错误',
    component: () => import('@/views/ErrorPage')
  },
  {
    path: '/statsinst',
    name: '负载统计',
    component: () => import('@/views/StatsInstances')
  },
  {
    path: '/analysevenday',
    name: '渠道玩家统计',
    component: () => import('@/views/AnalyServenDay'),
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/analysis',
    name: '渠道玩家统计',
    component: Analysis,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/analy500',
    name: '分享玩家统计',
    component: Analy500,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/analyrealtime',
    name: '实时在线统计',
    component: AnalyRealtime,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/analysceneprops',
    name: '中立道具统计',
    component: AnalySceneProps,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/analypackprops',
    name: '携带道具统计',
    component: AnalyPackProps,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/analyshop',
    name: '商城数据',
    component: AnalyShop,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/analygameinfo',
    name: '游戏数据统计',
    component: AnalyGameInfo,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/analyactor',
    name: '角色数量统计',
    component: AnalyActor,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/analyvideoad',
    name: '视频广告',
    component: AnalyVideoad,
    meta: { permission: [51] }
  },
  {
    path: '/analydead',
    name: '死亡竞赛',
    component: AnalyDead,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/admin',
    name: '管理员',
    component: Admin,
    meta: { permission: [50, 51] }
  },
  {
    path: '/gen',
    name: '生成器',
    component: Generator,
    meta: { permission: [50, 51] }
  },
  {
    path: '/account',
    name: '玩家账号',
    component: () => import('@/views/Account'),
    meta: { permission: [51, 53] }
  },
  {
    path: '/idconvert',
    name: 'ID转换器',
    component: () => import('@/views/IDConvertor'),
    meta: { permission: [50, 51, 53, 55] }
  },
  {
    path: '/channel',
    name: '渠道',
    component: () => import('@/views/Channel'),
    meta: { permission: [50, 51, 53, 55] }
  },
  {
    path: '/releasenote',
    name: '发布日志',
    component: ReleaseNote,
    meta: { permission: [51, 53] }
  },
  {
    path: '/gameserver',
    name: '服务器配置',
    component: () => import('@/views/GameServer'),
    meta: { permission: [51, 53] }
  },
  {
    path: '/mana',
    name: '后台接口',
    component: Mana,
    meta: { permission: [51, 53] }
  },
  {
    path: '/manabanner',
    name: 'Banner误触',
    component: ManaBanner,
    meta: { permission: [51, 53] }
  },
  {
    path: '/vol',
    name: '所有配置分类',
    component: ValueObjectList,
    meta: { permission: [50, 51, 53] }
  },
  {
    path: '/admvol',
    name: 'ADM配置',
    component: ValueObjectListAdmin,
    meta: { permission: [50, 51, 53] }
  },
  {
    path: '/vogc',
    name: '频繁访问的配置',
    component: () => import('@/views/ValueObjectGC'),
    meta: { permission: [50, 51, 53] }
  },
  {
    path: '/ranklist',
    name: '排行榜配置',
    component: RankList,
    meta: { permission: [50, 51, 53] }
  },
  {
    path: '/cdnfiles',
    name: 'CDN文件管理',
    component: () => import('@/views/CDNFiles'),
    meta: { permission: [50, 51, 53] }
  },
  {
    path: '/mp/materiallist',
    name: '公众号素材',
    component: MpMaterialList,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/mp/materialnews',
    name: 'mpmaterialnews',
    component: MpMaterialNews,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/mp/mpconfig',
    name: '公众号配置',
    component: MpConfig,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/mp/mptemsg',
    name: 'MP模版消息',
    component: MpTemplateMessage,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/mp/mpmenu',
    name: '公众号菜单',
    component: MpMenu,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/mp/follows',
    name: '公众号关注',
    component: MpFollows,
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/hongbao',
    name: '红包提现',
    component: () => import('@/views/Honebao'),
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/qqwallet',
    name: 'QQ钱包',
    component: () => import('@/views/Qqwallet'),
    meta: { permission: [51, 53, 55] }
  },
  {
    path: '/regionallist',
    name: '选择区服',
    component: () => import('@/views/RegionalList'),
    meta: { permission: [50, 51, 53, 55] }
  },
  {
    path: '/serverlist',
    name: 'Sever',
    component: ServerList,
    meta: { permission: [51] }
  },
  {
    path: '/applist',
    name: 'Application',
    component: RsiApplication,
    meta: { permission: [51] }
  },
  {
    path: '/instance',
    name: 'Instance',
    component: RsiInstance,
    meta: { permission: [51] }
  },
  {
    path: '/key',
    name: 'Key',
    component: RsiKey,
    meta: { permission: [51] }
  },
  {
    path: '/relation',
    name: 'Relation',
    component: RsiRelation,
    meta: { permission: [51] }
  },
  {
    path: '/redeemcode',
    name: '兑换码',
    component: RedeemCode,
    meta: { permission: [51, 53] }
  },
  {
    path: '/mapi',
    name: 'MAPI',
    component: () => import('@/views/Mapi'),
    meta: { permission: [51, 53] }
  }
]

const router = new Router({
  // mode: 'history',
  routes
})

const checkPermit = function (permission, exclude) {
  let permit = true
  if (permission) {
    permit = permission.includes(cache.getUserType())
  }
  if (exclude) {
    return !permit
  }
  return permit
}

router.beforeEach((to, from, next) => {
  // 对于首页、错误页面、oidc 登录页面，不需要检查权限
  if (['/', '/error', '/oidclogin'].includes(to.path)) {
    next()
    return
  }
  let accesstoken = null
  try {
    console.log('get user')
    accesstoken = cache.getAccessToken()
    // 有token，检查权限
    if (accesstoken) {
      if (commonLogin.login(window.location.href)) {
        const redirecturl = localStorage.getItem('redirecturl')
        window.location.href = `${redirecturl}?status=1&accesstoken=${accesstoken}`
        localStorage.removeItem('redirecturl')
      }
      if (checkPermit(to.meta.permission, to.meta.exclude)) {
        // 检查权限通过，但缓存中没有 type。一般是自动登录的账号。必须初始化 type 才能继续
        if (type.needPreload()) {
          type
            .preload(accesstoken)
            .then((data) => {
              next()
            })
            .catch((error) => {
              alert(error)
            })
        } else {
          next()
        }
      } else {
        cache.setValue('errorMessage', '没有权限进入!')
        next('/error')
      }
    } else {
      alert('请先登入!')
      next('/')
    }
  } catch (error) {
    cache.setValue('errorMessage', error.message)
    next('/error')
  }
})

export default router
