<template>
<div id="generator" class="container">
  <b-field horizontal label="选择生成器">
    <b-select v-model="genType" @input="clearValues">
      <option value="mjst">MJST</option>
      <option value="logintoken">LOGIN TOKEN</option>
      <option value="secretkey">SECRET KEY</option>
    </b-select>
    <b-switch v-if="genType === 'mjst'" v-model="forGS" @input="onGSTokenChange">GS 专用 MJST 配置</b-switch>
  </b-field>
  <b-field horizontal label="UID" v-if="genType === 'mjst' || genType === 'logintoken'">
    <b-input :disabled="forGS" v-model.number="uid"></b-input>
  </b-field>
  <b-field horizontal label="USERTYPE" v-if="genType === 'mjst'">
    <b-select :disabled="forGS" v-model.number="usertype">
      <option value="50">超级管理员</option>
      <option value="51">管理员</option>
      <option value="61">渠道商</option>
    </b-select>
  </b-field>
  <b-field horizontal label="NICKNAME" v-if="genType === 'mjst'">
    <b-input :disabled="forGS" v-model="nickname"></b-input>
  </b-field>
  <b-field horizontal label="EXPIRE" v-if="genType === 'mjst' || genType === 'logintoken'">
    <b-input :disabled="forGS" v-model.number="expire" placeholder="过期时间（秒），默认值为 86400"></b-input>
  </b-field>
  <b-field horizontal label="TS" v-if="genType === 'mjst' || genType === 'logintoken'">
    <b-input :disabled="forGS" v-model.number="ts" placeholder="过期的具体时间戳，若不提供则为 NOW+EXPIRE"></b-input>
  </b-field>
  <b-field position="is-centered" grouped>
    <button :disabled="!canGen" class="button is-success" @click="onSubmit">生成</button>
  </b-field>
  <b-field horizontal :label="genType === 'secretkey' ? 'SECRET KEY' : 'TOKEN'" v-if="tokenvalue">
    <b-input v-model="tokenvalue" disabled></b-input>
  </b-field>
  <b-field horizontal label="TS" v-if="tokents !== null">
    <b-input v-model="tokents" disabled></b-input>
  </b-field>
</div>
</template>

<script>
import cache from '@/core/cache'

export default {
  name: 'profile',
  computed: {
    canGen: function () {
      if (this.genType === 'mjst') {
        return this.uid !== null && this.nickname && this.usertype
      } else if (this.genType === 'logintoken') {
        return this.uid !== null
      } else if (this.genType === 'secretkey') {
        return true
      }
      return false
    }
  },
  data: function () {
    const user = cache.getUser()
    return {
      user: user,
      genType: null,
      uid: null,
      nickname: null,
      usertype: null,
      expire: null,
      ts: null,
      tokenvalue: null,
      tokents: null,
      forGS: null
    }
  },
  methods: {
    clearValues () {
      this.uid = null
      this.nickname = null
      this.usertype = null
      this.expire = null
      this.ts = null
      this.tokenvalue = null
      this.tokents = null
      this.forGS = false
    },
    onGSTokenChange (forGS) {
      if (forGS) {
        this.uid = 0
        this.nickname = 'GS'
        this.usertype = 50
        this.expire = 0
        this.ts = 0
      } else {
        this.clearValues()
      }
    },
    async onSubmit () {
      let rdata = null
      try {
        if (this.genType === 'secretkey') {
          rdata = await this.mjp.get('/admin/gen/secretkey/')
          this.tokenvalue = rdata.secretkey
          this.tokents = null
        } else if (this.genType === 'logintoken') {
          rdata = await this.mjp.post('/auth/gen/logintoken/', { uid: this.uid, expire: this.expire, ts: this.ts })
          this.tokenvalue = rdata.token
          this.tokents = rdata.ts
        } else if (this.genType === 'mjst') {
          rdata = await this.mjp.post('/admin/gen/mjst/', { uid: this.uid, expire: this.expire, ts: this.ts, nickname: this.nickname, usertype: this.usertype })
          this.tokenvalue = rdata.token
          this.tokents = rdata.ts
        }
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style>
</style>
