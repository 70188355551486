<template>
<div id="analyprops">
  <b-field grouped group-multiline>
    <date-quick @change="onDateQuick" expanded/>
    <b-datepicker
      placeholder="开始日期"
      v-model="fromDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar-today"
      expanded
      @input="onDateInput">
    </b-datepicker>
    <b-datepicker
      placeholder="截止日期"
      v-model="toDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar"
      expanded
      @input="onDateInput">
    </b-datepicker>
  </b-field>
  <horizontal-bar-chart :chart-data="chartData" :options="chartOptions"></horizontal-bar-chart>
  <b-table ref="table" :data="tableData">
    <b-table-column #default="props" field="pid" label="道具ID" width="80" numeric>
      {{ props.row.pid }}
    </b-table-column>
    <b-table-column #default="props" field="name" label="道具名称" width="100">
      {{ props.row.name }}
    </b-table-column>
    <b-table-column #default="props" field="date" label="日期" width="120">
      {{ props.row.date }}
    </b-table-column>
    <b-table-column #default="props" field="num0" label="出现次数" numeric width="120">
      {{ props.row.num0 }}
    </b-table-column>
    <b-table-column #default="props" field="num1" label="击中次数" numeric width="120">
      {{ props.row.num1 }}
    </b-table-column>
  </b-table>
</div>
</template>

<script>
import config from '@/core/config'
import cache from '@/core/cache'
import HorizontalBarChart from '@/components/charts/HorizontalBarChart'
import DateQuick from '@/components/DateQuick'
import { nextChartColor, fromNow, sortDate } from '@/core/util'

export default {
  name: 'analyprops',
  components: { HorizontalBarChart, DateQuick },
  mounted () {
    this.getRecord()
  },
  computed: {
    regional () {
      return cache.getR(true)
    }
  },
  data () {
    return {
      minDate: new Date(2018, 8, 1),
      maxDate: new Date(),
      fromDate: fromNow({ days: -7 }),
      toDate: new Date(),
      chartData: this.buildChartData(),
      chartOptions: null,
      tableData: [],
      isLoading: false,
      propsInStat: []
    }
  },
  methods: {
    getPropName (pid) {
      const p = config.SCENE_PROPS_WITH_KEY[pid]
      if (p) return p.name
      return pid
    },
    onDateQuick (date) {
      this.fromDate = date.fromDate
      this.toDate = date.toDate
    },
    onDateInput (date) {
      this.getRecord()
    },
    buildChartData (labels, datasets) {
      labels = labels || []
      datasets = datasets || []
      return { labels, datasets }
    },
    buildDatasets (dataSetsObj, labels) {
      // 同时创建道具出现和道具击中的次数
      const datasets = []
      const yAxes = []
      for (let i = 0; i < this.propsInStat.length; i++) {
        const pid = this.propsInStat[i]
        const yAxiesID = `y-axies-${pid}`
        const propName = this.getPropName(pid)
        const color = nextChartColor(i)
        // 道具的出现次数
        const datasetShow = { label: `${propName}/出现`, data: [], backgroundColor: color, borderColor: 'rgb(200, 200, 200)', borderWidth: 1, yAxiesID }
        // 道具的击中次数
        const datasetHit = { label: `${propName}/击中`, data: [], backgroundColor: color, yAxiesID }
        for (const date of labels) {
          const day = dataSetsObj[date]
          datasetShow.data.push(day[pid] ? day[pid][0] : 0)
          datasetHit.data.push(day[pid] ? day[pid][1] : 0)
        }
        // 两组数据的 yAxiesID 是相同的
        yAxes.push({ id: yAxiesID }, { id: yAxiesID })
        datasets.push(datasetShow, datasetHit)
      }
      return [datasets, yAxes]
    },
    buildPropChartData () {
      // 留存图表的数据
      const dataSetsObj = {}
      this.tableData.forEach(item => {
        if (!dataSetsObj[item.date]) {
          dataSetsObj[item.date] = {}
        }
        // 将数据按照日期分开
        dataSetsObj[item.date][item.pid] = [item.num0, item.num1]
      })
      // 其中的内容是 date 字符串
      const labels = sortDate(Object.keys(dataSetsObj))
      const [datasets, yAxes] = this.buildDatasets(dataSetsObj, labels)
      // 加入 yAxes 后显示不正常
      // this.chartOptions = { scales: { yAxes } }
      this.chartData = this.buildChartData(labels, datasets)
      console.log('chartData:%o', this.chartData)
      console.log('chartOptions:%o', this.chartOptions)
      console.log('yAxes:%o', yAxes)
    },
    buildTableData (data) {
      // 留存表格的数据，需要把表格“横”过来
      const stat = data.stat
      const statWithPropKey = {}
      stat.forEach(item => {
        for (const [propKey, propItem] of Object.entries(item.sceneprops)) {
          if (!statWithPropKey[propKey]) {
            statWithPropKey[propKey] = {}
          }
          if (!statWithPropKey[propKey][item.date]) {
            statWithPropKey[propKey][item.date] = {}
          }
          statWithPropKey[propKey][item.date].pid = propKey
          statWithPropKey[propKey][item.date].date = item.date
          statWithPropKey[propKey][item.date].name = this.getPropName(propKey)
          // 将数据按照战斗类型分开
          // propItem 是一个2元素数组，0为出现次数，1为击中次数
          for (let i = 0; i < propItem.length; i++) {
            statWithPropKey[propKey][item.date][`num${i}`] = propItem[i]
          }
        }
      })
      // 用于表格的数据
      const statList = []
      Object.values(statWithPropKey).forEach(propItem => {
        Object.values(propItem).forEach(dateItem => {
          statList.push(dateItem)
        })
      })
      // 倒序
      // statList.sort(this.sortChannelAndDate)
      this.tableData = statList

      // 在这里获得最简单， buildPropChartData 需要这个值
      this.propsInStat = Object.keys(statWithPropKey)
    },
    clearRecord () {
      this.chartData = this.buildChartData()
      this.chartOptions = {}
      this.tableData = []
      this.propsInStat = []
    },
    async getRecord () {
      if (this.isLoading) {
        return
      }
      if (!this.hub.checkFilters([this.regional, '区服'])) {
        this.clearRecord()
        return
      }
      this.isLoading = true
      try {
        const data = await this.mjp.get2({
          url: '/analy/props/',
          query: { from_date: this.formatDate(this.fromDate), to_date: this.formatDate(this.toDate) },
          mjpType: 'pa'
        })
        // 先处理一次 Table 数据
        this.buildTableData(data)
        // 再依赖  Table 数据得到图表数据
        this.buildPropChartData()
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    }
  }
}
</script>
