<template>
<div id="admin">
  <b-field class="markdown-body">
    <help-admin />
  </b-field>
  <b-tabs v-model="tabIndex" position="is-centered" type="is-boxed" size="is-medium">
    <b-tab-item label="管理员列表">
      <b-table :data="admins"
        paginated backend-pagination
        :loading="tableLoading"
        :per-page="perPage"
        :total="total"
        :current-page="currentPage"
        @page-change="getAdmins">
        <b-table-column #default="props" field="status" label="状态" width="60" centered>
          <b-tag :type="props.row.status===1?'is-success':'is-light'">{{ props.row.status===1?'已启用':'已禁用'}}</b-tag>
        </b-table-column>
        <b-table-column #default="props" field="adminuid" label="UID" width="40" numeric>
          {{ props.row.adminuid }}
        </b-table-column>
        <b-table-column #default="props" field="username" label="用户名" width="200">
          {{ props.row.username }}
        </b-table-column>
        <b-table-column #default="props" field="nickname" label="昵称" width="200">
          {{ props.row.nickname }}
        </b-table-column>
        <b-table-column #default="props" field="workid" label="企业微信ID" width="120">
          {{ props.row.wxopenid }}
        </b-table-column>
        <b-table-column #default="props" label="类型" width="120">
          {{ props.row.typename }}
        </b-table-column>
        <b-table-column #default="props" field="createtime" label="创建时间" centered>
          {{ formatDateTime(props.row.createtime) }}
        </b-table-column>
        <b-table-column #default="props" field="updatetime" label="更新时间" centered>
          {{ formatDateTime(props.row.updatetime) }}
        </b-table-column>
        <b-table-column #default="props" label="操作">
          <div class="buttons">
            <button class="button is-info" @click="onEditClick(props.row)">修改</button>
            <button class="button is-danger" @click="onDeleteClick(props.row)">删除</button>
          </div>
        </b-table-column>
        <template #bottom-left>
          <b>总计：</b>{{ total }}
        </template>
      </b-table>
    </b-tab-item>
    <b-tab-item label="增加管理员">
      <admin-editor ref="adminAdd" @admin-edit="onAdminAddSave"></admin-editor>
    </b-tab-item>
    <b-tab-item label="编辑管理员" :disabled="!inEdit">
      <admin-editor ref="adminEdit" is-edit="true" @admin-edit="onAdminEditSave"></admin-editor>
    </b-tab-item>
  </b-tabs>
</div>
</template>

<script>
import AdminEditor from '@/components/AdminEditor'
import type from '@/core/type'
import config from '@/core/config'
import HelpAdmin from '@/help/admin.md'

export default {
  name: 'admin',
  components: { AdminEditor, HelpAdmin },
  created: function () {
    this.getAdmins()
  },
  computed: {
  },
  data: function () {
    return {
      admins: [],
      perPage: config.PER_PAGE,
      total: 0,
      currentPage: 1,
      tabIndex: 0,
      tableLoading: false,
      inEdit: false
    }
  },
  methods: {
    onDeleteClick: function (admin) {
      this.$buefy.dialog.confirm(
        {
          title: '删除管理员',
          message: `是否要删除管理员 <strong>${admin.nickname}</strong> ？`,
          onConfirm: () => {
            const adminObj = { idvalue: admin.adminuid, idtype: 'adminuid' }
            this.mjp.post2({
              url: '/admin/del/',
              data: adminObj,
              mjpType: 'admin'
            }).then(data => {
              this.getAdmins()
            })
          }
        }
      )
    },
    onEditClick: function (admin) {
      this.$refs.adminEdit.setValues(admin)
      this.inEdit = true
      this.tabIndex = 2
    },
    onAdminAddSave: function (admin) {
      this.mjp.post2({
        url: '/admin/add/',
        data: admin,
        mjpType: 'admin'
      }).then(data => {
        this.tabIndex = 0
      }).catch(err => {
        console.error(err)
      })
    },
    onAdminEditSave: function (admin) {
      this.mjp.post2({
        url: '/admin/edit/',
        data: admin,
        mjpType: 'admin'
      }).then(data => {
        this.tabIndex = 0
      }).catch(err => {
        console.error(err)
      })
    },
    getAdmins: function (page) {
      page = page || this.currentPage || 1
      this.tableLoading = true
      this.mjp.get2({
        url: '/admin/get/more/',
        query: { page, per_page: this.perPage },
        mjpType: 'admin'
      }).then(data => {
        this.admins = data.admins
        this.perPage = data.per_page
        this.currentPage = data.page
        this.total = data.total
        this.admins.forEach((item, index) => {
          const typeobj = type.getOneType(item.usertype)
          item.typename = typeobj ? typeobj.name : ''
        })
      }).catch(err => {
        console.error(err)
      })
      this.tableLoading = false
    }
  },
  watch: {
    tabIndex: function (to, from) {
      // 从编辑管理员切换出来的时候清空编辑管理员中保存的值
      if (from === 2) {
        this.inEdit = false
        this.$refs.adminEdit.clearValues()
      }
      if (from === 1) {
        this.$refs.adminAdd.clearValues()
      }
      // 切换到增加管理员的时候，更新必须的值。修改管理员的值更新由 setValues 进行
      if (to === 1) {
        this.$refs.adminAdd.updateValues()
      }
      if (to === 0) {
        this.getAdmins()
      }
    }
  }
}
</script>
