const adminMJPServers = [
  {
    index: 0,
    name: '正式服 API',
    admapi: 'https://api1.sagiteam.cn/mjpadm',
    mpapi: 'https://api.sagiteam.cn/mjpmp',
    isProd: true
  },
  {
    index: 1,
    name: '测试服 API',
    admapi: 'https://gst.sagiteam.cn/mjp2api/mjpadm',
    mpapi: 'https://gst.sagiteam.cn/mjp2api/mjpmp',
    isProd: false
  },
  {
    index: 2,
    name: '本地服 API',
    admapi: 'http://127.0.0.1:5001/mjpadm',
    mpapi: 'http://127.0.0.1:5001/mjpmp',
    isProd: false,
    isLocal: true
  }
]

const characters = [
  { name: '吸血猎人', key: 'axegangster', value: 101 },
  { name: '富翁', key: 'president', value: 102 },
  { name: '丘比特', key: 'cupid', value: 103 },
  { name: '小红帽', key: 'lrrh', value: 104 },
  { name: '肥婆七', key: 'baozupo', value: 105 },
  { name: '萝拉', key: 'lara', value: 106 },
  { name: '绝地鸡王', key: 'chickeneater', value: 107 },
  { name: '核能肥宅', key: 'king', value: 108 },
  { name: '面霸', key: 'manos', value: 109 },
  { name: '粉红小妹', key: 'page', value: 110 },
  { name: '女皇大人', key: 'queen', value: 111 },
  { name: '咖喱王', key: 'fgo', value: 112 },
  { name: '白雪公主', key: 'snowwhite', value: 113 },
  { name: '龙娘', key: 'daenerys', value: 114 },
  { name: '大粽子', key: 'zombie', value: 115 },
  { name: '小贝贝', key: 'beckham', value: 116 },
  { name: '吸螺', key: 'cronaldo', value: 117 },
  { name: '没戏', key: 'messi', value: 118 },
  { name: '超级智', key: 'zhi', value: 119 },
  { name: '战国女巫', key: 'kikyo', value: 120 },
  { name: '森林公主', key: 'san', value: 121 },
  { name: '虫虫男孩', key: 'spider', value: 122 },
  { name: '疯狂萝莉', key: 'harley', value: 123 },
  { name: '溜溜球小子', key: 'yoyoboy', value: 124 },
  { name: '反恐精英', key: 'cs', value: 125 },
  { name: '正义队长', key: 'captain', value: 126 },
  { name: '唐大锤', key: 'robotman', value: 127 },
  { name: '托尔', key: 'thor', value: 128 },
  { name: '海盗船长', key: 'jake', value: 129 }
]

const sceneProps = [
  { key: 1, value: 1, name: '血瓶' },
  { key: 2, value: 2, name: '护盾' },
  { key: 4, value: 4, name: '疯狂' },
  { key: 8, value: 8, name: '大烧鸡' },
  { key: 9, value: 9, name: '瞄准镜' },
  { key: 10, value: 10, name: '致盲' },
  { key: 13, value: 13, name: '致命一击' },
  { key: 15, value: 15, name: '重伤不治' }
]

const packProps = [
  { key: 0, value: 0, name: '未携带道具' },
  { key: 1, value: 1, name: '杀戳标记' },
  { key: 2, value: 2, name: '蓄力一击' },
  { key: 3, value: 3, name: '淬毒武器' },
  { key: 4, value: 4, name: '怒气爆发' },
  { key: 5, value: 5, name: '士气高涨' },
  { key: 6, value: 6, name: '锋锐利箭' },
  { key: 101, value: 101, name: '破旧盾牌' },
  { key: 102, value: 102, name: '救心丸' },
  { key: 103, value: 103, name: '银鳞胸甲' },
  { key: 104, value: 104, name: '三级头盔' },
  { key: 105, value: 105, name: '止痛片' },
  { key: 106, value: 106, name: '肾上激素' },
  { key: 107, value: 107, name: '秘银盾牌' },
  { key: 201, value: 201, name: 'OK绷' },
  { key: 202, value: 202, name: '免死金牌' },
  { key: 203, value: 203, name: '记忆超群' },
  { key: 204, value: 204, name: '8倍镜' },
  { key: 205, value: 205, name: '貌美如花' },
  { key: 206, value: 206, name: '腐化毒箭' }
]

const chartColors = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
}

const colors = [
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba'
]

const rankClearTypes = { 0: '不清', 1: '日清', 2: '周清', 3: '月清' }

const userStatusTypes = [
  {
    status: -1,
    name: '删除'
  },
  {
    status: 1,
    name: '正常'
  },
  {
    status: 2,
    name: '状态2'
  },
  {
    status: 3,
    name: '状态3'
  },
  {
    status: 4,
    name: '状态4'
  },
  {
    status: 5,
    name: '禁用'
  }
]

function getAdminMJP (mjpIndex) {
  mjpIndex = Number.parseInt(mjpIndex)
  if (Number.isNaN(mjpIndex)) mjpIndex = 0
  return adminMJPServers[mjpIndex]
}

function array2Object (list, key) {
  const obj = {}
  for (const item of list) {
    obj[item[key]] = item
  }
  return obj
}

export default {
  ADMIN_MJP_SERVERS: adminMJPServers,
  PATH: location.pathname.substr(0, location.pathname.lastIndexOf('/')),
  PER_PAGE: 15,
  CHART_COLORS: chartColors,
  COLORS: colors,
  CHARACTERS: characters,
  CHARACTERS_WITH_KEY: array2Object(characters, 'value'),
  SCENE_PROPS: sceneProps,
  SCENE_PROPS_WITH_KEY: array2Object(sceneProps, 'value'),
  PACK_PROPS: packProps,
  PACK_PROPS_WITH_KEY: array2Object(packProps, 'value'),
  RANK_CLEAR_TYPES: rankClearTypes,
  USER_STATUS_TYPES: userStatusTypes,
  getAdminMJP: getAdminMJP
}
