<template>
  <div>
    <b-field v-if="instance" label="部署实例" horizontal position="is-centered">
      <h4 class="title is-4 has-text-danger has-text-weight-bold">
        {{instance.name}}({{instance.nid}}) 位于 {{instance.serverLabel}}
      </h4>
    </b-field>
    <b-field label="更新实例" horizontal>
      <div class="box">
        <b-field label="选择实例 git 分支" horizontal>
          <b-select v-model="branch">
            <option value="master">master</option>
            <option value="develop">develop</option>
          </b-select>
          <b-input v-model="branch" placeholder="输入分支名称" />
        </b-field>
        <b-field label="同时更新虚拟环境" horizontal>
          <b-switch  v-model.number="venv" true-value=1 false-value=0> venv </b-switch>
        </b-field>
        <b-field label="同时重载实例" horizontal>
          <b-switch  v-model.number="reload" true-value=1 false-value=0> reload </b-switch>
        </b-field>
        <b-field grouped position="is-centered">
          <b-tooltip label="从 Git 服务器中更新实例源码。同时允许用开关控制更新虚拟环境并重启。如果这个实例从来没有部署过，那么虚拟环境的安装以及实例的启动会自动执行，无论有没有选择 venv 和 reload。" position="is-bottom" multilined>
            <button size="is-medium" class="button is-warning" @click="deployUpdate()">UPDATE</button>
          </b-tooltip>
        </b-field>
      </div>
    </b-field>
    <b-field label="控制实例" horizontal class="buttons">
      <div class="box">
        <b-field label="重载实例" horizontal>
            <button size="is-medium" class="button is-warning" @click="deployReload()">RELOAD</button>
        </b-field>
        <b-field label="启动实例" horizontal>
          <b-tooltip label="启动实例，仅当实例还没有启动，或已经完全停止的时候使用。">
            <button size="is-medium" class="button is-success" @click="deployStart()">START</button>
          </b-tooltip>
        </b-field>
        <b-field label="停止实例" horizontal>
          <b-tooltip label="停止实例，删除运行状态文件。">
            <button size="is-medium" class="button is-danger" @click="deployStop()">STOP</button>
          </b-tooltip>
        </b-field>
        <b-field label="更新虚拟环境" horizontal>
          <b-tooltip label="更新实例的虚拟环境，可能耗时 10 秒以上。更新虚拟环境后，必须重载实例才会生效。" position="is-bottom" multilined>
            <button  size="is-medium" class="button is-warning" @click="deployVenv()">VENV</button>
          </b-tooltip>
        </b-field>
      </div>
    </b-field>
    <b-field label="PIP包管理" horizontal>
      <div class="box">
        <b-field label="模块列表" horizontal>
          <b-tooltip label="查看 VENV 虚拟环境中的 Python 模块">
            <button size="is-medium" class="button is-info" @click="deployPipList()">查看模块</button>
          </b-tooltip>
          <b-switch v-model="pipOnlyShowOutdated">仅显示过期模块</b-switch>
        </b-field>
        <b-field label="模块升级" horizontal>
          <b-tooltip label="升级 VENV 虚拟环境中的 Python 模块">
            <button size="is-medium" class="button is-danger" @click="deployPipUpgrade()">升级模块</button>
          </b-tooltip>
          <b-switch v-model="pipUpgradeAll">升级所有模块</b-switch>
          <b-input v-if="!pipUpgradeAll" v-model="pipUpgradeList" placeholder="请输入模块名称，使用英文半角空格分隔" />
        </b-field>
      </div>
    </b-field>
    <b-field v-if="instance" label="API 访问" horizontal>
      <b-collapse ref="apiContent" class="card" aria-id="apiContent" :open=true @open="deployApiInfo()" @close="clearCloseCollapse()">
        <div slot="trigger" slot-scope="props" class="card-header" role="button" alia-controls="apiContent">
          <p class="card-header-title">{{instance.deployuri}} {{ props.open ? '关闭详情' : '展开详情'}}</p>
          <a class="card-header-icon"><b-icon :icon="props.open ? 'menu-down': 'menu-up'"></b-icon></a>
        </div>
        <div class="card-content columns">
          <div class="column has-text-left">
            <h4 class="title is-4 is-spaced">API网关</h4>
            <h6 class="subtitle is-5" v-if="apigwinfo === false">不可用</h6>
            <h6 class="subtitle is-5" v-else-if="apigwinfo === null">未配置</h6>
            <table class="table" v-else>
              <thead>
                <tr>
                  <th>名称</th>
                  <th>内容</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>apiName</th>
                  <td>{{apigwinfo.apiName}}</td>
                </tr>
                <tr>
                  <th>apiId</th>
                  <td>{{apigwinfo.apiId}}</td>
                </tr>
                <tr>
                  <th>apiType</th>
                  <td>{{apigwinfo.apiType}}</td>
                </tr>
                <tr>
                  <th>apiBuniessType</th>
                  <td>{{apigwinfo.apiBuniessType}}</td>
                </tr>
                <tr>
                  <th>apiDesc</th>
                  <td>{{apigwinfo.apiDesc}}</td>
                </tr>
                <tr>
                  <th>protocol</th>
                  <td>{{apigwinfo.protocol}}</td>
                </tr>
                <tr>
                  <th>path</th>
                  <td>{{apigwinfo.path}}</td>
                </tr>
                <tr>
                  <th>serviceId</th>
                  <td>{{apigwinfo.serviceId}}</td>
                </tr>
                <tr>
                  <th>uniqVpcId</th>
                  <td>{{apigwinfo.uniqVpcId}}</td>
                </tr>
                <tr>
                  <th>createdTime</th>
                  <td>{{apigwinfo.createdTime}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column has-text-left">
            <h4 class="title is-4 is-spaced">负载均衡</h4>
            <h6 class="subtitle is-5" v-if="clb === false">不可用</h6>
            <h6 class="subtitle is-5" v-else-if="clb === null">未配置</h6>
            <table class="table" v-else>
              <thead>
                <tr>
                  <th>名称</th>
                  <th>内容</th>
                </tr>
                <tr>
                  <th>Domain</th>
                  <td>{{clb.Domain}}</td>
                </tr>
                <tr>
                  <th>Url</th>
                  <td>{{clb.Url}}</td>
                </tr>
                <tr>
                  <th>LocationId</th>
                  <td>{{clb.LocationId}}</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in clb.Targets" :key="item.Port">
                  <th>后端服务器 {{index}}</th>
                  <td>
                    <ul>
                      <li>服务器 ID：{{item.InstanceId}}</li>
                      <li>服务器名称：{{item.InstanceName}}</li>
                      <li>端口：{{item.Port}}</li>
                      <li>内网地址：{{item.PrivateIpAddresses.join(',')}}</li>
                      <li>外网地址：{{item.PublicIpAddresses.join(',')}}</li>
                      <li>绑定时间：{{item.RegisteredTime}}</li>
                      <li>权重：{{item.Weight}}</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column has-text-left">
            <h4 class="title is-4 is-spaced">安全组</h4>
            <h6 class="subtitle is-5" v-if="vpc === false">不可用</h6>
            <h6 class="subtitle is-5" v-else-if="vpc === null">未配置</h6>
            <table class="table" v-else>
              <thead>
                <tr>
                  <th>名称</th>
                  <th>内容</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>端口</th>
                  <td>{{vpc.Port}}</td>
                </tr>
                <tr>
                  <th>协议</th>
                  <td>{{vpc.Protocol}}</td>
                </tr>
                <tr>
                  <th>来源</th>
                  <td>{{vpc.CidrBlock}}</td>
                </tr>
                <tr>
                  <th>备注</th>
                  <td>{{vpc.PolicyDescription}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <footer class="card-footer" v-if="vpc !== false && clb !== false">
          <a class="card-footer-item" @click="deployApiAdd">创建 API</a>
          <a class="card-footer-item has-text-danger" @click="deployApiDel">删除 API</a>
        </footer>
      </b-collapse>
    </b-field>
    <div v-if="message" class="control is-clearfix"><textarea class="textarea" rows="20" disabled v-model="message" /></div>
  </div>
</template>

<script>
export default {
  name: 'deploy',
  data () {
    return {
      message: null,
      venv: 0,
      reload: 0,
      branch: 'master',
      taskType: null,
      instance: null,
      vpc: null,
      clb: null,
      apigwinfo: null,
      pipOnlyShowOutdated: true,
      pipUpgradeList: null,
      pipUpgradeAll: false
    }
  },
  methods: {
    clear () {
      this.pipOnlyShowOutdated = true
      this.pipUpgradeList = null
      this.pipUpgradeAll = false
      this.venv = 0
      this.reload = 0
      this.branch = 'master'
      this.clearCloseCollapse()
    },
    clearCloseCollapse () {
      this.message = null
      this.taskType = null
      this.vpc = null
      this.clb = null
      // apigwinfo 要保存是否在负载均衡处理完毕之后继续 apigateway 处理的状态，因此不能在关闭 collapse 的时候清空
      // this.apigwinfo = null
    },
    setInstance (ins) {
      this.instance = ins
      if (this.instance === null) {
        this.clear()
      } else {
        this.branch = ins.ntype === 'auth' ? 'develop' : 'master'
      }
    },
    /**
     * 判断是否需要继续查看异步任务状态
     */
    needStopTask (rdata) {
      console.log('needStopTask rdata: %o, apigwinfo: %o, taskType: %s', rdata, this.apigwinfo, this.taskType)
      // 若 status 为成功，需要判断他们的类型。因为异步任务中集中了好几个小任务，小任务完成的时候，status 也会为 0
      if (rdata.Status === 0) {
        if (this.taskType === 'api_add' && rdata.type === 'clbbind') {
          return this.taskType
        }
        if (this.taskType === 'api_del' && rdata.type === 'clbrule') {
          return this.taskType
        }
      } else if (rdata.Status === 1) {
        // 小任务失败的时候肯定要停止
        return 'api_error'
      }
      // 其他情况都应该继续
      return 'api_continue'
    },
    /**
     * 获取异步 task 的状态，延迟 2 秒执行
     */
    deployTaskStatus (taskid) {
      setTimeout(async (taskid) => {
        try {
          const rdata = await this.mjp.get2({
            url: '/rsi/deploy/task/status/',
            query: { taskid: taskid },
            mjpType: 'admin'
          })
          this.message = `taskid: ${rdata.taskid} type: ${rdata.type} status: ${rdata.Status}`
          console.info('rdata %o, apigwinfo: %o', rdata, this.apigwinfo)
          if (rdata.error) {
            this.message += rdata.message
          } else {
            const apiStatus = this.needStopTask(rdata)
            if (apiStatus === 'api_continue') {
              this.deployTaskStatus(rdata.taskid)
            } else {
              if (apiStatus === 'api_add') {
                // apigwinfo 为 null 代表可以创建 apigateway，若为 false 代表这个 instance 不支持 apigateway
                if (this.apigwinfo === null) {
                  try {
                    this.message = '创建 API 网关……'
                    await this.mjp.get2({
                      url: '/rsi/deploy/apigateway/add/',
                      query: { nid: this.instance.nid },
                      mjpType: 'admin'
                    })
                    this.message = '创建 API 网关完成。'
                  } catch (e) {
                    this.message = '创建 API 网关错误。'
                  }
                }
              } else if (apiStatus === 'api_del') {
                // apigwinfo 包含有效值代表可以删除 apigateway
                if (this.apigwinfo) {
                  try {
                    this.message = '删除 API 网关……'
                    await this.mjp.get2({
                      url: '/rsi/deploy/apigateway/del/',
                      query: { nid: this.instance.nid },
                      mjpType: 'admin'
                    })
                    this.message = '删除 API 网关完成。'
                  } catch (e) {
                    this.message = '删除 API 网关错误。'
                  }
                }
              }
              this.hub.showProgress(false)
              this.$refs.apiContent.toggle()
            }
          }
        } catch (e) {
          this.message = '获取 API 状态错误，请稍候自行查询 API 配置状态。'
          console.error(e)
          this.vpc = false
          this.clb = false
          this.apigwinfo = false
          this.hub.showProgress(false)
        }
      }, 2000, taskid)
    },
    async deployApiInfo () {
      try {
        this.hub.showProgress(true)
        this.message = '获取 API 配置中……'
        const rdata = await this.mjp.get2({
          url: '/rsi/deploy/api/info/',
          query: { nid: this.instance.nid },
          mjpType: 'admin'
        })
        this.message = '获取 API 配置完成。'
        this.vpc = rdata.vpc
        this.clb = rdata.clb
        this.apigwinfo = rdata.apigwinfo
        this.hub.showProgress(false)
      } catch (e) {
        this.message = '获取 API 配置错误，负载均衡和安全组不可用。'
        this.vpc = false
        this.clb = false
        this.apigwinfo = false
        this.hub.showProgress(false)
      }
    },
    deployApiAdd () {
      this.$buefy.dialog.confirm({
        title: '确认增加 API 配置？',
        message: '这是一个 <strong>异步任务</strong>，需要 10 秒以上，执行期间不要刷新页面。<br><p class="has-text-danger">这个任务可能会影响 API 访问，请尽量在访问量较小的时候执行。</p>',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            this.hub.showProgress(true)
            // 收起 apiContent
            this.$refs.apiContent.toggle()
            const rdata = await this.mjp.get2({
              url: '/rsi/deploy/api/add/',
              query: { nid: this.instance.nid },
              mjpType: 'admin'
            })
            this.message = `正在执行异步任务，taskid ${rdata.taskid}`
            this.taskType = 'api_add'
            // 查询异步任务的状态
            this.deployTaskStatus(rdata.taskid)
          } catch (e) {
            this.message = e
            this.hub.showProgress(false)
          }
        }
      })
    },
    deployApiDel () {
      console.log('deployApiDel apigwinfo: %o', this.apigwinfo)
      this.$buefy.dialog.confirm({
        title: '确认删除 API 配置？',
        message: '这是一个 <strong>异步任务</strong>，需要 10 秒以上，执行期间不要刷新页面。<br><p class="has-text-danger">这个任务可能会影响 API 访问，请尽量在访问量较小的时候执行。</p>',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            this.hub.showProgress(true)
            // 收起 apiContent
            this.$refs.apiContent.toggle()
            const rdata = await this.mjp.get2({
              url: '/rsi/deploy/api/del/',
              query: { nid: this.instance.nid },
              mjpType: 'admin'
            })
            this.message = `正在执行异步任务，taskid ${rdata.taskid}`
            this.taskType = 'api_del'
            // 查询异步任务的状态
            this.deployTaskStatus(rdata.taskid)
          } catch (e) {
            this.message = e
            this.hub.showProgress(false)
          }
        }
      })
    },
    deployUpdate () {
      this.$buefy.dialog.confirm({
        title: '确认更新实例？',
        message: '首次更新实例时，操作<strong>可能耗时 40 秒以上。</strong>',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            this.hub.showProgress(true)
            const res = await this.mjp.get2({
              url: '/rsi/deploy/update/',
              query: { nid: this.instance.nid, venv: this.venv, reload: this.reload, branch: this.branch },
              timeout: 60000
            })
            console.log(res)
            this.hub.showProgress(false)
            this.message = res.message
          } catch (e) {
            this.message = e
            this.hub.showProgress(false)
          }
        }
      })
    },
    deployReload () {
      this.$buefy.dialog.confirm({
        message: '确认重载实例？',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            this.hub.showProgress(true)
            const res = await this.mjp.get2({
              url: '/rsi/deploy/reload/',
              query: { nid: this.instance.nid },
              timeout: 60000
            })
            console.log(res)
            this.hub.showProgress(false)
            this.message = res.message
          } catch (e) {
            this.message = e
            this.hub.showProgress(false)
          }
        }
      })
    },
    deployStart () {
      this.$buefy.dialog.confirm({
        message: '确认启动实例？',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            this.hub.showProgress(true)
            const res = await this.mjp.get2({
              url: '/rsi/deploy/start/',
              query: { nid: this.instance.nid },
              timeout: 60000
            })
            this.hub.showProgress(false)
            this.message = res.message
          } catch (e) {
            this.message = e
            this.hub.showProgress(false)
          }
        }
      })
    },
    deployStop () {
      this.$buefy.dialog.confirm({
        message: '确认停止实例？',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            this.hub.showProgress(true)
            const res = await this.mjp.get2({
              url: '/rsi/deploy/stop/',
              query: { nid: this.instance.nid },
              timeout: 60000
            })
            console.log(res)
            this.hub.showProgress(false)
            this.message = res.message
          } catch (e) {
            this.message = e
            this.hub.showProgress(false)
          }
        }
      })
    },
    deployVenv () {
      this.$buefy.dialog.confirm({
        title: '确认更新虚拟环境？',
        message: '虚拟环境更新后必须重载实例才会生效。<br><strong>该操作可能耗时 10 秒以上。</strong>',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            this.hub.showProgress(true)
            const res = await this.mjp.get2({
              url: '/rsi/deploy/venv/',
              query: { nid: this.instance.nid },
              timeout: 60000
            })
            this.hub.showProgress(false)
            this.message = res.message
          } catch (e) {
            this.message = e
            this.hub.showProgress(false)
          }
        }
      })
    },
    async deployPipList () {
      try {
        this.hub.showProgress(true)
        let res = null
        let fmt = null
        if (this.pipOnlyShowOutdated) {
          res = await this.mjp.get2({
            url: '/rsi/deploy/pipoutdated/',
            query: { nid: this.instance.nid },
            timeout: 60000
          })
          fmt = item => `${item.name} ${item.version}/${item.latest_version}`
        } else {
          res = await this.mjp.get2({
            url: '/rsi/deploy/piplist/',
            query: { nid: this.instance.nid },
            timeout: 60000
          })
          fmt = item => `${item.name} ${item.version}`
        }
        this.hub.showProgress(false)
        const messages = res.piplist.map(fmt)
        messages.unshift(`共 ${messages.length} 个${this.pipOnlyShowOutdated ? '过期' : ''}模块:`)
        this.message = messages.join('\n')
      } catch (e) {
        this.message = e
        this.hub.showProgress(false)
      }
    },
    deployPipUpgrade () {
      const data = { nid: this.instance.nid }
      if (this.pipUpgradeAll) {
        data.all = true
      } else if (this.pipUpgradeList && this.pipUpgradeList.trim()) {
        const names = this.pipUpgradeList.trim().split(' ').map(s => s.trim())
        data.names = names
      } else {
        this.hub.alert('请提供要更新的模块名称。', 2)
        return
      }
      this.$buefy.dialog.confirm({
        title: '确认升级虚拟环境？',
        message: '虚拟环境更新后必须重载实例才会生效。<br><strong>该操作可能耗时 10 秒以上。</strong>',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            this.hub.showProgress(true)
            const res = await this.mjp.post2({
              url: '/rsi/deploy/pipupgrade/',
              data,
              timeout: 60000
            })
            this.hub.showProgress(false)
            this.message = res.message
          } catch (e) {
            this.message = e
            this.hub.showProgress(false)
          }
        }
      })
    }
  }
}
</script>

<style>

</style>
