<template>
<div id="cacheupdater">
  <button v-if="showVotype" class="button is-primary" @click="cacheUpdateVotype">刷新({{trueVotype}})缓存</button>
  <button v-if="showAll" class="button is-warning" @click="cacheUpdateAll">刷新所有缓存</button>
  <slot />
</div>
</template>

<script>
// 因为 mjpauth 可能有多个实例，需要同时更新多个实例的缓存

export default {
  name: 'cacheupdater',
  props: {
    showVotype: {
      type: Boolean,
      default: true
    },
    showAll: {
      type: Boolean,
      default: true
    },
    votype: {
      type: Number,
      default: 306
    }
  },
  computed: {
    trueVotype () {
      if (this.variantVotype !== null) {
        return this.variantVotype
      }
      return this.votype
    }
  },
  data () {
    return {
      variantVotype: null
    }
  },
  methods: {
    setVotype (votype) {
      this.variantVotype = votype
    },
    async cacheUpdateVotype () {
      try {
        await this.mjp.get('/cf/cache/updateothers/votype/', { votype: this.trueVotype }, null, 'admin')
        this.hub.alert('刷新成功！', 3, 'is-info')
      } catch (error) {
        console.error(error)
      }
    },
    async cacheUpdateAll () {
      try {
        await this.mjp.get('/cf/cache/updateothers/all/', null, null, 'admin')
        this.hub.alert('刷新成功！', 3, 'is-info')
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
