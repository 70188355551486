<template>
  <div>
    <help-rsikey class="markdown-body"/>
    <b-tabs position="is-centered" type="is-boxed" size="is-medium" v-model="tabIndex">
      <b-tab-item label="key列表">
        <b-field class="box" grouped position="is-centered">
          <b-select placeholder="key类型" v-model="status">
            <option value="loginkey">loginKey</option>
            <option value="secretkey">secretKey</option>
          </b-select>
        </b-field>
        <b-table :data="showData" ref="table" detailed detail-key="vid">
          <b-table-column #default="props" field="vid" label="vid" width="120" numeric centered>{{ props.row.vid}}</b-table-column>
          <b-table-column
            #default="props"
            field="name"
            label="name"
            width="120"
            numeric
            centered
          >{{ props.row.name}}</b-table-column>
          <b-table-column #default="props" label="操作">
            <div class="buttons">
              <button class="button is-danger" @click="ondeleteClick(props.row.vid)" :disabled="true">删除</button>
            </div>
          </b-table-column>
        </b-table>
      </b-tab-item>
      <b-tab-item label="增加">
        <section>
          <b-field  horizontal label="选择KEY">
            <b-select placeholder="key类型" v-model="saveData.key">
              <option value="loginkey">loginKey</option>
              <option value="secretkey">secretKey</option>
            </b-select>
          </b-field>
          <b-field horizontal label="key">
            <b-input v-model.number="saveData.value"></b-input>
          </b-field>
          <b-field horizontal label="名称">
            <b-input v-model.number="saveData.name"></b-input>
          </b-field>
          <b-field grouped position="is-centered">
            <button class="button is-danger" :disabled="!canSave" size="is-medium" @click="saveKey()" >保存</button>
          </b-field>
        </section>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import HelpRsikey from '@/help/rsikey.md'

export default {
  name: 'rsikey',
  components: { HelpRsikey },
  data () {
    return {
      tabIndex: 0,
      loginKey: [],
      secretKey: [],
      status: 'loginkey',
      saveData: {
        key: 'loginkey'
      }
    }
  },
  computed: {
    showData () {
      if (this.status === 'loginkey') {
        return this.loginKey
      } else {
        return this.secretKey
      }
    },
    canSave () {
      return this.saveData.value && this.saveData.name
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      const s = await this.mjp.get2({ url: '/rsi/instance/secretkey/get/all/' })
      const l = await this.mjp.get2({
        url: '/rsi/regional/loginkey/get/all/',
        timeout: 60000
      })
      this.secretKey = s.secretkeys
      this.loginKey = l.loginkeys
    },
    async saveKey () {
      try {
        const url =
          this.saveData.key === 'loginkey'
            ? '/rsi/regional/loginkey/add/'
            : '/rsi/instance/secretkey/add/'
        await this.mjp.post(url, this.saveData)
        this.$buefy.toast.open({
          message: '操作成功！',
          type: 'is-success'
        })
        this.getData()
        this.tabIndex = 0
      } catch (e) {
        console.log(e)
      }
    },
    async ondeleteClick (vid) {
      try {
        const url =
          this.status === 'loginkey'
            ? '/rsi/regional/loginkey/del/'
            : '/rsi/instance/secretkey/del/'
        this.$buefy.dialog.confirm({
          message: '确认删除？',
          type: 'is-danger',
          onConfirm: async () => {
            await this.mjp.post(url, { vid: vid })
            this.getData()
            this.$buefy.toast.open({
              message: '删除成功',
              type: 'is-success'
            })
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
