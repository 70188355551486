<template>
  <div v-if="show">
    <b-loading :is-full-page="isFullPage" :active.sync="show" :can-cancel="canCacel">
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin" />
    </b-loading>
    <b-message :type="color" has-icon>{{message}}</b-message>
  </div>
</template>

<script>
export default {
  created: function () {
    this.hub.$on('show-progress', this.showProgress)
  },
  data: function () {
    return {
      message: '载入中，请勿刷新页面...',
      color: 'is-danger',
      show: false,
      isFullPage: true,
      canCacel: false
    }
  },
  methods: {
    showProgress: function (evt) {
      this.show = evt.show
      if (!this.show) {
        return
      }
      this.message = evt.message || '载入中，请勿刷新页面...'
      this.color = evt.color || 'is-danger'
      this.canCacel = evt.canCacel === undefined ? false : evt.canCacel
      this.isFullPage = evt.isFullPage === undefined ? true : evt.isFullPage
    }
  }
}
</script>
