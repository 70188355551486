<template>
<b-field id="nicknamesearcher">
  <b-select v-model="idtype">
    <option value="invitecode">INVITECODE(游戏中显示ID)</option>
    <option value="sagiuid">SAGIUID(LID)</option>
    <option value="wxopenid">OPENID</option>
    <option value="wxunionid">UNIONID</option>
    <option v-if="includeNickName" value="nickname">昵称</option>
  </b-select>
  <b-input icon="account-heart" placeholder="输入搜索项" v-model.trim="idvalue" />
  <div class="controls">
    <button class="button is-success" :disabled="!idvalue" @click="onSearchNicknameClick">搜索</button>
  </div>
  <b-select icon="account" v-if="showSelect && targetUsers.length>0" v-model.number="targetUserIndex" placeholder="选择用户">
    <option v-for="(user, index) in targetUsers"
      :value="index"
      :key="user.uid">
      {{user.nickname}}({{user.sagiuid}})
    </option>
  </b-select>
  <div class="controls">
    <slot name="do"></slot>
  </div>
</b-field>
</template>

<script>
import cache from '@/core/cache'

export default {
  props: {
    showSelect: Boolean,
    // 昵称搜索消耗数据库资源，可能导致锁表影响登录。默认不显示。
    includeNickName: Boolean
  },
  data () {
    return {
      idvalue: null,
      idtype: 'invitecode',
      targetUsers: [],
      targetUserIndex: null
    }
  },
  methods: {
    getUser () {
      if (this.targetUsers.length > 0) {
        return this.targetUsers[this.targetUserIndex]
      }
      return null
    },
    clear () {
      // 还原索引设置
      this.targetUserIndex = null
      this.targetUsers = []
    },
    async onSearchNicknameClick () {
      this.clear()
      try {
        const r = cache.getR()
        if (r === null) {
          this.hub.alert('请先选择区服！')
          return
        }
        // 判断是否要搜索昵称
        if (this.idtype === 'nickname') {
          const data = await this.mjp.get2({
            url: '/user/search/',
            query: { nickname: this.idvalue, r },
            mjpType: 'auth'
          })
          this.targetUsers = data.users
        } else {
          const data = await this.mjp.get2({
            url: '/user/get/',
            query: { r, idvalue: this.idvalue, idtype: this.idtype },
            mjpType: 'auth'
          })
          this.targetUsers = [data.user]
        }
        this.$emit('search', this.targetUsers)
        this.targetUserIndex = 0
        this.idvalue = null
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    targetUserIndex (to, from) {
      if (this.showSelect && this.targetUsers.length > 0) {
        this.$emit('input', this.getUser())
      }
    }
  }
}
</script>
