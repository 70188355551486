<template>
<value-object id="mpmaterialnews"
  ref="vo"
  :votype="305"
  :refresh-route-path="['/mp/materialnews']"
  @tab-change="onTabChange">
  <template slot="top">
    <cache-updater :show-all="false" :votype="305" ref="cacheUpdater" />
  </template>
  <template slot="buttons" slot-scope="props">
    <button class="button is-warning" @click="onEditClick(props.row)">修改</button>
  </template>
  <template slot="tabitems">
    <b-tab-item label="增加">
      <material-config-editor ref="editorAsAdd" @edit="onEditSave" />
    </b-tab-item>
    <b-tab-item label="编辑"  :disabled="!inEdit">
      <material-config-editor ref="editorAsEdit" is-edit="true" @edit="onEditSave" />
    </b-tab-item>
  </template>
</value-object>
</template>

<script>
import ValueObject from '@/components/vo/ValueObject'
import MaterialConfigEditor from '@/components/vo/MaterialConfigEditor'
import CacheUpdater from '@/components/CacheUpdater'

export default {
  name: 'mpmaterialnews',
  components: { ValueObject, MaterialConfigEditor, CacheUpdater },
  data () {
    return {
      inEdit: false
    }
  },
  methods: {
    onTabChange ({ to, from }) {
      if (from === 2) {
        this.$refs.editorAsEdit.clearValues()
      }
      if (from === 1) {
        this.$refs.editorAsAdd.clearValues()
      }
      this.inEdit = to === 2
    },
    onEditClick (vo) {
      this.$refs.editorAsEdit.setValues(vo)
      this.$refs.vo.tabIndex = 2
    },
    async onEditSave (vo) {
      // 增加和编辑使用同一条协议
      try {
        vo.votype = 305
        await this.mjp.post('/cf/vo/set/', vo, { r: this.regional })
        this.$refs.vo.tabIndex = 0
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style>
#mpmaterialnews {
  padding: 12px;
}
</style>
