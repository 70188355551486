<template>
<div id="redeemcode">
  <b-field class="markdown-body">
    <help-redeem-code />
  </b-field>
  <b-tabs v-model="tabIndex" position="is-centered" type="is-boxed" size="is-medium">
    <b-tab-item label="已使用兑换码">
      <b-field class="markdown-body">
        <help-redeem-usage />
      </b-field>
      <b-field grouped group-multiline  position="is-centered" class="box">
        <b-datepicker
          placeholder="开始日期"
          v-model="rangeDates"
          :min-date="minDate"
          :max-date="maxDate"
          :loading="isLoading"
          icon="calendar-today"
          range
          @input="getRecord">
        </b-datepicker>
        <button class="button is-warning" @click="clear(); getRecord()">刷新所有兑换码</button>
      </b-field>
      <b-field grouped group-multiline  position="is-centered" class="box">
        <b-input v-model="configName" placeholder="通用配置名称"/>
        <nickname-searcher ref="nicknameselector" @input="onNicknameSelect" show-select />
        <button v-if="configName || selectedUser" class="button is-primary" @click="getRecord">{{ searchBtnName }}</button>
      </b-field>
      <br>
      <b-table :data="codes"
        paginated backend-pagination
        :total="total"
        :per-page="perPage"
        :current-page="currentPage"
        @page-change="onPageChange">
        <b-table-column #default="props" field="code" label="兑换码" width="100" numeric>
          {{ props.row.code }}
        </b-table-column>
        <b-table-column #default="props" field="sagiuid" label="SAGIUID" width="100" numeric>
          {{ props.row.sagiuid }}
        </b-table-column>
        <b-table-column #default="props" field="rvid" label="配置ID" width="100" numeric>
          {{ props.row.rvid }}
        </b-table-column>
        <b-table-column #default="props" field="createtime" label="创建时间" centered>
          {{ formatDateTime(props.row.createtime) }}
        </b-table-column>
        <template #bottom-left>
          <b>总计：</b>{{ total }}
        </template>
      </b-table>
    </b-tab-item>
    <b-tab-item label="创建兑换码">
      <b-field class="markdown-body" >
        <help-redeem-create />
      </b-field>
      <b-field grouped horizontal label="通用配置名称" >
        <b-input v-model="configName" placeholder="配置名称不包含 rxxxx_ 前缀" />
      </b-field>
      <b-field grouped horizontal label="生成参数" >
        <b-field label="生成数量" label-position="on-border">
          <b-numberinput v-model="amount" min="1" max="1000" />
        </b-field>
        <b-field label="表索引" label-position="on-border">
          <b-numberinput v-model="index" min="0" max="9" />
        </b-field>
        <b-field label="兑换码位数" label-position="on-border">
          <b-numberinput v-model="digit" min="5" max="9" />
        </b-field>
      </b-field>
      <b-field grouped position="is-centered" class="buttons">
          <button class="button is-warning" @click="onGenerate" :disabled="!configName">生成兑换码</button>
          <button class="button is-info" :disabled="!configName" @click="onDownload">下载累计生成的兑换码</button>
      </b-field>
      <b-field v-if="codesInLines" horizontal label="生成的兑换码">
        <textarea v-model="codesInLines" class="textarea" rows="10" />
      </b-field>
    </b-tab-item>
  </b-tabs>
</div>
</template>

<script>
import config from '@/core/config'
import cache from '@/core/cache'

import HelpRedeemCreate from '@/help/redeemcreate.md'
import HelpRedeemUsage from '@/help/redeemusage.md'
import HelpRedeemCode from '@/help/redeemcode.md'

import { fromNow } from '@/core/util'
import NicknameSearcher from '@/components/NicknameSearcher'

export default {
  name: 'redeemcode',
  components: { NicknameSearcher, HelpRedeemCreate, HelpRedeemUsage, HelpRedeemCode },
  mounted () {
    this.regional = cache.getR(true)
    this.getRecord()
  },
  computed: {
    searchBtnName () {
      if (this.selectedUser) {
        return '玩家兑换码'
      } else if (this.configName) {
        return '配置对应兑换码'
      }
      return '所有兑换码'
    },
    codesInLines () {
      if (this.codes && this.codes.length > 0) {
        return this.codes.join('\n')
      }
      return null
    }
  },
  data () {
    const user = cache.getUser()
    return {
      perPage: config.PER_PAGE,
      currentPage: 1,
      total: 0,
      user: user,
      codes: [],
      tabIndex: 0,
      regional: null,
      minDate: new Date(2018, 4, 12),
      maxDate: new Date(),
      rangeDates: [fromNow({ days: -30 }), new Date()],
      isLoading: false,
      configName: null,
      selectedUser: null,
      index: 0,
      amount: 10,
      digit: 6
    }
  },
  methods: {
    async onGenerate () {
      try {
        this.isLoading = true
        this.hub.showProgress(true)
        const data = { name: this.configName, digit: this.digit, amount: this.amount }
        if (this.index > 0) {
          data.index = this.index
        }
        const rdata = await this.mjp.post2({
          url: '/redeem/generate/',
          data,
          mjpType: 'prize'
        })
        this.codes = rdata.total_code_list
        this.hub.showProgress(false)
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        this.hub.showProgress(false)
      }
    },
    async onDownload () {
      const filterObj = {
        name: this.configName
      }
      if (this.index > 0) {
        filterObj.index = this.index
      }
      await this.mjp.download({
        url: '/redeem/download/',
        query: filterObj,
        mjpType: 'prize'
      })
    },
    onConfigNameSearch () {
      this.clear()
      this.getRecord()
    },
    onNicknameSelect (user) {
      this.selectedUser = user
    },
    onPageChange (page) {
      this.currentPage = page
      this.getRecord(page)
    },
    async getRecord (page) {
      if (this.isLoading) {
        return
      }
      this.tabIndex = 0
      page = page || this.currentPage || 1
      const filterObj = {
        page,
        per_page: this.perPage,
        from_date: this.formatDate(this.rangeDates[0]),
        to_date: this.formatDate(this.rangeDates[1])
      }
      let url = '/redeem/usage/get/more/'
      // 如果选择了玩家，就作为
      if (this.selectedUser) {
        url = '/redeem/usage/get/more/byuser/'
        filterObj.idvalue = this.selectedUser.sagiuid
        filterObj.idtype = 'sagiuid'
      } else if (this.configName) {
        filterObj.name = this.configName
      }
      try {
        // 避免 getRecord 被多次调用
        this.isLoading = true
        this.hub.showProgress(true)
        const data = await this.mjp.get2({
          url: url,
          query: filterObj,
          mjpType: 'prize'
        })
        this.isLoading = false
        this.hub.showProgress(false)
        this.codes = data.codes
        this.total = data.total
        this.perPage = data.per_page
        this.currentPage = data.page
      } catch (e) {
        this.isLoading = false
        this.hub.showProgress(false)
      }
    },
    clear () {
      this.codes = []
      this.total = 0
      this.currentPage = 1
      this.selectedUser = null
      this.$refs.nicknameselector.clear()
    }
  },
  watch: {
    tabIndex (to, from) {
      if (to === 0) {
        this.clear()
        this.configName = null
        this.getRecord()
      }
      if (to === 1) {
        this.clear()
        this.configName = null
      }
    }
  }
}
</script>

<style>
#redeemcode {
  padding: 12px;
}
</style>
