<template>
  <div id="rsirelation">
    <b-field class="markdown-body">
      <help-rsi-relation />
    </b-field>
    <b-tabs position="is-centered" type="is-boxed" size="is-medium" v-model="tabIndex">
      <b-tab-item :label="relationLabel">
        <section class="box">
          <b-field horizontal label="Regional" grouped>
            <b-select placeholder="Select Regional" v-model.number="selectData.r" @input="getData()" expanded>
              <option value="-1">全部</option>
              <option v-for="option in allR" :value="option.r" :key="option.r">
                {{ `${option.r}(${option.rname})` }}
              </option>
            </b-select>
            <b-input v-model="regionalFilter" placeholder="筛选 Regional"/>
          </b-field>
          <b-field horizontal label="Server" grouped>
            <b-select placeholder="Select Sid" v-model.number="selectData.sid" @input="getData()" expanded>
              <option value="-1">全部</option>
              <option v-for="option in allSid" :key="option.sid" :value="option.sid">
                {{ `${option.sid}(${option.sname})` }}
              </option>
            </b-select>
            <b-input v-model="serverFilter" placeholder="筛选 Server" />
          </b-field>
          <b-field horizontal label="Instance" grouped>
            <b-select placeholder="Select Nid" v-model.number="selectData.nid" @input="getData()" expanded>
              <option value="-1">全部</option>
              <option v-for="option in allNid" :key="option.nid" :value="option.nid">
                {{ `${option.nid}(${option.nname})` }}
              </option>
            </b-select>
            <b-input v-model="instanceFilter" placeholder="筛选 Instance" />
          </b-field>
          <b-field horizontal label="操作">
            <div class="buttons">
              <button class="button is-primary" @click="filterChange">筛选</button>
              <button class="button is-warning" @click="resetData()">重置</button>
            </div>
          </b-field>
        </section>
        <b-table :data="displayRelations" ref="table" :loading="isLoading">
          <b-table-column #default="props" numeric centered>{{ props.index+1}}</b-table-column>
          <b-table-column #default="props" field="rname" label="name" numeric centered>{{ props.row.rname}}</b-table-column>
          <b-table-column #default="props" field="bindkey" label="bindKey" numeric centered>
            {{ props.row.bindkey?props.row.bindkey:'-'}}</b-table-column>
          <b-table-column #default="props" field="regional" label="regional" numeric centered>{{ props.row.r}}</b-table-column>
          <b-table-column #default="props" field="nname" label="nName(nid)" numeric centered>
            {{ `${props.row.nname} (${props.row.nid})` }}</b-table-column>
          <b-table-column #default="props" field="ntype" label="nType" numeric centered>{{ props.row.ntype}}</b-table-column>
          <b-table-column #default="props" field="sname" label="sName(sid)" numeric centered>
            {{ `${props.row.sname} (${props.row.sid})` }}</b-table-column>
          <b-table-column #default="props" field="stype" label="sType" numeric centered>{{ props.row.stype}}</b-table-column>
          <b-table-column #default="props" label="操作" centered>
            <button class="button is-danger" @click="onDeleteClick(props.row)">删除</button>
          </b-table-column>
        </b-table>
      </b-tab-item>
      <b-tab-item label="增加">
        <section>
          <b-field horizontal label="Regional">
            <b-input v-model.number="addR" placeholder="输入 Regional（数字）" />
          </b-field>
          <b-field horizontal label="Instance" grouped>
            <instance-type-selector v-model="addNtype" @input="checkAddRegional" placeholder="选择 Instance 类型" />
            <b-select v-model="addNid" placeholder="选择 Instance">
              <option v-for="(item,id) in nids" :value="item.nid" :key="id">{{`${item.nid}(${item.name})`}}</option>
            </b-select>
          </b-field>
          <b-field horizontal label="Server" grouped position="is-centered">
            <b-select v-model="addStype" placeholder="选择 Server 类型" @input="checkAddRegional">
              <option v-for="(item,id) in ['mysql', 'redis']" :value="item" :key="id">{{item}}</option>
            </b-select>
            <b-select v-model="addSid" placeholder="选择 Server">
              <option v-for="(item,id) in sids" :value="item.sid" :key="id">{{`${item.sid}(${item.name})`}}</option>
            </b-select>
          </b-field>
          <b-field horizontal label="Bindkey" v-if="addStype">
            <b-input v-model="addBindkey"></b-input>
          </b-field>
          <b-field horizontal label="操作">
            <div class="buttons">
              <button class="button is-primary" :disabled="!canCheck" @click="checkAddRegional()">检测</button>
              <button class="button is-danger" :disabled="!canSave" @click="saveRelation()">保存</button>
            </div>
          </b-field>
        </section>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import cache from '@/core/cache'
import InstanceTypeSelector from '@/components/rsi/InstanceTypeSelector'

import HelpRsiRelation from '@/help/rsirelation.md'

export default {
  name: 'rsirelation',
  components: { InstanceTypeSelector, HelpRsiRelation },
  data () {
    return {
      tabIndex: 0,
      displayRelations: [],
      relationData: [],
      addR: cache.getR(),
      addStype: 'mysql',
      addNtype: 'auth',
      addSid: -1,
      addNid: -1,
      addBindkey: null,
      sids: [],
      nids: [],
      setData: {},
      selectData: {
        sid: -1,
        nid: -1,
        r: cache.getR() ? cache.getR() : -1
      },
      isLoading: false,
      regionalFilter: '',
      serverFilter: '',
      instanceFilter: ''
    }
  },
  watch: {
    $router: 'getParams'
  },
  computed: {
    relationLabel () {
      return `Relations(${this.displayRelations.length})`
    },
    canSave () {
      const rc = Number.isInteger(Number.parseInt(this.addR))
      return rc && this.addSid > -1 && this.addNid > -1
    },
    canCheck () {
      const rc = Number.isInteger(Number.parseInt(this.addR))
      const c = rc && this.addNtype && this.addStype
      return c
    },
    switchButton () {
      return this.addR === -1
    },
    selectNid () {
      if (this.addSid !== -1) {
        return this.relationData
      } else {
        return this.allNid
      }
    },
    allR () {
      const result = []
      const obj = {}
      for (const robj of this.displayRelations) {
        if (!obj[robj.r]) {
          result.push(robj)
          obj[robj.r] = true
        }
      }
      return result
    },
    allSid () {
      const result = []
      const obj = {}
      for (const robj of this.displayRelations) {
        if (!obj[robj.sid]) {
          result.push(robj)
          obj[robj.sid] = true
        }
      }
      return result
    },
    allNid () {
      const result = []
      const obj = {}
      for (const robj of this.displayRelations) {
        if (!obj[robj.nid]) {
          result.push(robj)
          obj[robj.nid] = true
        }
      }
      return result
    }
  },
  created () {
    this.getParams()
  },
  methods: {
    getParams () {
      console.log('query====', this.$route.query)
      if (this.$route.query.nid) {
        this.selectData.nid = this.$route.query.nid
        this.selectData.r = -1
      } else if (this.$route.query.sid) {
        this.selectData.sid = this.$route.query.sid
        this.selectData.r = -1
      }
      this.getData()
    },
    filterChange () {
      let filteredRelations = this.relationData.concat()
      if (this.regionalFilter !== '') {
        const r = Number.parseInt(this.regionalFilter.trim())
        filteredRelations = filteredRelations.filter(obj => {
          if (Number.isNaN(r)) {
            // 使用字符串模糊搜索
            return obj.rname.toLowerCase().includes(this.regionalFilter.toLowerCase().trim())
          }
          // 使用 r 数字模糊搜索
          return String(obj.r).includes(String(r))
        })
      }
      if (this.serverFilter !== '') {
        filteredRelations = filteredRelations.filter(obj => {
          return obj.sname.toLowerCase().includes(this.serverFilter.toLowerCase().trim())
        })
      }
      if (this.instanceFilter !== '') {
        filteredRelations = filteredRelations.filter(obj => {
          return obj.nname.toLowerCase().includes(this.instanceFilter.toLowerCase().trim())
        })
      }
      this.displayRelations = filteredRelations
    },
    resetData () {
      this.selectData.r = -1
      this.selectData.sid = -1
      this.selectData.nid = -1
      this.regionalFilter = ''
      this.serverFilter = ''
      this.instanceFilter = ''
      this.getData()
    },
    clearAddData () {
      this.addR = cache.getR()
      this.addStype = 'cvm'
      this.addNtype = 'auth'
      this.addSid = -1
      this.addNid = -1
      this.addBindkey = null
    },
    async getData () {
      try {
        this.isLoading = true
        const s = await this.mjp.get('/rsi/relation/get/all/', this.selectData)
        this.isLoading = false
        if (s.relations.length === 0) {
          this.$buefy.toast.open({
            duration: 5000,
            message: '无关联数据',
            position: 'is-bottom',
            type: 'is-danger'
          })
        } else {
          this.relationData = s.relations
          this.displayRelations = this.relationData.concat()
        }
      } catch (e) {
        console.log(e)
        this.isLoading = false
      }
    },
    selectAllRegional (e) {
      if (e) {
        this.selectData.r = -1
        this.getData()
      }
    },
    async saveRelation () {
      try {
        const res = await this.mjp.post('/rsi/relation/add/', {
          r: this.addR,
          ntype: this.addNtype,
          stype: this.addStype,
          nid: this.addNid,
          sid: this.addSid,
          bindkey: this.addBindkey
        })
        if (res.code === 200) {
          this.$buefy.toast.open({
            message: '操作成功！',
            type: 'is-success'
          })
          this.getData()
          this.clearAddData()
          this.tabIndex = 0
        }
      } catch (e) {
        console.log(e)
      }
    },
    async onDeleteClick (vid) {
      try {
        const p = {}
        p.r = vid.r
        p.sid = vid.sid
        p.nid = vid.nid
        this.$buefy.dialog.confirm({
          message: '确认删除？',
          type: 'is-danger',
          onConfirm: async () => {
            await this.mjp.post('/rsi/relation/del/', p)
            this.getData()
            this.$buefy.toast.open({
              message: '删除成功',
              type: 'is-success'
            })
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async checkAddRegional () {
      const s = await this.mjp.get('/rsi/server/get/all/', { stype: this.addStype })
      this.sids = s.servers
      const n = await this.mjp.get('/rsi/instance/get/all/', { ntype: this.addNtype, r: -1 })
      this.nids = n.instances

      // 清空已选
      this.addNid = -1
      this.addSid = -1
      this.addBindkey = null

      let r = Number.parseInt(this.addR)
      // 在获得了 r 之后检查 r 是否存在
      if (Number.isInteger(r)) {
        try {
          await this.mjp.get('/rsi/regional/get/', { r })
        } catch (e) {
          r = -1
        }
      }
      // 如果 r 是存在的，就获取 relations，根据 relations 进行当前项匹配
      if (r > 0) {
        try {
          this.isLoading = true
          const s = await this.mjp.get('/rsi/relation/get/all/', { r })
          this.isLoading = false
          for (const rel of s.relations) {
            if (rel.ntype === this.addNtype) {
              for (const nobj of this.nids) {
                if (nobj.nid === rel.nid) {
                  this.addNid = nobj.nid
                  break
                }
              }
            }
            if (rel.stype === this.addStype) {
              for (const sobj of this.sids) {
                if (sobj.sid === rel.sid) {
                  this.addSid = sobj.sid
                  this.addBindkey = rel.bindkey
                  break
                }
              }
            }
          }
        } catch (e) {
          this.isLoading = false
        }
      }
    }
  }
}
</script>
