<template>
<div id="ranklist">
  <b-field class="markdown-body">
    <help-rank-list />
  </b-field>
  <b-tabs v-model="tabIndex" position="is-centered" type="is-boxed" size="is-medium">
    <b-tab-item label="配置列表">
      <b-table :data="scs"
        detailed detail-key="name">
        <b-table-column #default="props" field="name" label="名称" width="120">
          {{ getRankName(props.row) }}
        </b-table-column>
        <b-table-column #default="props" field="max_score" label="最大值" width="180">
          {{ props.row.max_score }}
        </b-table-column>
        <b-table-column #default="props" field="total" label="总数" width="100">
          {{ props.row.total }}
        </b-table-column>
        <b-table-column #default="props" field="reversal" label="排序" width="120">
          {{ props.row.reversal ? '从大到小' : '从小到大' }}
        </b-table-column>
        <b-table-column #default="props" field="score_cast" label="分值" width="80">
          {{ props.row.score_cast }}
        </b-table-column>
        <b-table-column #default="props" field="clear_type" label="清榜" width="80">
          {{ clearTypes[props.row.clear_type] }}
        </b-table-column>
        <b-table-column #default="props" field="touch" label="最近更新">
          {{ formatDateTime(props.row.touch*1000) }}
        </b-table-column>
        <b-table-column #default="props" label="操作">
          <div class="buttons">
            <button class="button is-info" @click="onShowClick(props.row)">查看</button>
            <button class="button is-warning" @click="onRefreshClick(props.row)">刷新</button>
            <button class="button is-warning" @click="onEditClick(props.row)">编辑</button>
            <button class="button is-danger" @click="onClearClick(props.row)">清除</button>
            <button class="button is-danger" @click="onDeleteClick(props.row)">删除</button>
          </div>
        </b-table-column>
        <template #bottom-left>
          <b>总计：</b>{{ total }}
        </template>
        <template #detail="{row}">
          <p v-for="(v, k) in row" :key="k">{{k}}: {{v}}</p>
        </template>
      </b-table>
    </b-tab-item>
    <b-tab-item label="新增配置">
      <rank-list-editor ref="rlAdd" :is-edit="false" @save="onAddSave"/>
    </b-tab-item>
    <b-tab-item label="编辑配置" :disabled="tabIndex!==2">
      <rank-list-editor ref="rlEdit" :is-edit="true" @save="onEditSave"/>
    </b-tab-item>
  </b-tabs>
</div>
</template>

<script>
import config from '@/core/config'
import cache from '@/core/cache'
import RankListEditor from '@/components/RankListEditor'

import HelpRankList from '@/help/ranklist.md'

export default {
  name: 'ranklist',
  components: { RankListEditor, HelpRankList },
  mounted () {
    this.regional = cache.getR(true)
    this.getRecords()
  },
  data () {
    return {
      regional: null,
      total: 0,
      scs: [],
      tabIndex: 0,
      clearTypes: config.RANK_CLEAR_TYPES
    }
  },
  methods: {
    getRankName (row) {
      if (row.description) {
        return `${row.description}(${row.name})`
      }
      return row.name
    },
    async onShowClick (sc) {
      try {
        const rdata = await this.mjp.get('/rlist/get/byadmin/', { name: sc.name })
        this.$buefy.dialog.alert({
          title: this.getRankName(sc),
          message: rdata.rlist,
          type: 'is-info',
          size: 'is-small',
          hasIcon: false
        })
      } catch (error) {
        console.error(error)
      }
    },
    onRefreshClick (sc) {
      this.$buefy.dialog.confirm({
        message: '立即根据排行榜数据生成供客户端调用的排行榜。默认情况下，排行榜每小时会自动刷新一次。',
        onConfirm: async () => {
          try {
            await this.mjp.post('/store/refresh/', { name: sc.name })
            this.getRecords()
          } catch (error) {
            console.error(error)
          }
        },
        type: 'is-success'
      })
    },
    onClearClick (sc) {
      this.$buefy.dialog.confirm({
        title: '清空排行榜',
        message: '立即清除排行榜数据。默认情况下，排行榜自动根据配置进行清榜操作。',
        onConfirm: async () => {
          try {
            await this.mjp.post('/store/clear/', { name: sc.name })
            this.getRecords()
          } catch (error) {
            console.error(error)
          }
        },
        type: 'is-warning'
      })
    },
    onDeleteClick (sc) {
      this.$buefy.dialog.confirm({
        title: '删除排行榜',
        message: '立即清除排行榜数据，同时删除排行榜配置。',
        onConfirm: async () => {
          try {
            await this.mjp.post('/store/del/', { name: sc.name })
            this.getRecords()
          } catch (error) {
            console.error(error)
          }
        },
        type: 'is-danger'
      })
    },
    onEditClick (sc) {
      this.$refs.rlEdit.setValues(sc)
      this.tabIndex = 2
    },
    async onEditSave () {
      const sc = this.$refs.rlEdit.getValues()
      console.warn('sc %o', sc)
      try {
        await this.mjp.post('/store/set/', sc)
        this.tabIndex = 0
      } catch (error) {
        console.error(error)
      }
    },
    async onAddSave () {
      const sc = this.$refs.rlAdd.getValues()
      try {
        await this.mjp.post('/store/add/', sc)
        this.tabIndex = 0
      } catch (error) {
        console.error(error)
      }
    },
    async getRecords () {
      try {
        const params = {
          r: this.regional
        }
        const data = await this.mjp.get('/store/get/all/', params)
        this.scs = Object.values(data.scs)
        this.total = this.scs.length
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    tabIndex (to, from) {
      if (to === 0) {
        this.getRecords()
      }
      if (from === 2) {
        this.$refs.rlEdit.clearValues()
      }
      if (from === 1) {
        this.$refs.rlAdd.clearValues()
      }
    }
  }
}
</script>
