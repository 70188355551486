<template>
<div id="sendmail">
  <b-field horizontal label="邮件标题">
    <b-input v-model.trim="title" placeholder="请输入邮件标题"></b-input><b-switch v-model="sendToAll">发给所有人</b-switch>
  </b-field>
  <b-field horizontal label="邮件内容">
    <textarea class="textarea" v-model.trim="content" placeholder="请输入邮件内容" />
  </b-field>
  <b-field horizontal label="选择附件">
    <div class="columns">
      <b-select class="column" v-model="selectedRewardDefIndex" expanded @input="onRewardDefSelected">
        <option v-for="(o, index) in rewardsDef"
          :value="index"
          :key="index">
          {{o.name}}
        </option>
      </b-select>
      <div class="column" v-if="selectedRewardDef">
        <b-select v-if="selectedRewardDef.type==='list'" placeholder="选择附件值" v-model="selectedRewardValue" expanded>
          <option v-for="o in selectedRewardDef.list"
            :value="o.value"
            :key="o.key">
            {{o.name}}
          </option>
        </b-select>
        <b-input v-else placeholder="输入附件值" v-model.number="selectedRewardValue" expanded></b-input>
      </div>
      <div class="column" v-if="selectedRewardDef && selectedRewardDef.props">
        <b-input v-for="o in selectedRewardDef.props"
          :placeholder="o.name"
          :key="o.key"
          v-model.number="selectedRewardPropsValue[o.key]"
          expanded />
      </div>
      <div class="column is-one-quarter">
        <button class="button is-info" :disabled="!selectedRewardValue" @click="onAddReward">加入附件列表</button>
      </div>
    </div>
  </b-field>
  <b-field horizontal label="附件列表">
    <div class="columns">
      <b-select class="column is-three-quarters" multiple native-size="4" v-model="selectedRewards" expanded disabled>
        <option v-for="(o, index) in rewards"
          :value="index"
          :key="index">
          {{JSON.stringify(o)}}
        </option>
      </b-select>
      <div class="column">
        <button class="button is-warning" @click="rewards=[]">清空附件列表</button>
      </div>
    </div>
  </b-field>
  <b-field horizontal
    label="目标玩家列表"
    v-if="!sendToAll">
    <b-tabs v-model="curIdTabIndex" @change="onIDTypeChanged" position="is-centered" type="is-boxed" size="is-medium">
      <b-tab-item label="游戏服 UID">
        <textarea class="textarea" v-model.trim="UIDs" placeholder="输入UID，每行一个" />
      </b-tab-item>
      <b-tab-item label="SAGIUID(LID)">
        <div class="columns">
          <div class="column is-three-quarters">
            <div class="columns">
              <b-switch v-model="LIDInputByText">使用文本输入</b-switch>
              <nickname-searcher class="column is-two-thirds" ref="nicknameselector" @input="onNicknameSelect" idtype="nickname" show-select  />
            </div>
          </div>
          <div class="column">
            <button class="button is-info" :disabled="!selectedUser" @click="onAddLID">加入LID列表</button>
          </div>
        </div>
          <div class="columns">
            <div class="column is-three-quarters">
              <textarea v-if="LIDInputByText" class="textarea" v-model.trim="LIDs" placeholder="输入LID，每行一个" />
              <b-select v-else multiple native-size="4" expanded disabled v-model="selectedLIDs">
                <option v-for="o in selectedLIDs" :value="o" :key="o">
                    {{ o }}
                </option>
              </b-select>
            </div>
            <div class="column">
              <button class="button is-warning" @click="selectedLIDs=[];LIDs=null">清空LID列表</button>
            </div>
          </div>
      </b-tab-item>
    </b-tabs>
  </b-field>
  <button class="button is-danger" :disabled="!canSet" @click="onClick">设置</button>
</div>
</template>

<script>
import NicknameSearcher from '@/components/NicknameSearcher'
import config from '@/core/config'

export default {
  name: 'sendmail',
  components: { NicknameSearcher },
  computed: {
    canSet () {
      const s = this.title && this.content
      if (this.sendToAll) return s
      return s && this.getFillIDs()
    }
  },
  data () {
    return {
      rewardsDef: [
        { name: '奖杯', key: 'cup', type: 'input' },
        { name: '金币', key: 'coin', type: 'input' },
        { name: '钻石', key: 'diamond', type: 'input' },
        {
          name: '角色',
          key: 'actor',
          type: 'list',
          list: config.CHARACTERS,
          // 附加的属性，会合并进入最终的 rewards
          props: [
            { name: '使用时间', key: 'time', type: 'input' }
          ]
        },
        { name: '宝箱', key: 'slots', type: 'input' },
        { name: '体力', key: 'sp', type: 'input' },
        { name: '进化果实', key: 'fruit', type: 'input' }
      ],
      title: null,
      content: null,
      sendToAll: false,
      // 当前发送的 id 类型是 lid 还是 uid
      curIdType: 'uid',
      curIdTabIndex: 0,
      selectedUser: null,
      // 选择的奖励index
      selectedRewardDefIndex: null,
      // 选择的奖励实际配置
      selectedRewardDef: null,
      // 选择的奖励值（主要值）
      selectedRewardValue: null,
      // 选择的奖励值（属性值）
      selectedRewardPropsValue: null,
      rewards: [],
      // 输入的 UID 列表
      UIDs: null,
      // 输入的 LID 列表
      LIDs: null,
      // 使用 TextArea 输入 lid。默认使用 Select 输入
      LIDInputByText: false,
      // 选择的 LID 列表
      selectedLIDs: [],
      // 下面的这个 selected 没有实际作用，为了避免 b-select 没有 v-model 会报错
      selectedRewards: []
    }
  },
  methods: {
    onNicknameSelect (user) {
      if (user !== null) {
        this.selectedUser = user
      }
    },
    onRewardDefSelected (value) {
      this.selectedRewardValue = null
      this.selectedRewardDef = this.rewardsDef[value]
      if (!this.selectedRewardDef) return
      // 初始化 selectedRewardPropsValue 的值
      const props = this.selectedRewardDef.props
      if (props) {
        this.selectedRewardPropsValue = {}
        for (const prop in props) {
          this.selectedRewardPropsValue[prop.key] = null
        }
      } else {
        this.selectedRewardPropsValue = null
      }
    },
    onIDTypeChanged (index) {
      if (index === 1) {
        this.curIdType = 'lid'
      } else {
        this.curIdType = 'uid'
      }
      this.selectedLIDs = []
      this.UIDs = null
      this.LIDs = null
    },
    refresh () {
      this.rewards = []
      this.selectedRewardValue = null
      this.selectedRewardPropsValue = null
      this.UIDs = null
      this.selectedLIDs = []
      this.title = null
      this.content = null
      this.curIdType = 'uid'
      this.curIdTabIndex = 0
      this.selectedUser = null
      this.selectedRewardDefIndex = null
      this.selectedRewardDef = null
    },
    /**
     * 从 Textarea 中获取 ID
     */
    getTextIDs (text) {
      if (!text) return null
      const checkedIDs = []
      const ids = text.split(/\s+/)
      ids.forEach(item => {
        const id = Number.parseInt(item)
        // 排除重复ID和非数字ID
        if (!Number.isNaN(id) && !checkedIDs.includes(id)) {
          checkedIDs.push(id)
        }
      })
      if (checkedIDs.length === 0) {
        return null
      }
      return checkedIDs
    },
    getFillIDs () {
      if (this.curIdType === 'uid') {
        return this.getTextIDs(this.UIDs)
      } else if (this.LIDInputByText) {
        return this.getTextIDs(this.LIDs)
      }
      return this.selectedLIDs
    },
    onAddLID () {
      const lid = this.selectedUser.sagiuid
      console.log('onAddLID %s', lid)
      // console.log('includs %s', this.selectedLIDs.includes(lid))
      if (this.LIDInputByText) {
        if (this.LIDs === null) this.LIDs = ''
        this.LIDs += `\n${lid}`
      } else {
        if (!this.selectedLIDs.includes(lid)) {
          this.selectedLIDs.push(lid)
        }
      }
    },
    onAddReward () {
      const data = {}
      data[this.selectedRewardDef.key] = this.selectedRewardValue
      // 合并附加属性到 reward
      if (this.selectedRewardPropsValue) {
        for (const [key, v] of Object.entries(this.selectedRewardPropsValue)) {
          if (v) data[key] = v
        }
      }
      this.rewards.push(data)
    },
    onClick () {
      const data = {
        name: 'sendmail',
        title: this.title,
        content: this.content
      }
      // 在发给部分人的时候，才提供 lid/uid
      if (!this.sendToAll) {
        const ids = this.getFillIDs()
        if (!ids) {
          this.hub.alert('ID 必须是数字！', 6, 'is-danger')
          return
        }
        data[this.curIdType] = ids
      }
      if (this.rewards.length > 0) {
        data.reward = this.rewards
      }
      this.$emit('do', data)
    }
  }
}
</script>
