<template>
<div id="mpmenu">
  <b-field grouped class="box buttons" position="is-centered">
    <p> 修改菜单后，请先保存，然后点击“提交菜单”按钮。 </p>
    <a href="https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141013" target="_blank" class="button is-text">菜单文档</a>
  </b-field>
  <aside class="menu">
    <h1 class="title is-4 menu-label">
      公众号菜单
    </h1>
    <ul v-for="(o, i) in items" :key="i" class="menu-list">
      <li>
        <!-- 若有子菜单，显示为 is-info -->
        {{i+1}} <button class="button is-text"
          @click="onMenuClick(o, i)">{{o.name}}</button>
        <ul v-if="hasSubButton(o)">
          <li v-for="(o2, i2) in o.sub_button" :key="i+''+i2">
            {{i+1}}.{{i2+1}} <button class="button is-text"
              @click="onSubMenuClick(o2, i, i2)">{{o2.name}}</button>
          </li>
        </ul>
      </li>
    </ul>
  </aside>
  <div class="buttons is-centered">
    <button v-if="canAddMain"
      class="button is-dark"
      @click="onAddMenu">新增主菜单
      </button>
    <button v-if="canAddSub"
      class="button is-info"
      @click="onAddSubMenu">新增子菜单
    </button>
    <button v-if="canCancelMenu"
      class="button is-warning"
      @click="onCancelMenuSelect">取消选择
    </button>
  </div>
  <div class="buttons is-centered">
    <button v-if="items.length > 0"
      class="button is-success"
      @click="onSubmitMenu">提交菜单
    </button>
    <button
      class="button is-danger"
      @click="onClearMenu">清空菜单
    </button>
    <button
      class="button is-warning"
      @click="getRecord">刷新菜单
    </button>
  </div>
  <div class="container" v-if="aButton">
    <b-field v-if="clickButtonIndex !== null" horizontal label="当前菜单">
      <b-taglist attached>
        <b-tag type="is-dark">主菜单</b-tag>
        <b-tag type="is-success">{{clickButtonIndex+1}}</b-tag>
        <b-tag v-if="clickSubButtonIndex !== null" type="is-info">子菜单</b-tag>
        <b-tag v-if="clickSubButtonIndex !== null" type="is-success">{{clickButtonIndex+1}}.{{clickSubButtonIndex+1}}</b-tag>
      </b-taglist>
    </b-field>
    <b-field horizontal label="type">
      <b-select v-model="aButton.type" @input="onMenuTypeSelect" expanded>
        <option v-if="this.clickSubButtonIndex===null" value="MAIN">MAIN</option>
        <option v-for="(v, k) in menuTypes" :key="k" :value="v.type">{{k}}</option>
      </b-select>
    </b-field>
    <b-field horizontal label="name">
      <b-input name="menuName" v-model.trim="aButton.name" />
    </b-field>
    <template v-if="aButton && aButton.type && aButton.type !== 'MAIN'">
      <b-field v-for="o in menuTypes[aButton.type].props" :key="o" horizontal :label="o">
        <b-input name="o" v-model.trim="aButton[o]" />
      </b-field>
    </template>
    <b-field horizontal>
      <button class="button is-success" :disabled="!canSave" @click="onSaveMenu">保存</button>
      <button class="button is-danger" :disabled="!canDel" @click="onDelMenu">删除</button>
    </b-field>
  </div>
</div>
</template>

<script>
export default {
  name: 'mpmenu',
  created () {
    this.getRecord()
  },
  computed: {
    canSave () {
      if (this.aButton &&
          this.aButton.type &&
          this.aButton.name) {
        if (this.aButton.type === 'MAIN') return true
        const props = this.menuTypes[this.aButton.type].props
        if (props) {
          for (const p of props) {
            if (!this.aButton[p]) {
              return false
            }
          }
        }
        return true
      }
      return false
    },
    canDel () {
      return true
    },
    canAddMain () {
      if (this.isAdding) return false
      return this.items.length < 3
    },
    canAddSub () {
      if (this.isAdding) return false
      if (this.clickButtonIndex === null) return false
      const subButton = this.items[this.clickButtonIndex]
      if (subButton && subButton.length >= 5) return false
      return true
    },
    canCancelMenu () {
      return !this.isAdding && this.clickButtonIndex !== null && this.aButton !== null
    }
  },
  data () {
    return {
      items: [],
      // 是否正在增加新菜单
      isAdding: false,
      aButton: null,
      clickButtonIndex: null,
      clickSubButtonIndex: null,
      // 部分需要的菜单类型以及传递参数，需要完整的类型，详见：
      // https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141013
      menuTypes: {
        click: { type: 'click', props: ['key'] },
        view: { type: 'view', props: ['url'] },
        media_id: { type: 'media_id', props: ['media_id'] },
        view_limited: { type: 'view_limited', props: ['media_id'] },
        miniprogram: { type: 'miniprogram', props: ['appid', 'pagepath', 'url'] }
      }
    }
  },
  methods: {
    hasSubButton (button) {
      return button.sub_button && button.sub_button.length > 0
    },
    onMenuTypeSelect (select) {

    },
    onMenuClick (button, i) {
      this.isAdding = false
      this.clickButtonIndex = i
      this.clickSubButtonIndex = null
      this.aButton = this.items[this.clickButtonIndex]
      // 处理主菜单
      if (!this.aButton.type) {
        this.aButton.type = 'MAIN'
      }
    },
    onSubMenuClick (button, i, i2) {
      this.isAdding = false
      this.clickButtonIndex = i
      this.clickSubButtonIndex = i2
      const mainButton = this.items[this.clickButtonIndex]
      if (mainButton && mainButton.sub_button) {
        this.aButton = mainButton.sub_button[this.clickSubButtonIndex]
      } else {
        this.aButton = null
      }
    },
    onSaveMenu (evt) {
      // 保存菜单到内存
      this.isAdding = false
      if (!this.aButton) return
      if (this.clickSubButtonIndex === null) {
        // 增加主菜单
        this.items[this.clickButtonIndex] = this.aButton
      } else {
        // 增加子菜单
        const mainButton = this.items[this.clickButtonIndex]
        if (mainButton) {
          if (!mainButton.sub_button) {
            mainButton.sub_button = []
          }
          mainButton.sub_button[this.clickSubButtonIndex] = this.aButton
        }
      }
      this.onCancelMenuSelect(null)
    },
    onDelMenu (evt) {
      this.isAdding = false
      if (this.clickSubButtonIndex === null) {
        // 删除内存中的主菜单
        if (this.items.length > this.clickButtonIndex) {
          this.items.splice(this.clickButtonIndex, 1)
        }
      } else {
        // 删除内存中的子菜单
        const mainButton = this.items[this.clickButtonIndex]
        if (mainButton && mainButton.sub_button && mainButton.sub_button.length > this.clickSubButtonIndex) {
          mainButton.sub_button.splice(this.clickSubButtonIndex, 1)
        }
      }
      this.onCancelMenuSelect(null)
    },
    onCancelMenuSelect (evt) {
      this.aButton = null
      this.clickButtonIndex = null
      this.clickSubButtonIndex = null
      this.isAdding = false
    },
    onAddMenu (evt) {
      this.aButton = {}
      this.clickButtonIndex = this.items.length
      this.isAdding = true
    },
    onAddSubMenu (evt) {
      this.aButton = {}
      const subButton = this.items[this.clickButtonIndex].sub_button
      this.clickSubButtonIndex = subButton ? subButton.length : 0
      this.isAdding = true
    },
    onSubmitMenu (evt) {
      const cb = async () => {
        try {
          await this.mjp.post('/wxoa/menu/create/', { button: this.items })
          this.getRecord()
        } catch (error) {
          console.error(error)
          if (error.code === 502) {
            this.items = []
          }
        }
      }
      this.$buefy.dialog.confirm({
        title: '替换菜单',
        type: 'is-danger',
        message: '本操作将替换公众号的菜单，确定要操作吗？',
        onConfirm: cb
      })
    },
    onClearMenu (evt) {
      const cb = async () => {
        try {
          await this.mjp.get('/wxoa/menu/delete/')
          this.getRecord()
        } catch (error) {
          console.error(error)
        }
      }
      this.$buefy.dialog.confirm({
        title: '清空菜单',
        message: '本操作将清空公众号的所有菜单，确定要操作吗？',
        type: 'is-danger',
        onConfirm: cb
      })
    },
    async getRecord () {
      this.onCancelMenuSelect(null)
      try {
        const data = await this.mjp.get('/wxoa/menu/get/')
        if (data.menu && data.menu.button) {
          this.items = data.menu.button
        } else {
          this.items = []
        }
      } catch (error) {
        console.error(error)
        if (error.code === 502) {
          this.items = []
        }
      }
    }
  }
}
</script>

<style>
#mpmenu {
  padding: 12px;
}
</style>
