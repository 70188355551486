/**
 * 读取缓存中的内容
 */
class CacheManager {
  removeValue (k, permanent) {
    if (permanent === 'all') {
      this.removeValue(k, false)
      this.removeValue(k, true)
    } else {
      const storage = permanent ? localStorage : sessionStorage
      storage.removeItem(k)
    }
  }

  clear (permanent) {
    if (permanent === 'all') {
      this.clear(false)
      this.clear(true)
    } else {
      const storage = permanent ? localStorage : sessionStorage
      storage.clear()
    }
  }

  /**
   * 在 WebStorage 中保存值
   * @param k
   * @param v
   * @param permanent 为 true 则代表存在 localStorage 中
   * @returns {*}
   */
  setValue (k, v, permanent) {
    if (v === null || v === undefined) {
      this.removeValue(k, permanent)
      return null
    }
    const storage = permanent ? localStorage : sessionStorage
    let sv = null
    // 针对 object 和 array 使用 JSON
    if (typeof v === 'object') {
      sv = JSON.stringify(v)
    } else {
      sv = String(v)
    }
    storage.setItem(k, sv)
    return v
  }

  /**
   * 从 Storage 中获取值
   * @param {string} k
   * @param {boolean} permanent 为 true 则代表取 localStorage 中的值
   * @param {boolean} isJSONString 取到的值是 JSON 字符串，将其 parse
   * @param defaultValue 默认值
   * @returns {*}
   */
  getValue (k, permanent, isJSONString, defaultValue = null) {
    const storage = permanent ? localStorage : sessionStorage
    let sv = storage.getItem(k)
    // console.log('cache.getValue:%s', k, sv)
    if (isJSONString) {
      if (sv === null || sv === undefined) {
        return defaultValue
      }
      try {
        sv = JSON.parse(sv)
      } catch (e) {
        console.error(e)
        sv = defaultValue
      }
    }
    return sv || defaultValue
  }

  /**
   * 从缓存中获取 regional.r
   */
  getR () {
    const regional = this.getRegional()
    if (regional !== null) {
      return Number.parseInt(regional.r)
    }
    return null
  }

  /**
   * 从缓存中获取 regional 对象
   */
  getRegional () {
    const regional = this.getValue('regional', false, true)
    return regional
  }

  setRegional (regional) {
    this.setValue('regional', regional, false)
  }

  getAdminMJPIndex () {
    const mjpIndex = Number.parseInt(this.getValue('adminMJPIndex', true, false))
    if (Number.isNaN(mjpIndex)) return 0
    return mjpIndex
  }

  setAdminMJPIndex (mjpIndex) {
    this.setValue('adminMJPIndex', mjpIndex, true)
  }

  /**
   * 返回一个 MJP 地址
   * @param {string} typ 提供一个 mjp 类型，可用值 auth/mp/gs
   */
  getMJPURI (typ) {
    const mjpObj = this.getRegional()
    if (mjpObj && mjpObj.mjp) {
      return mjpObj.mjp[typ]
    }
    return null
  }

  /**
   * 获取 accesstoken，检测其是否过期
   */
  getAccessToken () {
    const user = this.getUser()
    if (user) {
      if (this.isExpire(user.expire)) {
        throw new RangeError('登录已经过期，请重新登录。')
      }
      console.log('accesstoken:%s', user.accesstoken)
      return user.accesstoken
    }
    return null
  }

  isExpire (expire) {
    // 提前300秒过期
    const ts = expire - 300
    if (new Date().getTime() > ts * 1000) {
      return true
    }
    return false
  }

  getUserType () {
    const user = this.getUser()
    return user ? user.usertype : null
  }

  isMe (id) {
    const user = this.getUser()
    if (user) {
      if (user.uid === id) {
        return true
      }
    }
    return false
  }

  checkUser (user) {
    if (user) {
      const expire = Number.parseInt(user.expire)
      const usertype = Number.parseInt(user.usertype)
      const avail = user.nickname &&
        user.username &&
        (!Number.isNaN(Number.parseInt(user.uid))) &&
        (!Number.isNaN(usertype)) &&
        (!Number.isNaN(expire)) &&
        user.accesstoken
      if (avail) {
        return usertype < 60 && expire > (new Date().getTime() / 1000)
      }
    }
    return false
  }

  setUser (user) {
    if (!user) {
      return null
    }
    const shrinkUser = {
      uid: user.uid,
      usertype: user.usertype,
      typename: user.typename,
      accesstoken: user.accesstoken,
      expire: user.expire,
      nickname: user.nickname,
      username: user.username
    }
    // 合并信息
    let userInLocal = this.getValue('user', true, true) || {}
    userInLocal = Object.assign(userInLocal, shrinkUser)
    // user 存入 local storage ，用于自动登录
    this.setValue('user', userInLocal, true)
    console.log('user local: %o', userInLocal)
    return user
  }

  /**
   * 获取当前缓存中的用户信息，并检测用户信息的正确性
   * @returns {*}
   */
  getUser () {
    const user = this.getValue('user', true, true)
    const avaiable = this.checkUser(user)
    return avaiable ? user : null
  }
}

export default new CacheManager()
