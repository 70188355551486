<template>
<div id="analypackprpos">
  <b-field grouped group-multiline>
    <b-select v-model="selectedProp" @input="onPropSelect" expanded>
      <option value="equip">携带</option>
      <option value="use">使用</option>
    </b-select>
    <date-quick @change="onDateQuick" expanded/>
    <b-datepicker
      placeholder="开始日期"
      v-model="fromDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar-today"
      expanded
      @input="onDateInput">
    </b-datepicker>
    <b-datepicker
      placeholder="截止日期"
      v-model="toDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar"
      expanded
      @input="onDateInput">
    </b-datepicker>
  </b-field>
  <horizontal-bar-chart :chart-data="chartData" :height="400"></horizontal-bar-chart>
</div>
</template>

<script>
import config from '@/core/config'
import cache from '@/core/cache'
import HorizontalBarChart from '@/components/charts/HorizontalBarChart'
import DateQuick from '@/components/DateQuick'
import { nextChartColor, fromNow, sortDate } from '@/core/util'

export default {
  name: 'analyprops',
  components: { HorizontalBarChart, DateQuick },
  mounted () {
    this.getRecord()
  },
  computed: {
    regional () {
      return cache.getR(true)
    }
  },
  data () {
    return {
      minDate: new Date(2018, 8, 1),
      maxDate: new Date(),
      fromDate: fromNow({ days: -7 }),
      toDate: new Date(),
      chartData: null,
      statData: null,
      isLoading: false,
      selectedProp: 'equip'
    }
  },
  methods: {
    getPropName (pid) {
      const prop = config.PACK_PROPS_WITH_KEY[pid]
      if (prop) return prop.name
      return pid
    },
    onDateQuick (date) {
      this.fromDate = date.fromDate
      this.toDate = date.toDate
    },
    onDateInput (date) {
      this.getRecord()
    },
    onPropSelect (select) {
      this.buildPropData()
    },
    buildChartData (labels, datasets) {
      labels = labels || []
      datasets = datasets || []
      return { labels, datasets }
    },
    // 创建各个要分析的属性数据的 dataset
    buildPropDataSets (labels, propsInStat, dataSetsObj) {
      const datasets = []
      for (let i = 0; i < propsInStat.length; i++) {
        const pid = propsInStat[i]
        const propName = this.getPropName(pid)
        const color = nextChartColor(i)
        const dataset = { label: `${propName}`, data: [], backgroundColor: color }
        for (const date of labels) {
          const day = dataSetsObj[date]
          dataset.data.push(day[pid] || 0)
        }
        datasets.push(dataset)
      }
      return datasets
    },
    buildPropData () {
      // 获得 propsId 的数组
      let propsInStat = {}
      // 留存图表的数据
      const dataSetsObj = {}
      this.statData.forEach(item => {
        if (!dataSetsObj[item.date]) {
          dataSetsObj[item.date] = {}
        }
        // console.log('dataSetObj[%s] %o', item.date, dataSetsObj[item.date])
        for (let [propId, propValue] of Object.entries(item.packprops[this.selectedProp])) {
          propId = Number.parseInt(propId)
          propsInStat[propId] = true
          dataSetsObj[item.date][propId] = Number.parseInt(propValue)
        }
      })
      // 其中的内容是 date 字符串
      const labels = sortDate(Object.keys(dataSetsObj))
      // 把 propsInstat 转换成 Array
      propsInStat = Object.keys(propsInStat).sort()

      const datasets = this.buildPropDataSets(labels, propsInStat, dataSetsObj)

      this.chartData = this.buildChartData(labels, datasets)
      console.log('buildPropData chartData:%o', this.chartData)
    },
    buildData (data) {
      // 留存表格的数据，需要把表格“横”过来
      const stat = data.stat
      this.statData = stat
    },
    clearRecord () {
      this.chartData = this.buildChartData()
      this.statData = null
    },
    async getRecord () {
      if (this.isLoading) {
        return
      }
      if (!this.hub.checkFilters([this.regional, '区服'])) {
        this.clearRecord()
        return
      }
      this.isLoading = true
      try {
        const data = await this.mjp.get('/analy/packprop/', {
          from_date: this.formatDate(this.fromDate),
          to_date: this.formatDate(this.toDate)
        })
        this.buildData(data)
        this.buildPropData()
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    }
  }
}
</script>

<style>
#analypackprops {
  padding: 12px;
}
</style>
