<template>
  <div id="ranklisteditor">
    <b-field horizontal label="full_name" v-if="isEdit">
      {{ fullName }}
    </b-field>
    <b-field horizontal label="最近更新" v-if="isEdit && touch">
      {{ formatDateTime(touch*1000) }}
    </b-field>
    <b-field horizontal label="name">
      <b-input name="name" v-model.trim="name" placeholder="英文+数字" :disabled="isEdit"></b-input>
    </b-field>
    <b-field horizontal label="介绍">
      <b-input name="description" v-model.trim="description" placeholder="中文可留空"></b-input>
    </b-field>
    <b-field horizontal label="格式">
      <b-select
        multiple
        native-size="6"
        v-model="userKeys">
        <option v-for="key in allUserKeys" :value="key" :key="key">{{key}}</option>
      </b-select>
    </b-field>
    <b-field horizontal label="排序">
      <b-switch v-model="reversal">{{reversal ? '从大到小' : '从小到大'}}</b-switch>
    </b-field>
    <b-field horizontal label="清榜">
      <b-radio v-model.number="clearType" v-for="(v, k) in clearTypes" :key="k" :native-value="k">{{v}}</b-radio>
    </b-field>
    <b-field horizontal label="分值类型">
      <b-radio v-model.number="scoreCast" native-value="int">int</b-radio>
      <b-radio v-model.number="scoreCast" native-value="float">float</b-radio>
    </b-field>
    <b-field horizontal label="最大值">
      <b-input v-model.number="maxScore" placeholder="允许保存的最大 score，最大为 2^32"></b-input>
    </b-field>
    <b-field horizontal label="最大步进">
      <b-input v-model.number="maxStepScore" placeholder="每次修改 score 时步进不能超过这个值，最大为 2^20。当原始 score 为 0 时，不检测这个值。"></b-input>
    </b-field>
    <b-field horizontal label="调用间隔(秒)">
      <b-input v-model.number="callInterval" placeholder="每次修改 score 的调用间隔，最小为1秒"></b-input>
    </b-field>
    <b-field horizontal label="显示总数">
      <b-input v-model.number="total" placeholder="客户端排行榜的显示条目总数，最大为 1000"></b-input>
    </b-field>
    <b-field horizontal label="上榜人数">
      <b-input v-model.number="recordNumber" placeholder="具有上榜资格的玩家数，大于等于显示总数。如果为空则默认为显示总数"></b-input>
    </b-field>
    <b-field horizontal label="榜单记录">
      <b-tooltip label="如果启用榜单记录功能，请填写榜单名">
        <b-switch v-model="recordListActive">{{recordListActive ? '已启用' : '未启用'}}</b-switch>
      </b-tooltip>
      <b-field horizontal v-if="recordListActive" label="记录榜单名">
        <b-input v-model.trim="recordListName" placeholder="请输入统计用榜单名（英文，驼峰命名）" expanded />
      </b-field>
    </b-field>
    <b-field horizontal label="记录日期组">
      <div class="columns">
        <div class="column">
          <b-field>
            <b-input v-model.trim="curSDGName" placeholder="请输入记录组的名称（支持英文和数字）" expanded />
            <b-tooltip label="注意：同时仅允许一个激活的记录组。">
              <b-switch :disabled="!curSDGName" v-model="curSDGActive" @input="onSDGActive">{{curSDGActive ? '已经激活' : '没有激活'}}</b-switch>
            </b-tooltip>
          </b-field>
          <b-input v-model.trim="curSDGDesc" placeholder="请输入记录组的说明（支持中文）" />
          <textarea class="textarea" rows=8 v-model.trim="curSDGDate" placeholder="每行一个日期，格式形如：20191001" />
          <b-field class="buttons" position="is-centered" grouped>
            <b-button :disabled="!canDelSDG" class="button is-warning" @click="onDelSDG">删除记录组</b-button>
            <b-button :disabled="!canAddSDG" class="button is-success" @click="onAddSDG">{{SDGLabel}}</b-button>
          </b-field>
        </div>
        <div v-if="savedDateGroup.length > 0" class="column">
          <b-select v-model="selectedSDGNames" @input="onSDGChange" multiple native-size="8" >
            <option v-for="g in savedDateGroup" :value="g.name" :key="g.name">{{g.name}}({{g.description}})</option>
          </b-select>
        </div>
      </div>
    </b-field>
    <b-field class="buttons" grouped position="is-centered">
      <button class="button is-danger" :disabled="!canSave" @click="$emit('save')">保存</button>
    </b-field>
  </div>
</template>

<script>
import config from '@/core/config'
import { DateTime } from 'luxon'

const ALL_USER_KEYS = ['invitecode', 'sagiuid', 'score', 'nickname', 'gender', 'headimg']

export default {
  name: 'ranklisteditor',
  props: {
    canSaveExtra: Boolean,
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    canSave () {
      return this.name &&
        this.callInterval !== null &&
        this.maxScore !== null &&
        this.maxStepScore !== null &&
        ['int', 'float'].includes(this.scoreCast) &&
        this.userKeys.length > 0 &&
        this.total !== null
    },
    canDelSDG () {
      return this.selectedSDGNames.length > 0
    },
    canAddSDG () {
      return this.curSDGName && this.curSDGDesc && this.curSDGDate
    },
    SDGLabel () {
      if (!this.curSDGName) {
        return '增加&更新记录组'
      }
      if (this.selectedSDGNames.includes(this.curSDGName)) {
        return '更新记录组'
      }
      return '增加记录组'
    }
  },
  data: function () {
    return {
      name: null,
      fullName: null,
      callInterval: null,
      clearType: 0,
      description: null,
      maxScore: null,
      maxStepScore: null,
      reversal: false,
      scoreCast: 'int',
      touch: 0,
      userKeys: ['invitecode', 'score', 'nickname', 'gender', 'headimg'],
      allUserKeys: ALL_USER_KEYS.concat(),
      total: null,
      savedDateGroup: [],
      selectedSDGNames: [],
      curSDGName: null,
      curSDGActive: false,
      curSDGDate: '',
      curSDGDesc: '',
      clearTypes: config.RANK_CLEAR_TYPES,
      recordNumber: null,
      recordListName: null,
      recordListActive: false

    }
  },
  methods: {
    dateValid (d) {
      return DateTime.fromFormat(d, 'yyyyMMdd').isValid
    },
    /**
     * 检测 SDG 是否合法
     */
    updateSavedGroupDate () {
      let actives = 0
      for (const g of this.savedDateGroup) {
        if (!/^\w+$/.test(g.name)) {
          return '名称必须为英文、数字或下划线！'
        }
        if (g.name === this.curSDGName) {
        }
        if (g.active) {
          actives++
        }
        for (const d in this.savedDateGroup.date) {
          if (!this.dateValid(d)) {
            return `日期 ${d} 不正确！`
          }
        }
      }
      if (actives > 1) {
        return '只允许一个记录组被激活'
      }
      return null
    },
    updateCurSDG (g) {
      // console.log('updateCurSDG %o', g)
      this.curSDGName = g.name
      this.curSDGActive = g.active
      this.curSDGDate = g.date.join('\n')
      this.curSDGDesc = g.description
    },
    /**
     * 检测一个 SDG 的合法性
     * 检测 name/date 并控制 active 只允许 1 个
     */
    checkSDG (g) {
      if (!/^\w+$/.test(g.name)) {
        return '名称必须为英文、数字或下划线！'
      }
      if (g.date.length === 0) {
        return '必须提供日期！'
      }
      // 当提供的 active 是 true 的时候，必须把其他已经保存的 active 设置为 false
      if (g.active) {
        for (const g2 of this.savedDateGroup) {
          g2.active = false
        }
      }
      return null
    },
    onDelSDG () {
      const names = this.selectedSDGNames.concat()
      // 删除当前选择的所有 SDG
      while (names.length > 0) {
        const name = names.pop()
        for (let i = 0; i < this.savedDateGroup.length; i++) {
          if (name === this.savedDateGroup[i].name) {
            this.savedDateGroup.splice(i, 1)
            break
          }
        }
      }
      this.selectedSDGNames = []
      this.clearCurSDG()
    },
    onAddSDG () {
      const dateList = this.curSDGDate.split('\n')
      for (const d of dateList) {
        if (!this.dateValid(d)) {
          this.hub.alert(`日期 ${d} 不正确！`, 2, 'is-warning')
          return
        }
      }
      console.log('savedDateGroup %o', this.savedDateGroup)
      let include = false
      for (const g of this.savedDateGroup) {
        console.log('g.name %s, %s', g.name, this.curSDGName)
        if (g.name === this.curSDGName) {
          // 更新已经存在的 SDG
          const succ = this.checkSDG(g)
          if (succ !== null) {
            this.hub.alert(succ, 2, 'is-warning')
            return
          }
          g.date = dateList
          g.description = this.curSDGDesc
          g.active = this.curSDGActive
          include = true
          break
        }
      }
      if (!include) {
        const g = {
          name: this.curSDGName,
          active: this.curSDGActive,
          date: dateList,
          description: this.curSDGDesc
        }
        // 新增一个 SDG
        const succ = this.checkSDG(g)
        if (succ !== null) {
          this.hub.alert(succ, 2, 'is-warning')
          return
        }
        this.savedDateGroup.push(g)
      }
    },
    onSDGChange (SDGList) {
      this.clearCurSDG()
      if (SDGList.length > 0) {
        for (const g of this.savedDateGroup) {
          // console.log('onSDGChange g.name %s %s', g.name, SDGList[0])
          if (g.name === SDGList[0]) {
            this.updateCurSDG(g)
            break
          }
        }
      }
    },
    /**
     * 若有一个 SDG.active 为真，则将其他所有的 SDG.active 置为 false
     */
    onSDGActive (active) {
      for (const g of this.savedDateGroup) {
        if (active) {
          g.active = false
        }
        if (g.name === this.curSDGName) {
          g.active = active
        }
      }
    },
    setValues (sc) {
      this.name = sc.name
      this.fullName = sc.full_name
      this.callInterval = sc.call_interval
      this.clearType = sc.clear_type
      this.description = sc.description
      this.maxScore = sc.max_score
      this.maxStepScore = sc.max_step_score
      this.reversal = sc.reversal
      this.scoreCast = sc.score_cast
      this.touch = sc.touch
      this.userKeys = sc.user_keys
      this.total = sc.total
      this.savedDateGroup = sc.saved_date_group || []
      this.curSDGName = null
      this.curSDGDesc = ''
      this.recordNumber = sc.record_number
      this.recordListName = sc.record_list_name
      this.recordListActive = sc.record_list_active
      // 显示激活的那个
      for (const g of this.savedDateGroup) {
        if (g.active) {
          this.updateCurSDG(g)
          break
        }
      }
    },
    getValues () {
      const updated = this.updateSavedGroupDate()
      if (updated !== null) {
        this.hub.alert(updated, 2, 'is-warning')
        return
      }
      const sc = {
        name: this.name,
        call_interval: this.callInterval,
        clear_type: this.clearType,
        description: this.description,
        max_score: this.maxScore,
        max_step_score: this.maxStepScore,
        reversal: this.reversal,
        score_cast: this.scoreCast,
        user_keys: this.userKeys,
        total: this.total,
        saved_date_group: this.savedDateGroup,
        record_number: this.recordNumber,
        record_list_name: this.recordListName,
        record_list_active: this.recordListActive
      }
      return sc
    },
    clearValues () {
      this.name = null
      this.fullName = null
      this.callInterval = null
      this.clearType = 0
      this.description = null
      this.maxScore = null
      this.maxStepScore = null
      this.reversal = false
      this.scoreCast = 'int'
      this.touch = 0
      this.userKeys = ['invitecode', 'score', 'nickname', 'gender', 'headimg']
      this.total = null
      this.savedDateGroup = []
      this.selectedSDGNames = []
      this.clearCurSDG()
      this.recordNumber = null
      this.clearRecordListName()
    },
    clearCurSDG () {
      this.curSDGName = null
      this.curSDGActive = false
      this.curSDGDate = ''
      this.curSDGDesc = ''
    },
    clearRecordListName () {
      this.recordListName = null
      this.recordListActive = false
    }
  }
}
</script>
