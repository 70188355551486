<template>
<div id="vo">
  <slot name="top"></slot>
  <b-tabs v-model="tabIndex" position="is-centered" type="is-boxed" size="is-medium">
    <b-tab-item label="配置列表">
      <b-table :data="items"
        paginated backend-pagination
        detailed detail-key="vid"
        :per-page="perPage"
        :total="total"
        :current-page="currentPage"
        @page-change="onPageChange">
        <b-table-column #default="props" field="status" label="状态" width="60">
          <b-tag :type="statusColor(props.row.status)"> {{ statusText(props.row.status) }}</b-tag>
        </b-table-column>
        <b-table-column #default="props" field="vid" label="VID" width="40" numeric>
          {{ props.row.vid }}
        </b-table-column>
        <b-table-column #default="props" field="name" label="中文名" width="150">
          {{ props.row.note ? (props.row.note.length > 10 ? props.row.note.slice(0, 10) : props.row.note) : '' }}
        </b-table-column>
        <b-table-column #default="props" field="name" label="name" width="300">
          <b-tag>{{ props.row.preName }}</b-tag>{{ props.row.name }}
          <div class="buttons is-inline">
            <b-tooltip :label="`复制name到剪贴板： ${props.row.name}`">
              <b-button
                :id="`copy-name-${props.row.vid}`"
                icon-right="clipboard-text"
                type="is-text"
                :data-clipboard-text="props.row.name"
                @click="onClipboardClick(`copy-name-${props.row.vid}`)" />
            </b-tooltip>
            <b-tooltip
              v-if="getConfigUrl(props.row)"
              :label="`复制URL到剪贴板: ${getConfigUrl(props.row)}`">
              <b-button
                :id="`copy-url-${props.row.vid}`"
                icon-right="link-plus"
                type="is-text"
                :data-clipboard-text="getConfigUrl(props.row)"
                @click="onClipboardClick(`copy-url-${props.row.vid}`)" />
            </b-tooltip>
          </div>
        </b-table-column>
        <b-table-column #default="props" field="votype" label="votype" width="100">
          {{ props.row.votype }}
        </b-table-column>
        <b-table-column #default="props" field="createtime" label="创建时间">
          {{ formatDateTime(props.row.createtime) }}
        </b-table-column>
        <b-table-column #default="props" field="updatetime" label="更新时间">
          {{ formatDateTime(props.row.updatetime) }}
        </b-table-column>
        <b-table-column #default="props" label="操作">
          <div class="buttons">
            <slot name="buttons" :row="props.row"></slot>
            <button class="button is-danger" @click="onDeleteClick(props.row)">删除</button>
          </div>
        </b-table-column>
        <template #bottom-left>
          <b>总计：</b>{{ total }}
        </template>
        <template #detail="{row}">
          <pre class="prewrap">{{ row.value }}</pre>
        </template>
      </b-table>
    </b-tab-item>
    <slot name="tabitems"></slot>
  </b-tabs>
</div>
</template>

<script>
/**
 * 通用的 ValueObject 界面，包括一个列表 TAB、一个编辑界面，一个新增界面
 * 使用 editorAsAdd 和 editorAsEdit 来指定编辑和新增界面
 */
import config from '@/core/config'
import cache from '@/core/cache'
import Clipboard from 'clipboard'

export default {
  name: 'vo',
  props: {
    // 指定一个 votype
    votype: {
      type: Number,
      default: 306
    },
    // 获取单个配置地址的前缀，默认为 306
    configUrlPrefix: {
      type: String,
      default: '/cf/vo/get/306/'
    },
    mjptype: {
      type: String,
      default: 'guess'
    },
    // 支持 toml 和 json
    valueType: {
      type: String,
      default: 'json'
    },
    refreshRoutePath: {
      type: Array,
      default: function () { return [] }
    },
    setUrl: {
      type: String,
      default: '/cf/vo/set/'
    },
    filterVos: {
      type: Function,
      default: function (vos) {
        vos.forEach(vo => {
          const [preName, newName] = vo.name.split('_')
          vo.preName = preName + '_'
          vo.trueName = vo.name
          vo.name = newName
        })
        return vos
      }
    }
  },
  mounted () {
    this.regional = cache.getR(true)
    this.getRecords()
  },
  computed: {
    trueVotype () {
      if (this.variantVotype !== null) {
        return this.variantVotype
      }
      return this.votype
    }
  },
  data () {
    return {
      tabIndex: 0,
      perPage: config.PER_PAGE,
      currentPage: 1,
      total: 0,
      items: [],
      regional: null,
      detailActive: false,
      // 可变的 votype ，可以被外部设置
      variantVotype: null
    }
  },
  methods: {
    onClipboardClick (btnid) {
      const c = new Clipboard(`#${btnid}`)
      c.on('success', e => {
        this.hub.alert(`已经复制 ${e.text} 到剪贴板。`, 1.5, 'is-success')
      })
      c.on('error', e => {
        this.hub.alert('复制失败啦！您自己解决吧。', 1.5, 'is-danger')
      })
    },
    setVotype (votype) {
      this.variantVotype = votype
    },
    getConfigUrl (row) {
      if (this.trueVotype !== 306) {
        return null
      }
      const baseUrl = this.mjp.getMJPURI(this.configUrlPrefix, this.mjptype, this.regional)
      const params = [`r=${this.regional}`, `name=${row.name}`]
      const url = `${baseUrl}${this.configUrlPrefix}?${params.join('&')}`
      return url
    },
    statusColor (status) {
      return status === 1 ? 'is-success' : 'is-danger'
    },
    statusText (status) {
      return status === 1 ? '启用' : '禁用'
    },
    async onDeleteClick (vo) {
      this.$buefy.dialog.confirm({
        title: '删除配置',
        type: 'is-danger',
        message: `是否要删除配置 ${vo.name} ？`,
        onConfirm: async () => {
          try {
            await this.mjp.post2({
              url: '/cf/vo/del/',
              data: { vid: vo.vid },
              mjpType: this.mjptype
            })
            this.hub.alert(`删除配置 ${vo.name} 成功！`, 5)
            this.getRecords()
          } catch (error) {
            console.warn(error)
          }
        }
      })
    },
    onPageChange (page) {
      this.currentPage = page
      this.getRecords(page)
    },
    async onEditSave (vo) {
      // 增加和编辑使用同一条协议
      try {
        // 如果指定了 votype，就使用指定的值
        vo.votype = this.trueVotype
        // 如果传递来的数据中包含了 valuetype ，就使用传来的值
        if (vo.valuetype === undefined) {
          // valueType 支持 toml 和 json
          vo.valuetype = this.valueType
        }
        await this.mjp.post2({
          url: this.setUrl,
          data: vo,
          query: { r: this.regional },
          mjpType: this.mjptype
        })
        this.tabIndex = 0
      } catch (error) {
        console.warn(error)
      }
    },
    async getRecords (page) {
      page = page || this.currentPage || 1
      try {
        const params = {
          r: this.regional,
          page: page,
          per_page: this.perPage,
          mergevo: 0,
          votype: this.trueVotype
        }
        const votype = Number.parseInt(this.trueVotype)
        if (!Number.isNaN(votype)) {
          params.votype = votype
        }
        const data = await this.mjp.get2({
          url: '/cf/vo/get/more/',
          query: params,
          mjpType: this.mjptype
        })
        this.items = this.filterVos(data.vos)
        this.currentPage = data.page
        this.perPage = data.per_page
        this.total = data.total
      } catch (error) {
        console.warn(error)
      }
    }
  },
  watch: {
    tabIndex (to, from) {
      if (to === 0) {
        this.getRecords()
      }
      this.$emit('tab-change', { to, from })
    }
  }
}
</script>
