<template>
  <div id="regionalselector" class="is-flex-tablet">
    <b-field>
      <b-tooltip label="例如可以输入 5 寻找所有 Regional 中包含 5 的游戏，也可以输入”微信“来得到所有微信游戏。" multilined type="is-dark" position="is-bottom">
        <b-input placeholder="R或游戏名，模糊搜索" expanded v-model="waitingForSearch" @input="doSearch"/>
      </b-tooltip>
    </b-field>
    <b-field v-if="showMjpType">
      <!-- <b-checkbox-button v-for="t in includeTypes" :key="t" v-model="mjpTypes" :native-value="t" @input="mjpTypeSelect" :type="mjpType2Color(t)">{{ mjpType2Name(t) }}</b-checkbox-button> -->
      <b-checkbox v-for="t in includeTypes" :key="t" v-model="mjpTypes" :native-value="t" @input="mjpTypeSelect" :type="mjpType2Color(t)">{{ mjpType2Name(t) }}</b-checkbox>
    </b-field>
  </div>
</template>
<script>
/**
 区服选择器组件
 */
import cache from '@/core/cache'
import type from '@/core/type'

export default {
  name: 'regionalselector',
  props: {
    showTypeFilter: {
      type: Boolean,
      default: true
    },
    includeTypes: {
      type: Array,
      default () {
        return [5000, 1000, 2000, 0]
      }
    }
  },
  computed: {
    showMjpType () {
      if (this.showTypeFilter) {
        return this.waitingForSearch === ''
      }
      return false
    }
  },
  data () {
    return {
      regionals: [],
      displayRegionals: [],
      mjpTypes: [5000],
      waitingForSearch: ''
    }
  },
  methods: {
    mjpType2Color (mjpType) {
      const mt = Number.parseInt(mjpType)
      if (mt === 1000) {
        return 'is-danger'
      }
      if (mt === 2000) {
        return 'is-primary'
      }
      if (mt === 5000) {
        return 'is-dark'
      }
      return 'is-info'
    },
    mjpType2Name (mjpType) {
      const mt = Number.parseInt(mjpType)
      if (mt === 1000) {
        return '测试服'
      }
      if (mt === 2000) {
        return '审核服'
      }
      if (mt === 5000) {
        return '正式服'
      }
      return '其他'
    },
    setMjpTypes (mjpTypes) {
      this.mjpTypes = mjpTypes
    },
    mjpTypeSelect (value) {
      this.updateDisplayRegionals()
    },
    updateDisplayRegionals () {
      if (this.regionals.length === 0) {
        this.displayRegionals = []
      } else if (this.waitingForSearch) {
        const r = Number.parseInt(this.waitingForSearch)
        this.displayRegionals = this.regionals.filter(obj => {
          let searched = false
          if (Number.isNaN(r)) {
            // 使用字符串模糊搜索
            searched = obj.name.toLowerCase().includes(this.waitingForSearch.toLowerCase().trim())
          } else {
            // 使用 r 数字模糊搜索
            searched = String(obj.r).includes(String(r))
          }
          // 检测是否属于 includeTypes
          if (searched) {
            const mjpType = type.r2MjpType(obj.r)
            return this.includeTypes.includes(mjpType)
          }
          return false
        })
      } else if (this.showTypeFilter) {
        this.displayRegionals = this.regionals.filter(robj => {
          let cond = false
          for (const mjpType of this.mjpTypes) {
            // console.log('mjpType %s, r %s', mjpType, robj.r)
            if (mjpType === 1000 && type.r2MjpType(robj.r) === 1000) {
              cond = true
              break
            } else if (mjpType === 2000 && type.r2MjpType(robj.r) === 2000) {
              cond = true
              break
            } else if (mjpType === 5000 && type.r2MjpType(robj.r) === 5000) {
              cond = true
              break
            } else if (mjpType === 0 && type.r2MjpType(robj.r) === null) {
              cond = true
              break
            }
          }
          return cond
        })
      } else {
        this.displayRegionals = this.regionals.concat()
      }
      this.$emit('select', this.displayRegionals)
    },
    /**
     * 获取 Regional 列表
     */
    async getRecords () {
      try {
        const rdata = await this.mjp.get2({
          url: '/cf/regional/all/',
          query: { mjst: cache.getAccessToken() },
          mjpType: 'admin'
        })
        this.regionals = rdata.regionals
        this.updateDisplayRegionals()
      } catch (e) {
        console.error(e)
      }
    },
    clear () {
      this.waitingForSearch = ''
    },
    doSearch (value) {
      this.updateDisplayRegionals()
    }
  }
}
</script>

<style>
</style>
