<template>
<div>
  <nav class="navbar" :class="bgClass" >
    <div class="navbar-brand">
      <div class="is-hidden-mobile navbar-item is-vertical-center">
        <img alt="SAGITEAM" src="@/assets/logo1.png">
      </div>
      <div class="is-hidden-tablet" style="display: block; width: 12px"></div>
      <div v-if="regional !== null" class="navbar-iterm is-vertical-center">
        <button class="button is-primary is-inverted is-outlined" @click="onRegionalClick">
          <b-icon icon="gamepad"></b-icon><span>{{ mjpSelectName }}</span>
        </button>
      </div>
    </div>
  </nav>
  <nav v-if="adminMJPServer && adminMJPServer.index > 0">
    <b-message type="is-danger" has-icon icon-size="is-small">
      正在使用 {{ adminMJPServer.name}}： {{ adminMJPServer.admapi}}
    </b-message>
  </nav>
  <nav id="breadcrumbNav" class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li><router-link to="/home">首页</router-link></li>
      <li v-if="curRouterName!=='首页'" class="is-active"><router-link :to="curRouterPath">{{curRouterName}}</router-link></li>
    </ul>
  </nav>
</div>
</template>

<script>
import cache from '@/core/cache'
import config from '@/core/config'
import { mjpType2Color, mjpType2Name } from '@/core/util'

export default {
  data () {
    return {
      regional: cache.getR(),
      bgClass: 'is-info',
      mjpSelectName: '选择区服',
      // 当前选择的 mjpadm，值为 config.adminMJPServers 中的一个
      adminMJPServer: null,
      curRouterName: this.$route.name,
      curRouterPath: this.$route.path
    }
  },
  created () {
    this.updateLogin()
    this.hub.$on('login-success', this.updateLogin)
    this.hub.$on('logout', this.onLogout)
    this.hub.$on('regional-change', this.onRegionalChange)
    this.hub.$on('admin-change', this.onRegionalChange)
    this.hub.$on('mjpadm-change', this.onAdminMJPChange)
  },
  destroyed () {
    this.hub.$off('login-success', this.updateLogin)
    this.hub.$off('logout', this.onLogout)
    this.hub.$off('regional-change', this.onRegionalChange)
    this.hub.$off('mjpadm-change', this.onAdminMJPChange)
  },
  methods: {
    checkPermit (...permits) {
      const typeid = cache.getUserType()
      // console.log('checkPermit, permit:%s, typeid:%s', permits, typeid)
      return typeid && permits.includes(typeid)
    },
    updateLogin () {
      // 首次启动的时候，不一定能收到 regioinalSelect 发出的变化事件
      this.onRegionalChange(cache.getR())
      const mjpadmIndex = cache.getAdminMJPIndex()
      this.onAdminMJPChange(config.getAdminMJP(mjpadmIndex))
    },
    onLogout () {
      this.mjpType = null
      this.regional = null
      this.bgClass = 'is-info'
      this.mjpSelectName = '点击主菜单游戏列表选择'
    },
    onAdminMJPChange (adminMJP) {
      this.adminMJPServer = adminMJP
    },
    onRegionalChange (regional) {
      // console.warn('onRegionalChange regional %s', regional)
      if (regional === null) {
        return
      }
      this.regional = regional
      // console.log('this.regional', this.regional)
      const mjpObj = cache.getRegional()
      // console.warn('onRegionalChange mjpObj %o', mjpObj)
      if (!mjpObj) {
        return
      }
      this.mjpType = mjpObj.mjptype
      this.bgClass = mjpType2Color(mjpObj.mjptype)
      this.mjpSelectName = `(${mjpObj.r})${mjpObj.name}`
      if (this.mjpType !== null) {
        this.mjpSelectName += '- ' + mjpType2Name(this.mjpType)
      }
    },
    async onRegionalClick () {
      try {
        const rdata = await this.mjp.get2({
          url: '/rsi/regional/get/',
          query: { r: this.regional }
        })
        console.warn('rData %o', rdata)
        this.$buefy.dialog.alert({
          title: `${rdata.regional.name}(${rdata.regional.r})`,
          message: `请使用 <strong>游戏列表</strong> 菜单切换游戏。<br><br>配置内容：<br><br><pre>${rdata.regional.value}</pre>`,
          type: 'is-info',
          size: 'is-small'
        })
      } catch (e) {
        console.log(e)
      }
    }
  },
  watch: {
    $route: function (to, from) {
      this.curRouterName = this.$route.name
      this.curRouterPath = this.$route.path
      // console.warn('to: %o', to)
      // console.warn('from: %o', from)
    }
  }
}
</script>

<style>
#breadcrumbNav {
  padding: 8px 12px;
}

.is-vertical-center {
  display: flex;
  align-items: center;
}
</style>
