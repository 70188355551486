<template>
  <b-field horizontal label="TOML 格式帮助">
    <b-collapse
      :open="isOpen"
      class="card"
      animation="slide"
      aria-id="tomlhelp">
      <div
        slot="trigger"
        slot-scope="props"
        class="card-header"
        role="button"
        aria-controls="tomlhelp">
        <p class="card-header-title">
          点击查看 TOML 文档，了解 ROOTLIST 用法
        </p>
        <a class="card-header-icon">
          <b-icon
            :icon="props.open ? 'menu-down' : 'menu-up'">
          </b-icon>
        </a>
      </div>
      <div
        class="card-content">
        <b-tabs position="is-centered" type="is-boxed">
          <b-tab-item label="ROOTLIST用法">
            <help-root-list />
          </b-tab-item>
          <b-tab-item label="TOML文档">
            <help-toml />
          </b-tab-item>
        </b-tabs>
      </div>
    </b-collapse>
  </b-field>
</template>

<script>
import HelpRootList from '@/help/rootlist.md'
import HelpToml from '@/help/toml.md'

export default {
  components: { HelpRootList, HelpToml },
  data () {
    return {
      showTomlHelp: false,
      isOpen: false
    }
  }
}
</script>
