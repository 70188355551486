/**
 * 作为第三方公共登录页面
 * @param type
 * @param redirecturl
 */
// 检测是否为第三方跳转登录
const commonLogin = {
  login (search) {
    console.log(search)
    let item
    const result = {}
    let state = false
    if (search.indexOf('&') > 0) {
      const splitArray = search.split('&')

      for (let i = 0; i < splitArray.length; i++) {
        item = splitArray[i].split('=')
        result[item[0]] = item[1]
      }
      localStorage.setItem('redirecturl', result.redirecturl)
      localStorage.setItem('status', '1')
      state = true
    }
    return state
  }
}

export default commonLogin
