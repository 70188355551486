<template>
<div id="analytics">
  <b-field grouped group-multiline class="box">
    <b-select v-model.number="selectedAnaly" @input="onAnalySelect" expanded>
      <option v-for="(o, i) in analyUrls" :key="i" :value="i">{{o.name}}</option>
    </b-select>
    <b-select v-if="isRetention" v-model="selectedRetention" @input="onRetentionSelect" expanded>
      <option v-for="(v, k) in retentionName" :key="k" :value="k">{{v}}</option>
    </b-select>
    <date-quick @change="onDateQuick" expanded/>
    <b-datepicker
      placeholder="开始日期"
      v-model="fromDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar-today"
      expanded
      @input="onDateInput">
    </b-datepicker>
    <b-datepicker
      placeholder="截止日期"
      v-model="toDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar"
      expanded
      @input="onDateInput">
    </b-datepicker>
    <button class="button is-primary" @click="downloadCSV">下载 CSV 文件</button>
  </b-field>
  <br>
  <b-field position="is-centered" grouped>
    <channel-selector2 ref="channelSelector" @change="onChannelChange"/>
  </b-field>
  <b-field v-if="!isRetention" position="is-centered" grouped>
    <p class="has-text-danger"><strong>总量：</strong> {{getHumenTotal(total)}}</p>
  </b-field>
  <line-chart :chart-data="chartData" :height="120"></line-chart>
  <b-table ref="table" :data="tableData">
    <b-table-column #default="props" field="cid" label="渠道ID" width="80" numeric>
      {{ props.row.channel }}
    </b-table-column>
    <b-table-column #default="props" field="name" label="渠道名" width="200">
      {{ props.row.name }}
    </b-table-column>
    <b-table-column #default="props" field="date" label="日期" width="120">
      {{ props.row.date }}
    </b-table-column>
    <b-table-column #default="props" field="num" label="数量" :visible="isNewActive">
      {{ props.row.num }}
    </b-table-column>
    <!-- 不再显示在线时长 -->
    <!-- <b-table-column #default="props" field="num" label="时长" :visible="isOnlineTime">
      {{ props.row.num }}秒 <b-tag type="is-success">{{getHumenTime(props.row.num, 'minutes')}}分</b-tag>
    </b-table-column> -->
    <b-table-column #default="props" field="base" label="基数" width="120" :visible="isRetention">
      {{ props.row.base }}
    </b-table-column>
    <b-table-column #default="props" field="day1" label="次日留存" width="120" :visible="isRetention">
      {{ getRetentionFixed(props.row.day1) }}
    </b-table-column>
    <b-table-column #default="props" field="day3" label="三日留存" width="120" :visible="isRetention">
      {{ getRetentionFixed(props.row.day3) }}
    </b-table-column>
    <b-table-column #default="props" field="day7" label="七日留存" width="120" :visible="isRetention">
      {{ getRetentionFixed(props.row.day7) }}
    </b-table-column>
    <b-table-column #default="props" field="day14" label="十四日留存" width="120" :visible="isRetention">
      {{ getRetentionFixed(props.row.day14) }}
    </b-table-column>
    <b-table-column #default="props" field="day30" label="三十日留存" width="120" :visible="isRetention">
      {{ getRetentionFixed(props.row.day30) }}
    </b-table-column>
  </b-table>
</div>
</template>

<script>
import { nextChartColor, fromNow } from '@/core/util'
import { Duration } from 'luxon'
import cache from '@/core/cache'
import LineChart from '@/components/charts/LineChart'
import DateQuick from '@/components/DateQuick'
import ChannelSelector2 from '@/components/channel/ChannelSelector2'

export default {
  name: 'analytics',
  components: { LineChart, ChannelSelector2, DateQuick },
  mounted () {
    this.$refs.channelSelector.getChannels()
  },
  data: function () {
    return {
      // 图表数据
      chartData: this.buildChartData(),
      selectedAnaly: 0,
      // 表格数据
      tableData: [],
      minDate: new Date(2018, 4, 12),
      maxDate: new Date(),
      fromDate: fromNow({ days: -7 }),
      toDate: new Date(),
      total: 0,
      isLoading: false,
      analyUrls: [
        { index: 0, url: '/analy/register/', name: '新增' },
        { index: 1, url: '/analy/active/', name: '活跃' },
        // { index: 2, url: '/analy/onlinetime/', name: '在线时长' },
        { index: 2, url: '/analy/register/retention/', name: '新增留存' },
        { index: 3, url: '/analy/active/retention/', name: '活跃留存' }
      ],
      retentionName: { 1: '次日留存', 3: '三日留存', 7: '七日留存', 14: '十四日留存', 30: '三十日留存' },
      selectedRetention: 1,
      // 数据中包含的 channel id
      channelsInStat: []
    }
  },
  computed: {
    selectedChannels () {
      const cs = this.$refs.channelSelector
      if (cs) {
        return cs.selectedChannels
      }
      return []
    },
    regional () {
      return cache.getR(true)
    },
    isNewActive () {
      // 是否为新增和活跃
      return [0, 1].includes(this.selectedAnaly)
    },
    // isOnlineTime () {
    //   // 是否为在线时间
    //   return this.selectedAnaly === 2
    // },
    isRetention () {
      // 是否为 留存信息
      // 取消了在线时长的显示，因此要把 index 往前移
      // return [3, 4].includes(this.selectedAnaly)
      return [2, 3].includes(this.selectedAnaly)
    }
  },
  methods: {
    getHumenTotal (num) {
      if (this.selectedAnaly === 2) {
        return this.getHumenTime(num, 'hours') + '小时'
      }
      return num
    },
    getHumenTime (seconds, unit) {
      if (seconds && unit) {
        const humenTime = Duration.fromMillis(seconds * 1000).as(unit)
        return humenTime.toFixed(1)
      } else {
        return '-'
      }
    },
    getRetentionFixed (num) {
      if (num === undefined) return '-'
      if (num === 0) return 0
      return num.toFixed(1) + '%'
    },
    getChannelName (cid) {
      if (this.$refs.channelSelector) {
        return this.$refs.channelSelector.channelsName[cid]
      }
      return ''
    },
    onRetentionSelect (reteneion) {
      // 在切换图表留存天数的时候重建数据。因为图表只能包含一种留存曲线
      this.buildRetentionChartData()
    },
    onAnalySelect (analy) {
      this.clearRecord()
      this.$refs.table.columns.splice(0)
      this.getRecord()
    },
    onDateQuick (date) {
      this.fromDate = date.fromDate
      this.toDate = date.toDate
    },
    onDateInput (date) {
      this.getRecord()
    },
    onChannelChange (channel) {
      this.getRecord()
    },
    getUrlObj () {
      const channnelParam = this.selectedChannels
      const urlObj = this.analyUrls[this.selectedAnaly]
      if (channnelParam.length === 0) {
        return [urlObj.url, null]
      }
      const filterObj = { channel: JSON.stringify(channnelParam) }
      if (this.fromDate) {
        filterObj.from_date = this.formatDate(this.fromDate)
      }
      if (this.toDate) {
        filterObj.to_date = this.formatDate(this.toDate)
      }
      if (this.isRetention) {
        filterObj.days = JSON.stringify(Object.keys(this.retentionName))
      }
      return [urlObj.url, filterObj]
    },
    sortChannelAndDate (a, b) {
      const channelComp = b.channel - a.channel
      if (channelComp === 0) {
        const atime = this.fromDateFormat(a.date).getTime()
        const btime = this.fromDateFormat(b.date).getTime()
        return btime - atime
      }
      return channelComp
    },
    buildChartData (labels, datasets) {
      labels = labels || []
      datasets = datasets || []
      return { labels, datasets }
    },
    buildData (data) {
      // 数据需要重新进行组合才能在图表中使用
      const stat = data.stat
      const dataSetsObj = {}
      let channels = {}
      let total = 0
      stat.forEach(item => {
        // 获得不重复的 channel
        channels[item.channel] = true
        if (!dataSetsObj[item.date]) {
          dataSetsObj[item.date] = {}
        }
        dataSetsObj[item.date][item.channel] = item.num
        item.name = this.getChannelName(item.channel)
        total += item.num
      })
      this.tableData = stat
      this.tableData.sort(this.sortChannelAndDate)
      this.total = total

      // 创建图表需要的数据
      const labels = Object.keys(dataSetsObj).sort()
      const datasets = []
      channels = Object.keys(channels)
      for (let i = 0; i < channels.length; i++) {
        const cid = channels[i]
        const dataset = { label: this.getChannelName(cid), data: [], borderColor: nextChartColor(i) }
        for (const label of labels) {
          const day = dataSetsObj[label]
          dataset.data.push(day[cid] || 0)
        }
        datasets.push(dataset)
      }
      this.chartData = { labels, datasets }
      console.log('chartData:%o', this.chartData)
    },
    buildRetentionChartData () {
      // 留存图表的数据
      const dataSetsObj = {}
      this.tableData.forEach(item => {
        if (!dataSetsObj[item.date]) {
          dataSetsObj[item.date] = {}
        }
        // 将数据按照日期，分开，保存选择的留存天数
        dataSetsObj[item.date][item.channel] = item[`day${this.selectedRetention}`]
      })
      // 其中的内容是 date 字符串
      const labels = Object.keys(dataSetsObj).sort()
      const datasets = []
      for (let i = 0; i < this.channelsInStat.length; i++) {
        const cid = this.channelsInStat[i]
        const dataset = { label: this.getChannelName(cid), data: [], borderColor: nextChartColor(i) }
        for (const date of labels) {
          const day = dataSetsObj[date]
          dataset.data.push(day[cid] || 0)
        }
        datasets.push(dataset)
      }
      this.chartData = this.buildChartData(labels, datasets)
      console.log('chartData:%o', this.chartData)
    },
    buildRetentionTableData (data) {
      // 留存表格的数据，需要把表格“横”过来
      const stat = data.stat
      const statWithChannelKey = {}
      stat.forEach(item => {
        item.name = this.getChannelName(item.channel)

        if (!statWithChannelKey[item.channel]) {
          statWithChannelKey[item.channel] = {}
        }
        if (!statWithChannelKey[item.channel][item.date]) {
          statWithChannelKey[item.channel][item.date] = {}
        }
        // 将所有的留存数据加入到一条记录中
        statWithChannelKey[item.channel][item.date][`day${item.day}`] = (item.num / item.base * 100)
        statWithChannelKey[item.channel][item.date].channel = item.channel
        statWithChannelKey[item.channel][item.date].name = item.name
        statWithChannelKey[item.channel][item.date].date = item.date
        statWithChannelKey[item.channel][item.date].base = item.base
      })
      // 用于表格的数据
      const statList = []
      Object.values(statWithChannelKey).forEach(channelItem => {
        Object.values(channelItem).forEach(dateItem => {
          statList.push(dateItem)
        })
      })
      // 倒序
      statList.sort(this.sortChannelAndDate)
      this.tableData = statList

      // 在这里获得最简单， buildRetentionChartData 需要这个值
      this.channelsInStat = Object.keys(statWithChannelKey)
    },
    clearRecord () {
      this.chartData = this.buildChartData()
      this.tableData = []
      this.channelsInStat = []
      this.total = 0
      this.selectedRetention = 1
    },
    async getRecord () {
      console.log('isLoading: %s', this.isLoading)
      if (this.isLoading) {
        return
      }
      if (!this.hub.checkFilters([this.regional, '区服'])) {
        this.clearRecord()
        return
      }
      const [url, filterObj] = this.getUrlObj()
      if (filterObj === null) {
        return
      }
      this.isLoading = true
      let data = null
      try {
        data = await this.mjp.get(url, filterObj)
        // 留存数据和其他数据采用不同的处理方法
        if (this.isRetention) {
          // 先处理一次 Table 数据
          this.buildRetentionTableData(data)
          // 再依赖  Table 数据得到图表数据
          this.buildRetentionChartData()
        } else {
          this.buildData(data)
        }
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },
    async downloadCSV () {
      const channnelParam = this.selectedChannels
      if (channnelParam.length === 0 || !this.fromDate || !this.toDate) {
        this.hub.alert('请选择渠道、开始日期和结束日期！')
        return
      }
      const filterObj = {}
      filterObj.channel = JSON.stringify(channnelParam)
      filterObj.from_date = this.formatDate(this.fromDate)
      filterObj.to_date = this.formatDate(this.toDate)
      await this.mjp.get('/analy/register/retention14/', filterObj, 'blob').then(data => {
        const blob = new Blob([data], { type: data.type })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        // 由于 api.js 没有传回 response 对象，无法得到 headers。因此在这里处理文件名
        // const contentDisposition = response.headers['content-disposition']
        // let fileName = 'unknown'
        // if (contentDisposition) {
        //   const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
        //   if (fileNameMatch.length === 2) {
        //     fileName = fileNameMatch[1]
        //   }
        // }
        link.setAttribute('download', `${filterObj.from_date}-${filterObj.to_date}.csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      })
    }
  }
}
</script>

<style>
</style>
