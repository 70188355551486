import cache from './cache'
import api from './api'

export default {
  saveTypes (types) {
    for (const typeid in types) {
      delete types[typeid].desc
    }
    return cache.setValue('types', types)
  },
  getTypeName (typeId) {
    const one = this.getOneType(typeId)
    if (one) {
      return one.name
    }
    return ''
  },
  getOneType (typeId) {
    return this.getAllTypes()[typeId]
  },
  getAllTypes () {
    const typesInCache = cache.getValue('types', false, true)
    return typesInCache
  },
  channelBodyTypes () {
    return this.filterTypes([212, 213, 214, 215])
  },
  channelUserTypes () {
    return this.filterTypes([221, 222, 223, 224, 225])
  },
  historyTypes () {
    return this.filterTypes([1001, 1002, 1003, 1004])
  },
  voTypes () {
    return this.filterTypes([301, 302, 303, 304, 305, 306, 307])
  },
  adminTypes () {
    return this.filterTypes([50, 51, 53, 55])
  },
  /** 小游戏平台的所有类型 */
  pfTypes (excludes) {
    let fts = [0, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413]
    if (Array.isArray(excludes)) {
      fts = fts.filter(i => !excludes.includes(i))
    }
    return this.filterTypes(fts)
  },
  /**
   * 从 regional 转换成 mjptype
   */
  r2MjpType (r) {
    if (r >= 1000 && r < 2000) {
      return 1000
    } else if (r >= 2000 && r < 3000) {
      return 2000
    } else if ((r > 0 && r < 1000) || r >= 5000) {
      return 5000
    }
    return null
  },
  filterTypes (start, end) {
    const result = []
    const typesInCache = this.getAllTypes()
    for (const typeId in typesInCache) {
      const typeObj = typesInCache[typeId]
      if (Object.prototype.hasOwnProperty.call(typesInCache, typeId) && typeObj) {
        let typeIdMatch = false
        if (typeof start === 'object') {
          typeIdMatch = start.includes(typeObj.typeid)
        } else {
          typeIdMatch = typeObj.typeid >= start && typeObj.typeid <= end
        }
        if (typeIdMatch) {
          result.push({ value: typeObj.typeid, text: typeObj.name, desc: typeObj.desc })
        }
      }
    }
    return result
  },
  needPreload () {
    return !this.getAllTypes()
  },
  async preload (accesstoken) {
    // 此时缓存中可能还没有写入 mjst ，因此要从参数传递过去
    const mjst = { mjst: accesstoken }
    // type 和 regional 必须在登录的时候获取并写入缓存，两个 API 指定调用 admin 的 API
    // 2019-07-27 zrong 不再保存 regionals 到缓存中
    const result = await Promise.all([
      api.get('/cf/type/all/', mjst, null, 'admin')
      // api.get('/cf/regional/all/', mjst, null, 'admin')
    ])
    this.saveTypes(result[0].types)
  }
}
