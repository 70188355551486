<template>
  <div id="releasenoteedit">
    <b-field horizontal label="版本号(x.y.z)">
      <b-input name="version" v-model.trim="version" placeholder="x.y.z" />
    </b-field>
    <b-field horizontal label="日期">
      <b-datepicker
        placeholder="版本发布日期"
        v-model="date"
        :min-date="new Date(2018, 4, 12)"
        :date-formatter="formatDate"
        icon="calendar">
      </b-datepicker>
    </b-field>
    <b-field horizontal label="生效时间点">
      <b-input name="startTime" v-model.trim="startTime" placeholder="06:00:00" />
    </b-field>
    <b-field horizontal label="失效时间点">
      <b-input name="endTime" v-model.trim="endTime" placeholder="08:00:00" />
    </b-field>
    <b-field horizontal label="发布内容">
      <textarea name="msg" rows="8" class="textarea" type="text" v-model.trim="msg"></textarea>
    </b-field>
    <b-field horizontal label="内容格式">
      <b-switch type="is-danger" v-model="useToml">{{useToml ? '使用 TOML' : '使用字符串'}}</b-switch>
    </b-field>
    <b-field horizontal label="状态">
      <b-switch v-model="statusBool">{{statusBool ? '启用' : '禁用'}}</b-switch>
    </b-field>
    <b-field horizontal label="备注">
      <b-input name="note" v-model.trim="note" placeholder="可留空"></b-input>
    </b-field>
    <b-field horizontal>
      <button class="button is-danger" :disabled="!canSave" @click="onSaveClick">保存</button>
    </b-field>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { checkTJValue } from '@/core/util'
import toml from '@iarna/toml'

export default {
  name: 'releasenoteedit',
  computed: {
    canSave () {
      const msgValid = this.msg && (this.useToml ? checkTJValue({ value: this.msg, valueType: 'toml' }) : true)
      const s = msgValid &&
        this.version &&
        /^\d+\.\d+\.\d+$/.test(this.version) &&
        this.date
      // 编辑状态下需要检验渠道ID
      if (this.isEdit) return s && this.vid
      return s
    }
  },
  // 编辑管理员则值为 true， 增加管理员则值为 false
  props: ['isEdit'],
  data: function () {
    return {
      vid: null,
      version: null,
      date: null,
      msg: null,
      statusBool: true,
      useToml: false,
      note: null,
      startTime: null,
      endTime: null
    }
  },
  methods: {
    setValues: function (vo) {
      this.vid = vo.vid
      this.version = vo.version
      this.date = DateTime.fromISO(vo.date).toJSDate()
      this.statusBool = vo.status === 1
      this.note = vo.note
      this.useToml = typeof vo.msg !== 'string'
      this.msg = this.useToml ? toml.stringify(vo.msg) : vo.msg
      this.startTime = vo.start_time
      this.endTime = vo.end_time
    },
    clearValues: function () {
      this.vid = null
      this.version = null
      this.date = null
      this.msg = null
      this.statusBool = true
      this.useToml = false
      this.note = null
      this.startTime = null
      this.endTime = null
    },
    onSaveClick () {
      const msg = this.useToml ? toml.parse(this.msg) : this.msg
      const vo = {
        vid: this.vid,
        ver: this.version,
        value: {
          version: this.version,
          msg,
          date: this.formatDate(this.date),
          // status 和 note 包含在 value 里面并没有写错，MJP 中做了特殊处理
          status: this.statusBool ? 1 : 5,
          note: this.note,
          startTime: this.startTime,
          endTime: this.endTime
        }
      }
      if (this.note) vo.note = this.note
      this.$emit('edit', vo)
    }
  }
}
</script>
