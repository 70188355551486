<template>
<div id="analydead">
  <b-field grouped group-multiline>
    <b-select v-if="mapKeys" v-model.number="selectedMap" @input="onMapSelect" expanded>
      <option v-for="map in mapKeys" :key="map.value" :value="map.value">{{ map.name }}</option>
    </b-select>
    <b-select v-model.number="selectedType" @input="onTypeSelect" expanded>
      <option value="games">场数/人数</option>
      <option value="avgtime">平均耗时</option>
      <option value="headshot">爆头率</option>
      <option value="reason">游戏结束原因</option>
    </b-select>
    <date-quick @change="onDateQuick" expanded/>
    <b-datepicker
      placeholder="开始日期"
      v-model="fromDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar-today"
      expanded
      @input="onDateInput">
    </b-datepicker>
    <b-datepicker
      placeholder="截止日期"
      v-model="toDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar"
      expanded
      @input="onDateInput">
    </b-datepicker>
  </b-field>
  <pie-chart v-if="pieData" :chart-data="pieData" />
  <!-- <horizontal-bar-chart v-if="barData" :chart-data="barData" /> -->
  <line-chart :chart-data="barData" :height="150" />
</div>
</template>

<script>
import cache from '@/core/cache'
import LineChart from '@/components/charts/LineChart'
import PieChart from '@/components/charts/PieChart'
import DateQuick from '@/components/DateQuick'
import { nextChartColor, fromNow, sortDate } from '@/core/util'

const PROP_TYPE = {
  games: {
    numGame: '场数',
    numPlayer: '人数',
    numWin: '胜利场数'
  },
  avgtime: {
    avgTime: '平均耗时'
  },
  headshot: {
    avgHeadAI: 'AI 的平均爆头',
    avgHeadPlayer: '玩家的平均爆头'
  },
  reason: {
    0: '选角界面',
    1: '认输',
    2: '切后台',
    3: '超过游戏时间',
    4: '血量为空',
    5: '掉落'
  }
}

const MIN_DATE = new Date(2018, 10, 9)

export default {
  name: 'analyprops',
  components: { PieChart, LineChart, DateQuick },
  mounted () {
    this.getRecord()
  },
  computed: {
    regional () {
      return cache.getR(true)
    }
  },
  data () {
    return {
      minDate: MIN_DATE,
      maxDate: new Date(),
      fromDate: fromNow({ days: -7 }, MIN_DATE),
      toDate: new Date(),
      barData: this.buildChartData(),
      pieData: this.buildChartData(),
      statData: null,
      isLoading: false,
      mapKeys: null,
      selectedType: 'games',
      selectedMap: null
    }
  },
  methods: {
    onDateQuick (date) {
      this.fromDate = date.fromDate
      this.toDate = date.toDate
    },
    onDateInput (date) {
      this.getRecord()
    },
    onMapSelect (select) {
      console.log('onMapSelect %s', select)
      this.buildDeadData()
    },
    onTypeSelect (select) {
      console.log('onTypeSelect %s', select)
      this.buildDeadData()
    },
    buildChartData (labels, datasets) {
      labels = labels || []
      datasets = datasets || []
      return { labels, datasets }
    },
    buildMapData (dataSetObj, rawMapDataOfOneDay) {
      const kv = PROP_TYPE[this.selectedType]
      if (!kv) return
      const isReason = this.selectedType === 'reason'
      const rawMapData = isReason ? rawMapDataOfOneDay.reason : rawMapDataOfOneDay
      // 找不到具体的 reason 数据，无法继续
      if (!rawMapData) {
        return Object.values(kv)
      }
      console.log('rawMapData %o', rawMapData)
      // 对 reason 需要特殊处理
      if (isReason) {
        for (const [k, v] of Object.entries(rawMapData)) {
          // 兼容老数据中的浮点数键名
          const k1 = k.split('.')
          if (k1.length > 1) {
            rawMapData[k1[0]] = v
          }
        }
      }
      for (const [k, v] of Object.entries(kv)) {
        dataSetObj[v] = rawMapData[k]
      }
      return Object.values(kv)
    },
    buildTypeData (dataSetsObj, propsInStat) {
      // 其中的内容是 date 字符串
      const labels = sortDate(Object.keys(dataSetsObj), true)
      const datasets = []
      if (Array.isArray(propsInStat)) {
        for (let i = 0; i < propsInStat.length; i++) {
          const pid = propsInStat[i]
          const color = nextChartColor(i)
          const dataset = { label: pid, data: [], borderColor: color }
          for (const date of labels) {
            const dataOfDay = dataSetsObj[date]
            const value = dataOfDay ? (dataOfDay[pid] || 0) : 0
            dataset.data.push(value)
          }
          datasets.push(dataset)
        }
      }
      this.barData = this.buildChartData(labels, datasets)
      console.log('buildTypeData barData:%o', this.barData)
    },
    buildDeadData () {
      // 留存图表的数据
      const dataSetsObj = {}
      let propsInStat = null
      this.statData.forEach(item => {
        if (!dataSetsObj[item.date]) {
          dataSetsObj[item.date] = {}
        }
        // console.log('dataSetObj[%s] %o', item.date, dataSetsObj[item.date])
        // console.log('item.data %o', item.data)
        const mapValue = this.selectedMap === 'total' ? item.data.total : item.data.hardnesses[this.selectedMap]
        if (mapValue) {
          propsInStat = this.buildMapData(dataSetsObj[item.date], mapValue)
        }
      })
      this.buildTypeData(dataSetsObj, propsInStat)
    },
    buildData (data) {
      // 留存表格的数据，需要把表格“横”过来
      const stat = data.stat
      this.statData = stat
      const mapK = {}
      // 获取 maps 键名
      this.statData.forEach(item => {
        for (const k of Object.keys(item.data.hardnesses)) {
          mapK[k] = true
        }
      })
      // 生成 mapKeys 的键值对
      this.mapKeys = [{ name: '全部', value: 'total' }]
      for (const k of Object.keys(mapK)) {
        this.mapKeys.push({ name: `难度${k}`, value: k })
      }
      this.selectedMap = 'total'
    },
    clearRecord () {
      this.barData = this.buildChartData()
      this.statData = null
      this.mapKeys = null
      this.selectedMap = null
    },
    async getRecord () {
      if (this.isLoading) {
        return
      }
      if (!this.hub.checkFilters([this.regional, '区服'])) {
        this.clearRecord()
        return
      }
      this.isLoading = true
      try {
        const data = await this.mjp.get2({
          url: '/analy/dead/',
          query: { from_date: this.formatDate(this.fromDate), to_date: this.formatDate(this.toDate) },
          mjpType: 'pa'
        })
        this.buildData(data)
        this.buildDeadData()
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    }
  }
}
</script>
