<template>
<div id="mptemsg">
  <b-field position="is-centered" class="box">
    <h1>发送订阅消息给玩家，支持 <strong>QQ/微信/字节</strong> 小游戏</h1>
    <a class="button is-text" href="https://q.qq.com/wiki/develop/game/server/open-port/port_subscrib.html#sendsubscriptionmessage" target="_blank">QQ小游戏通知模板消息文档</a>
    <a class="button is-text" href="https://developers.weixin.qq.com/minigame/dev/api-backend/open-api/subscribe-message/subscribeMessage.send.html#method-http" target="_blank">微信小游戏通知模板消息文档</a>
  </b-field>
  <hr />
  <b-field horizontal label="公众号配置名称">
    <b-input v-model="msgConfigName" placeholder="输入公众号配置中的名称，不含 r_ 前缀"/>
  </b-field>
  <b-field horizontal label="openid">
    <b-input v-model="msgOpenid" placeholder="输入玩家的 openid。" />
  </b-field>
  <b-field horizontal label="template_id">
    <b-input v-model="msgTplid" placeholder="输入模版 ID。" />
  </b-field>
  <b-field horizontal label="消息发到哪去">
      <b-radio v-model="platform"
                native-value="qq">
                QQ
      </b-radio>
      <b-radio v-model="platform"
          native-value="wechat">
          微信
      </b-radio>
      <b-radio v-model="platform"
          native-value="bytedance">
          字节
      </b-radio>
  </b-field>
  <b-field>
    <button class="button is-danger" :disabled="!canSend" @click="onSendMessage">发送消息</button>
  </b-field>
  <b-table :data="histories"
        paginated backend-pagination
        :total="total"
        :per-page="perPage"
        :current-page="currentPage"
        detailed detail-key="hid"
        @page-change="onPageChange">
    <b-table-column #default="props" field="hid" label="hid" width="100">
      {{ props.row.hid }}
    </b-table-column>
    <b-table-column #default="props" field="adminuid" label="adminuid" width="100">
      {{ props.row.adminuid }}
    </b-table-column>
    <b-table-column #default="props" field="nickname" label="管理员昵称" width="180">
      {{ props.row.nickname }}
    </b-table-column>
    <b-table-column #default="props" field="status" label="status" width="100">
      {{ props.row.status }}
    </b-table-column>
    <b-table-column #default="props" field="r" label="regional" width="100">
      {{ props.row.r }}
    </b-table-column>
    <b-table-column #default="props" field="typeid" label="typeid" width="80">
      {{ props.row.typeid }}
    </b-table-column>
    <b-table-column #default="props" field="note" label="说明">
      {{ props.row.note }}
    </b-table-column>
    <b-table-column #default="props" field="createtime" label="操作时间" width="200">
      {{ formatDateTime(props.row.createtime) }}
    </b-table-column>
    <template #bottom-left>
      <b>总计：</b>{{ total }}
    </template>
    <template #detail="{row}">
      <p v-for="(v, k) in row" :key="k">{{k}}: {{v}}</p>
    </template>
  </b-table>
</div>
</template>

<script>
/**
 * 发送模版消息给玩家
 */
import config from '@/core/config'

export default {
  name: 'mptemsg',
  mounted () {
    this.getRecords()
  },
  computed: {
    canSend () {
      return this.msgConfigName && this.msgTplid && this.msgOpenid
    }
  },
  data () {
    return {
      msgConfigName: null,
      msgOpenid: null,
      msgTplid: null,
      perPage: config.PER_PAGE,
      platform: 'wechat',
      currentPage: 1,
      histories: [],
      total: 0
    }
  },
  methods: {
    async getRecords (page) {
      page = page || this.currentPage || 1
      try {
        const filterobj = { page, per_page: this.perPage, alladmin: 1, typeid: 1001 }
        const rdata = await this.mjp.get2({
          url: '/admin/history/get/more/',
          query: filterobj,
          msgType: 'admin'
        })
        this.histories = rdata.histories
        this.total = rdata.total
        this.perPage = rdata.per_page
        this.page = rdata.page
      } catch (error) {
        console.error(error)
      }
    },
    onPageChange (page) {
      this.currentPage = page
      this.getRecords(page)
    },
    async onSendMessage () {
      // 根据平台进行判断
      let apiUrl = ''
      if (this.platform === 'qq') {
        apiUrl = '/qq2mini/subscribe/send/'
      } else if (this.platform === 'wechat') {
        apiUrl = '/wechatminiapp/subscribe/send/'
      } else if (this.platform === 'bytedance') {
        apiUrl = '/bytedance/subscribe/send/'
      } else {
        return
      }
      try {
        await this.mjp.post2({
          url: apiUrl,
          data: {
            config_name: this.msgConfigName,
            page: this.msgPage,
            openid: this.msgOpenid,
            template_id: this.msgTplid,
            platform: this.platform
          },
          mjpType: 'mp'
        })
        this.getRecords()
      } catch (e) {
        console.error(e)
      }
    }
  }
}

</script>

<style>
#mptemsg {
  padding: 12px;
}
</style>
