<template>
  <div>
    <b-field class="markdown-body">
      <help-rsi-instance-deploy v-if="tabIndex===3"  />
      <help-rsi-instance v-else />
    </b-field>
    <b-tabs @change="onTabChange" v-model="tabIndex" position="is-centered" type="is-boxed" size="is-medium">
      <b-tab-item label="Instance">
        <b-field grouped class="box" position="is-centered">
          <regional-selector ref="regionalSelector" @select="regionalSelect" :includeTypes="[5000,2000,1000]" />
          <b-select placeholder="Select Regional" @input="getRecords()" v-model.number="selectInstance.r">
            <option value="-1">全部 Regional</option>
            <option v-for="robj in regionals" :value="robj.r" :key="robj.r">
              {{ `(${robj.r})${robj.name}` }}
            </option>
          </b-select>
          <b-select v-model="instanceType">
            <option value="all">全部 Instance 类型</option>
            <option v-for="item in typeData" :key="item.ntype" :value="item.ntype">{{item.ntype}}</option>
          </b-select>
        </b-field>
        <b-table :data="showInstance" ref="table" detailed detail-key="nid">
          <b-table-column #default="props" field="nid" label="nid" width="60" numeric centered>{{ props.row.nid}}</b-table-column>
          <b-table-column #default="props" field="name" label="名称" width="200">{{ props.row.name}}</b-table-column>
          <b-table-column #default="props" field="sid" label="CVM">{{ getCVMLabel(props.row.sid) }}</b-table-column>
          <b-table-column #default="props" field="deploydir" label="部署路径" centered>{{ props.row.deploydir }}</b-table-column>
          <b-table-column #default="props" field="deployurl" label="url" centered>{{ props.row.deployuri }}</b-table-column>
          <b-table-column #default="props" field="secretkey" label="secretkey" centered>{{ props.row.secretkey }}</b-table-column>
          <b-table-column #default="props" field="ntype" label="类型" centered>{{ props.row.ntype }}</b-table-column>
          <b-table-column #default="props" field="status" label="状态" width="60" centered>
            <b-tag :type="props.row.status===1?'is-success':'is-light'">{{ props.row.status===1?'已启用':'已禁用'}}</b-tag>
          </b-table-column>
          <b-table-column #default="props" label="操作">
            <div class="buttons">
              <button class="button is-primary" @click="onDeployClick(props.row)" :disabled="props.row.status!==1">部署</button>
              <button class="button is-warning" @click="onEditClick(props.row)">修改</button>
              <button class="button is-danger" @click="onDeleteClick(props.row.nid)">删除</button>
              <button class="button is-info" @click="onRelationClick(props.row.nid)">Relation</button>
            </div>
          </b-table-column>
          <template #detail="props">
            <pre>{{props.row.value?props.row.value:'-'}}</pre>
          </template>
        </b-table>
      </b-tab-item>
      <b-tab-item label="增加">
        <section>
          <b-field horizontal label="名称" width="100">
            <b-input v-model="saveData.name" placeholder="必填"></b-input>
          </b-field>
          <b-field horizontal label="secretKey">
            <b-select v-model.number="saveData.secretkey">
              <option v-for="(v,k) in secretkey" :key="k" :value="v.vid">{{v.name}}</option>
            </b-select>
          </b-field>
          <b-field horizontal label="instance类型">
            <instance-type-selector v-model="saveData.ntype" />
          </b-field>
          <b-field horizontal label="CVM Server">
            <b-select v-model.number="saveData.sid">
              <option v-for="(v,k) in servers" :key="k" :value="v.sid">{{v.name}}({{v.sid}})</option>
            </b-select>
          </b-field>
          <b-field horizontal label="部署路径">
            <b-input v-model="saveData.deploydir" placeholder="必填"></b-input>
          </b-field>
          <b-field horizontal label="访问URL">
            <b-input v-model="saveData.deployuri" placeholder="必填"></b-input>
          </b-field>
          <b-field horizontal label="配置">
            <b-input v-model="saveData.value" type="textarea" rows="10" placeholder="TOML格式"></b-input>
          </b-field>
          <b-field horizontal label="状态" style="text-align:left">
            <b-switch v-model.number="saveData.status" true-value="1" false-value="5">{{saveData.status == 1?'启用':'禁用'}}
            </b-switch>
          </b-field>
          <b-field position="is-centered" grouped>
            <button center class="button is-danger" size="is-medium" @click="saveAdd()">保存</button>
          </b-field>
        </section>
      </b-tab-item>
      <b-tab-item label="编辑" :disabled="!inEdit">
        <section>
          <b-field horizontal label="nid" width="100">{{saveData.nid}}</b-field>
          <b-field horizontal label="名称" width="100">
            <b-input v-model="saveData.name"></b-input>
          </b-field>
          <b-field horizontal label="secretKey">
            <b-select v-model.number="saveData.secretkey">
              <option v-for="(v,k) in secretkey" :key="k" :value="v.vid">{{v.name}}</option>
            </b-select>
          </b-field>
          <b-field horizontal label="instance类型">
            <instance-type-selector v-model="saveData.ntype" />
          </b-field>
          <b-field horizontal label="CVM Server">
            <b-select v-model.number="saveData.sid">
              <option v-for="(v,k) in servers" :key="k" :value="v.sid">{{v.name}}({{v.sid}})</option>
            </b-select>
          </b-field>
          <b-field horizontal label="部署路径">
            <b-input v-model="saveData.deploydir"></b-input>
          </b-field>
          <b-field horizontal label="访问URL">
            <b-input v-model="saveData.deployuri"></b-input>
          </b-field>
          <b-field horizontal label="配置">
            <b-input v-model="saveData.value" rows="10" type="textarea" placeholder="TOML格式"></b-input>
          </b-field>
          <b-field horizontal label="状态" style="text-align:left">
            <b-switch v-model.number="saveData.status" true-value="1" false-value="5">{{saveData.status == 1?'启用':'禁用'}}
            </b-switch>
          </b-field>
          <b-field position="is-centered" grouped>
            <button center class="button is-danger" size="is-medium" @click="saveEdit()">保存</button>
          </b-field>
        </section>
      </b-tab-item>
      <b-tab-item label="Deploy" :disabled="!inDeploy">
        <Deploy ref="deploy" />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import toml from '@iarna/toml'
import cache from '@/core/cache'
import Deploy from '@/components/rsi/Deploy'
import InstanceTypeSelector from '@/components/rsi/InstanceTypeSelector'
import RegionalSelector from '@/components/RegionalSelector'

import HelpRsiInstance from '@/help/rsiinstance.md'
import HelpRsiInstanceDeploy from '@/help/rsiinstancedeploy.md'

export default {
  name: 'rsiinstance',
  components: { Deploy, InstanceTypeSelector, RegionalSelector, HelpRsiInstance, HelpRsiInstanceDeploy },
  data () {
    return {
      instanceData: [],
      regionals: [],
      tabIndex: 0,
      inEdit: false,
      inDeploy: false,
      saveData: {
        status: 1,
        ntype: 'auth'
      },
      instanceTypes: ['auth', 'tool', 'cache', 'rank', 'pa', 'mp', 'hlw', 'stats', 'prize'],
      instanceType: 'all',
      secretkey: [],
      servers: [],
      serversKV: {},
      selectInstance: {
        r: cache.getR() ? cache.getR() : -1
      }
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    tabIndex (to) {
      if (to === 0) {
        this.saveData = {
          status: 1,
          ntype: 'auth'
        }
      }
    }
  },
  computed: {
    showInstance () {
      if (this.instanceData.length === 0) {
        return []
      }
      return this.instanceData.filter(robj => {
        if (this.instanceTypes.includes(this.instanceType)) {
          return robj.ntype === this.instanceType
        }
        return robj
      })
    },
    typeData () {
      const result = []
      const obj = {}
      for (const i in this.instanceData) {
        if (!obj[this.instanceData[i].ntype]) {
          result.push(this.instanceData[i])
          obj[this.instanceData[i].ntype] = true
        }
      }
      return result
    }
  },
  methods: {
    init () {
      this.$refs.regionalSelector.getRecords()
      this.getRecords()
    },
    testToml (t) {
      try {
        toml.parse(t)
        return true
      } catch (e) {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'TOML格式错误',
          position: 'is-bottom',
          type: 'is-danger'
        })
        console.log(e)
        return false
      }
    },
    getCVMLabel (sid) {
      if (this.serversKV) {
        if (this.serversKV[sid]) {
          return `${this.serversKV[sid].name}(${sid})`
        }
      }
      return 'N/A'
    },
    regionalSelect (regionalList) {
      this.regionals = regionalList
    },
    async getRecords () {
      try {
        this.hub.showProgress(true)
        const results = await this.mjp.get('/rsi/instance/get/all/', this.selectInstance)
        this.hub.showProgress(false)
        const k = await this.mjp.get('/rsi/instance/secretkey/get/all/')
        this.secretkey = k.secretkeys
        const serversResult = await this.mjp.get2({
          url: '/rsi/server/get/all/',
          query: { stype: 'cvm' },
          mjpType: 'admin'
        })
        this.servers = serversResult.servers
        // 用 sid 作为 key，方便查找
        this.serversKV = {}
        for (const server of this.servers) {
          this.serversKV[server.sid] = server
        }
        this.instanceData = results.instances
      } catch (e) {
        console.log(e)
        this.hub.showProgress(false)
      }
    },
    async onEditSave (url, s) {
      try {
        await this.mjp.post(url, s)
        this.$buefy.toast.open({
          message: '操作成功！',
          type: 'is-success'
        })
        this.freshData()
      } catch (e) {
        console.log(e)
      }
    },
    checkSaveData () {
      return this.saveData.name &&
        this.saveData.status &&
        this.saveData.value &&
        this.saveData.ntype &&
        this.saveData.sid &&
        this.saveData.deploydir &&
        this.saveData.deployuri &&
        this.saveData.secretkey
    },
    saveAdd () {
      if (!this.checkSaveData()) {
        this.hub.alert('请将参数填写完整！')
        return
      }
      if (this.testToml(this.saveData.value)) {
        this.onEditSave('/rsi/instance/add/', this.saveData)
      }
    },
    onDeleteClick (s) {
      try {
        this.$buefy.dialog.confirm({
          message: '确认删除？',
          type: 'is-danger',
          onConfirm: async () => {
            await this.mjp.post('/rsi/instance/del/', {
              nid: s
            })
            this.freshData()
            this.$buefy.toast.open({
              message: '删除成功',
              type: 'is-success'
            })
          }
        })
      } catch (e) {
        console.log(e)
        this.$buefy.toast.open({
          message: '删除失败',
          type: 'is-danger'
        })
      }
    },
    onEditClick (data) {
      this.saveData = data
      this.tabIndex = 2
      this.inEdit = true
    },
    saveEdit () {
      if (this.saveData.value) {
        if (this.testToml(this.saveData.value)) {
          this.onEditSave('/rsi/instance/edit/', this.saveData)
        }
      } else {
        this.onEditSave('/rsi/instance/edit/', this.saveData)
      }
    },
    onTabChange (to) {
      if (to !== 2) {
        this.inEdit = false
      }
      if (to !== 3) {
        this.inDeploy = false
        this.$refs.deploy.setInstance(null)
      }
    },
    freshData () {
      this.init()
      this.tabIndex = 0
    },
    onDeployClick (row) {
      row.serverLabel = this.getCVMLabel(row.sid)
      this.$refs.deploy.setInstance(row)
      this.inDeploy = true
      this.tabIndex = 3
    },
    onRelationClick (nid) {
      this.$router.push({
        path: '/relation',
        query: {
          nid: nid
        }
      })
    }
  }
}
</script>
