<template>
<div id="root">
  <app-header />
  <div>
    <global-progress />
  </div>
  <div id="main" class="columns is-desktop">
    <div v-if="isLogin" class="column is-narrow">
      <app-menu ref="appMenu" class="box" style="width: 250px" />
    </div>
    <router-view class="column"/>
  </div>
  <app-footer />
</div>
</template>

<script>
// import CheckChrome from '@/components/CheckChrome'
import GlobalProgress from '@/components/GlobalProgress'
import AppHeader from '@/components/AppHeader'
import AppMenu from '@/components/AppMenu'
import AppFooter from '@/components/AppFooter'

import cache from '@/core/cache'

export default {
  components: {
    AppHeader,
    AppMenu,
    AppFooter,
    // CheckChrome,
    GlobalProgress
  },
  data () {
    return {
      openInWechat: false,
      isLogin: false
    }
  },
  created () {
    this.isLogin = Boolean(cache.getUser())
    console.warn(`App created path: ${this.$route.path}`)
    this.hub.$on('login-success', this.onLoginSuccess)
    this.hub.$on('logout', this.onLogout)
  },
  methods: {
    onLoginSuccess () {
      this.isLogin = Boolean(cache.getUser())
    },
    onLogout () {
      this.isLogin = false
    }
  }
}
</script>

<style>

#main {
  padding: 12px 12px;
}

/* #nav a {
  padding: 30px;
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */

/* 用于 pre 上，使其内容自动换行 */
.prewrap {
  overflow-x: scroll;
  white-space: pre-wrap;
}
</style>
